import { roleBoolean } from "../data/FormsData";
import { useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { updateTypeWriterEffect } from "../features/chats/chatsSlice";

const BooleanSelectRole = ({
  chatId,
  category,
  formattedDate,
  role,
  setRole,
  updateChatHistory,
}) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (role) {
      const bOTMessage = `Enter the required skills for ${role} role separated by commas (,)`;

      const updateChatHistoryFun = async () => {
        await updateChatHistory(
          chatId,
          {
            message: role,
            sender: "BOOLEANUSER",
            sentAt: formattedDate,
          },
          category
        )

        await updateChatHistory(
          chatId,
          {
            message: bOTMessage,
            sender: "BOOLEANBOT",
            sentAt: formattedDate,
          },
          category
        );
        setRole("");
      };
      updateChatHistoryFun();
      dispatch(updateTypeWriterEffect(true));
    }
  }, [role]);

  const handleRole = (event) => {
    event.preventDefault();
    setRole(event.target.value);
  };

  return (
      <FormControl sx={{ m: 1, minWidth: 250 }}>
        <InputLabel>Role</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select"
          value={role}
          label="Role"
          onChange={handleRole}
        >
          {roleBoolean.map((value, index) => (
            <MenuItem key={index} value={value.value}>
              {value.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
};

export default BooleanSelectRole;
