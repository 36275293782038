import { Avatar } from '@mui/material';
import { blue } from '@mui/material/colors';

const UserAvatar = ({ name, logoUrl }) => {
  let initials = '';
  if (name) {
    const words = name.trim().split(/\s+/);
    if (words?.length >= 2) {
      initials = words[0][0] + words[1][0];
    } else if (words?.length === 1) {
      initials = words[0].slice(0, 2);
    }
  }

  return (
    <>
      <Avatar variant='square' sx={{  backgroundColor:(name ? "#2196f3" : "white") , width: '35px', height: '35px', borderRadius: '5px', fontSize: 16 }} alt={name} src={logoUrl}>
        {initials}
      </Avatar>

      {/* <Avatar variant='square' sx={{ backgroundColor: blue[500], width: '35px', height: '35px', borderRadius: '5px', fontSize: 16 }} src={logoUrl}>
      </Avatar> */}
    </>

  );
}


export default UserAvatar;