import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Certificate from "../components/Certificate";
import { Alert, Box, Snackbar, Typography } from "@mui/material";
import ShareOnLinkedin from "../components/ShareOnLinkedin";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const PreviewCertificate = () => {
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.auth.user);
  const certificateImage = userDetails.certificateUrl;


  // Replace 'certificateUrl' with the actual URL received from the backend
  const imageUrl =
    "https://marketplace.canva.com/EAFML9NG98A/1/0/1600w/canva-gold-dark-blue-modern-elegant-achievement-certificate-EwA7_nSN1Ig.jpg";

  const goBackToTabuddy = () => {
    // Implement the logic to navigate back to the Tabuddy page
    navigate("/");
  };

  const copyUrl = () => {
    const domain = "http://localhost:3000";
    const newPath = certificateImage.replace("https://", ""); // Remove "https://" from the original URL
    const newURL = `${domain}/learning/certificate/${encodeURIComponent(
      newPath
    )}`;

    // To copy the newUrl on button click
    navigator.clipboard
      .writeText(newURL)
      .then(() => {
        console.log("URL copied successfully!");
      })
      .catch((error) => {
        console.error("Failed to copy URL:", error);
      });
      setSnackbarOpen(true)
  };

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={4000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      >
        <Alert
          severity="success"
          sx={{ width: "100%" }}
          onClose={() => {
            setSnackbarOpen(false);
          }}
        >
          Certificate Link Copied on Clipboard
        </Alert>
      </Snackbar>
      <Box style={{ overflowY: "scroll" }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            justifyContent: "space-around",
          }}
        >
          <Box>
            <Certificate />
          </Box>
          <Box
            mt={10}
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <Button variant="contained" color="primary" onClick={copyUrl}>
              My Certificate Link
            </Button>
            <Typography mb={2} sx={{ fontWeight: "500" }}>
              (Copy the link & share it with your network)
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={goBackToTabuddy}
            >
              Go back to TaBuddy
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PreviewCertificate;
