import axios from "axios";
import { useDispatch } from "react-redux";
import {
  loginFailure,
  loginRequest,
  loginSuccess,
} from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";

function useAuthData() {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const TOKEN = localStorage.getItem("token")
    ? "jwt " + localStorage.getItem("token").toString()
    : "none";
  const dispatch = useDispatch();
  const navigate = useNavigate()


  const getUserDetails = async (token) => {
    dispatch(loginRequest());
    return await axios({
      method: "get",
      baseURL: BASE_URL,
      url: "/user/profile-details",
      headers: {
        Authorization: "jwt "+token,
      },
    })
      .then((res) => {
        localStorage.setItem("token", token);
        navigate('/app/chat',{
          replace: true
        })
        dispatch(loginSuccess(res.data.data));
      })
      .catch((error) => {
        const errorMsg = "Some Error";
        // dispatch(loginFailure(errorMsg));
      });
  };

  return { getUserDetails };
}

export default useAuthData;
