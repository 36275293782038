import { useEffect, useState } from "react";
import domtoimage from "dom-to-image";
import { useSelector } from "react-redux";
import axios from "axios";
import useChatData from "../api/useChatData";

const Certificate = () => {
  const [dateString, setDateString] = useState("");
  const [credentialID, setCredentialID] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const userDetails = useSelector((state) => state.auth.user);
  const { uploadCertificate } = useChatData();

  const certificateURL = userDetails.certificateUrl;

  console.log(certificateURL);

  const userName = userDetails.firstName + " " + userDetails.lastName;

  useEffect(() => {
    const initializeCertificate = async () => {
      await generateCertificate();
    };

    const convertBase64ToImage = async () => {
      try {
        await base64ToImage();
      } catch (error) {
        console.error("Error converting base64 to image:", error);
      }
    };

    if (certificateURL === "") {
      initializeCertificate();
    }

    if (certificateURL === "") {
      console.log("hi");
      convertBase64ToImage();
    }
  }, [certificateURL]);

  const generateCertificate = async () => {
    certificationDate();
    await generateCredentialId();
    if (credentialID !== "") {
      handleDomToImage();
    }
  };

  const generateCredentialId = async () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";

    for (let i = 0; i < 16; i++) {
      if (i % 4 === 0 && i !== 0) {
        randomString += "-";
      }

      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }

    setCredentialID(randomString);
  };

  const certificationDate = (apiDate = "") => {
    if (apiDate === "") {
      const formattedDate = new Date().toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      setDateString(formattedDate);
      return formattedDate;
    } else {
      setDateString(apiDate);
      return apiDate;
    }
  };

  const handleDomToImage = () => {
    const node = document.getElementById("certificate");
    domtoimage
      .toPng(node)
      .then(function (dataUrl) {
        setImgUrl(dataUrl);
      })
      .catch(function (error) {
        console.error("Oops, something went wrong!", error);
      });
  };

  let certificatePreview = null;
  if (imgUrl || certificateURL) {
    certificatePreview = (
      <div>
        <img src={certificateURL} alt="Please refresh the page" />
      </div>
    );
  }

  const base64ToImage = async () => {
    const image = new Image();

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      canvas.width = image.width;
      canvas.height = image.height;

      context.drawImage(image, 0, 0);

      canvas.toBlob((blob) => {
        // Create a FormData object
        const data = new FormData();
        data.append("file", blob, "image.png");
        data.append("type", "certificate");

        // Send the image to the backend
        uploadCertificate(data);
      }, "image/png");
    };

    image.onerror = () => {
      console.log("Failed to convert Base64 to image");
    };

    image.src = imgUrl;
  };

  return (
    <>
      <div>
        {certificateURL === "" ? (
          <div>
            <div
              className="text-center relative"
              id="certificate"
              style={{
                height: "237mm",
                width: "160mm",
                border: "2px solid #000",
                backgroundImage: "url('/img/certificate-bg.png')",
                backgroundSize: "160mm 237mm",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                style={{
                  paddingTop: "95mm",
                  textAlign: "center",
                  fontSize: "30px",
                }}
              >
                <div
                  style={{
                    fontWeight: "550",
                  }}
                >
                  Awarded to
                </div>

                <div
                  style={{
                    fontWeight: "600",
                    color: "#006db0",
                  }}
                >
                  {userName}
                </div>

                <div style={{ fontWeight: "500" }}>
                  for successfully completing
                  <br /> the Xray - Basics Certification <br />
                  from TABuddy on <u>{dateString}</u>
                </div>
              </div>

              <div
                style={{
                  color: "white",
                  textAlign: "left",
                  marginLeft: "1rem",
                  paddingTop: "77.7mm",
                  fontWeight: "500",
                  width: "100%",
                  fontSize: "11px",
                }}
              >
                Credential ID : &nbsp;
                {credentialID}
              </div>
            </div>
          </div>
        ) : (
          <div className="p-3">
            {certificatePreview}
          </div>
        )}
      </div>
    </>
  );
};

export default Certificate;
