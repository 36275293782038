import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  assessmentLearning,
  booleanLearning,
  xrayLearning,
} from "../data/LearningData";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useChatData from "../api/useChatData";
import TypeWriter from "./TypeWriter";
import { BotMessage, UserMessage } from "./MessageBox";
import {
  updateLearningCompleted,
  updateShowCountinue,
} from "../features/chats/chatsSlice";
import AlertDialogSlide from "./RatingReview.jsx";

const LearnSourcingChat = ({ stackRef, userDetails }) => {
  const { updateChatHistory, getFirstChat } = useChatData();
  const chatHistories = useSelector((state) => state.chats.chatHistories);
  const firstChat = useSelector((state) => state.chats.firstChat);
  const { showCountinue } = useSelector((state) => state.chats);
  let { chatId, category } = useParams();
  const { learningCompleted } = useSelector((state) => state.chats);
  const { getRatings } = useChatData();
  const navigate = useNavigate();

  const showTextField = useRef(false);

  const [data, setData] = useState([]);
  const [text, setText] = useState("");
  const [showReview, setShowReview] = useState(false);
  const [index, setIndex] = useState(false);
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState("");
  const [marks, setMarks] = useState(0);

  const dispatch = useDispatch();

  const ratings = useSelector((state) => state.chats.userRatings);

  useEffect(() => {
    if (chatHistories?.length !== 0) {
      if (
        chatHistories[chatHistories?.length - 1].sender === "LEARNINGCOMPLETED"
      ) {
        if (!ratings.xray || !ratings.boolean || !ratings.general) {
          dispatch(updateShowCountinue(true));
          setShowReview(true);
        }
      }
    }
    getFirstChat(chatId, category);
  }, []);

  useEffect(() => {
    if (firstChat.length !== 0) {
      if (firstChat[0].message === "BOOLEAN SOURCING") {
        setOption("BOOLEAN");
      } else {
        setOption("XRAY");
      }
    }
  }, []);

  useEffect(() => {
    getRatings();
    if (chatHistories?.length !== 0) {
      if (!ratings.general || ratings.xray || !ratings.boolean) {
        if (
          (chatHistories[chatHistories?.length - 1].message === "NEXT" &&
            learningCompleted) ||
          (chatHistories[chatHistories?.length - 1].sender ===
            "LEARNINGCOMPLETED" &&
            learningCompleted)
        ) {
          setShowReview(true);
        }
      }
    }
  }, [chatHistories]);

  useEffect(() => {
    stackRef.current.scrollTop = stackRef.current.scrollHeight;
  }, [chatHistories, index, showCountinue]);

  useEffect(() => {
    // To show the user the Learning is completed.

    if (index === data?.length - 1 && option === "BOOLEAN") {
      dispatch(updateLearningCompleted(true));
      setData([]);
    }
    if (index === 5 && option === "ASSESSMENT") {
      dispatch(updateLearningCompleted(true));
      setShowReview(true);
      setData([]);
      setOption("XRAY");
    }
  }, [index, chatHistories]);

  const currentDate = new Date();
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  const formattedDate = currentDate
    .toLocaleString("en-US", options)
    .replace(",", "")
    .replace(/[/]/g, "-")
    .replace(" ", " ");

  const handleBoolean = async () => {
    setOption("BOOLEAN");
    setShowReview(false);
    dispatch(updateLearningCompleted(false));
    await updateChatHistory(
      chatId,
      {
        message: "BOOLEAN SOURCING",
        sender: "LEARNUSER",
        sentAt: formattedDate,
      },
      category
    );
    setData(booleanLearning);
    setIndex(0);
  };

  const handleXray = async () => {
    setOption("XRAY");
    setShowReview(false);
    dispatch(updateLearningCompleted(false));
    await updateChatHistory(
      chatId,
      {
        message: "XRAY Sourcing Learning",
        sender: "LEARNUSER",
        sentAt: formattedDate,
      },
      category
    );
    setData(xrayLearning);
    setIndex(0);
  };

  const handleIndex = async (message) => {
    dispatch(updateShowCountinue(false));
    let text = message.value.split("<br/>");
    text = JSON.stringify(text);
    await updateChatHistory(
      chatId,
      {
        message: text,
        sender: "LEARNBOT",
        sentAt: formattedDate,
      },
      category
    );
    if (message.button === "NEXT") {
      if (ratings.boolean && ratings.general) {
        await updateChatHistory(
          chatId,
          {
            message:
              "Hurray !!! You’ve completed the basic boolean concepts. I hope I didnt get your mouth watery by picking up a foodie example. ;)",
            sender: "LEARNINGFULLCOMPLETED",
            sentAt: formattedDate,
          },
          category
        );
      }
      if (!ratings.boolean || !ratings.general) {
        {
          await updateChatHistory(
            chatId,
            {
              message:
                "Hurray !!! You’ve completed the basic boolean concepts. I hope I didnt get your mouth watery by picking up a foodie example. ;)",
              sender: "LEARNINGCOMPLETED",
              sentAt: formattedDate,
            },
            category
          );
        }
      }
    } else {
      await updateChatHistory(
        chatId,
        {
          message: message.button,
          sender: "LEARNUSER",
          sentAt: formattedDate,
        },
        category
      );
    }
    setIndex(index + 1);
  };

  const handleAssessment = async (answer) => {
    const question = data[index];
    const correct = question.correctAnswer;
    dispatch(updateShowCountinue(false));
    let text = question.value.split("<br/>");
    text = JSON.stringify(text);

    if (answer === correct) {
      setMarks((prev) => prev + 1);
    }
    if (index === 3) {
      showTextField.current = false;
      setText("");
    }
    await updateChatHistory(
      chatId,
      {
        message: text,
        sender: "LEARNBOT",
        sentAt: formattedDate,
      },
      category
    );

    await updateChatHistory(
      chatId,
      {
        message: answer,
        sender: "LEARNUSER",
        sentAt: formattedDate,
      },
      category
    );

    await updateChatHistory(
      chatId,
      {
        message: correct,
        sender: "LEARNBOTCORRECTANSWER",
        sentAt: formattedDate,
      },
      category
    );

    if (index === 4) {
      if (marks >= 3) {
        await updateChatHistory(
          chatId,
          {
            message:
              "Congratulations! You have achieved above-average marks in the assessment and have earned your certificate. Well done!",
            sender: "ASSESSMENTSUCCESS",
            sentAt: formattedDate,
          },
          category
        );
      } else {
        await updateChatHistory(
          chatId,
          {
            message:
              "Unfortunately, you did not achieve above-average marks in the assessment. To receive a certificate, please retake the assessment.",
            sender: "ASSESSMENTFAILURE",
            sentAt: formattedDate,
          },
          category
        );
      }
    }

    if (index === 4 && ratings.general && ratings.xray) {
      await updateChatHistory(
        chatId,
        {
          message:
            "Hurray !!! You’ve completed the basic XRAY Assessment. I hope I helped you learn the fundamentals.",
          sender: "LEARNINGFULLCOMPLETED",
          sentAt: formattedDate,
        },
        category
      );
    }

    if (index === 4 && (!ratings.general || !ratings.xray)) {
      await updateChatHistory(
        chatId,
        {
          message: "Hurray !!! XRAY Assessment Completed",
          sender: "LEARNINGCOMPLETED",
          sentAt: formattedDate,
        },
        category
      );
    }

    setIndex(index + 1);
    if (index === 2) {
      showTextField.current = true;
    }
  };

  const renderBooleanMessage = () => {
    let message = data[index];
    let button = data[index].button;

    if (button === "NEXT" && index === data?.length - 2 && showCountinue) {
      handleIndex(message);
    }

    return (
      <>
        <BotMessage>
          <TypeWriter text={message.value}></TypeWriter>
        </BotMessage>
        <Box sx={{ borderBottom: "1px solid lightgrey" }} />
        {showCountinue && button && button !== "NEXT" && (
          <BotMessage>
            <Button variant="outlined" onClick={() => handleIndex(message)}>
              {message.button}
            </Button>
          </BotMessage>
        )}
      </>
    );
  };

  const renderXrayMessage = () => {
    let message = data[index];
    if (index < 6) {
      return (
        <>
          <BotMessage>
            <TypeWriter text={message.value}></TypeWriter>
          </BotMessage>
          <Box sx={{ borderBottom: "1px solid lightgrey" }} />
          {showCountinue && (
            <BotMessage>
              <Button variant="outlined" onClick={() => handleIndex(message)}>
                {message.button}
              </Button>
            </BotMessage>
          )}
        </>
      );
    } else {
      setData(assessmentLearning);
      setOption("ASSESSMENT");
      setIndex(0);
    }
  };

  const renderReview = () => {
    let button;
    let message;

    if (!ratings.general) {
      button = "Rate and Comment";
      message =
        "Thank you for using TABUDDY! We value your feedback and would greatly appreciate it if you could take a moment to share your experience with us. Your rating and review will help us improve TABUDDY and better serve you in the future. ?";
    } else {
      if (option === "BOOLEAN" && !ratings.boolean) {
        button = "Rate Boolean Learning";
        message =
          "Thank you for using TABUDDY! We value your feedback and would greatly appreciate it if you could take a moment to share your experience with us. Your rating and review will help us improve TABUDDY and better serve you in the future. ?";
      }
      if (option === "XRAY" && !ratings.xray) {
        button = "Rate X-RAY Learning";
        message =
          "Thank you for using TABUDDY! We value your feedback and would greatly appreciate it if you could take a moment to share your experience with us. Your rating and review will help us improve TABUDDY and better serve you in the future. ?";
      }
    }

    return (
      <>
        <BotMessage>
          <TypeWriter text={message}></TypeWriter>
        </BotMessage>
        <Box sx={{ borderBottom: "1px solid lightgrey" }} />
        {showCountinue && (
          <BotMessage>
            <Button variant="outlined" onClick={() => setOpen(true)}>
              {button}
            </Button>
          </BotMessage>
        )}
      </>
    );
  };

  const renderAssignment = () => {
    let message = data[index];

    return (
      <>
        <BotMessage>
          <TypeWriter text={message.value}></TypeWriter>
          {showCountinue &&
            message.type === "MCQ" &&
            message.options.map((option, index) => (
              <Box sx={{ display: "felx", flexDirection: "column" }}>
                <Button
                  keky={index}
                  variant="outlined"
                  sx={{ marginTop: "10px" }}
                  style={{ textTransform: "none" }}
                  onClick={() => handleAssessment(option)}
                >
                  {option}
                </Button>
              </Box>
            ))}
        </BotMessage>
      </>
    );
  };

  return (
    <>
      <AlertDialogSlide
        open={open}
        setOpen={setOpen}
        option={option}
        chatId={chatId}
        formattedDate={formattedDate}
        category={category}
      ></AlertDialogSlide>
      <Stack
        p={1}
        flexGrow={2}
        sx={{
          overflowY: "scroll",
        }}
        ref={stackRef}
      >
        <Typography variant="h4">Learn Sourcing</Typography>
        <Divider />
        {chatHistories?.length === 0 && (
          <BotMessage>
            <Typography mb={2}>
              Hoollaa {userDetails && `${userDetails.firstName}`}, Ready to
              embark on an exciting journey of mastering Sourcing? Choose one of
              the options below to kickstart your incredible learning adventure!
            </Typography>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
              }}
            >
              <Button variant="outlined" onClick={handleXray}>
                XRAY Sourcing
              </Button>
              <Button variant="outlined" onClick={handleBoolean}>
                BOOLEAN Sourcing
              </Button>
            </Stack>
          </BotMessage>
        )}

        {chatHistories?.length !== 0 && (
          <>
            {chatHistories?.map((value, mainIndex) => (
              <>
                {value.sender === "LEARNUSER" && (
                  <UserMessage>{value.message}</UserMessage>
                )}

                {value.sender === "LEARNBOT" && (
                  <BotMessage>
                    {JSON.parse(value.message).map((message, index) => (
                      <>
                        <span
                          dangerouslySetInnerHTML={{ __html: message }}
                        ></span>
                        <br />
                      </>
                    ))}
                  </BotMessage>
                )}

                {value.sender === "LEARNBOTCORRECTANSWER" && (
                  <BotMessage>
                    <b>Correct Answer is: </b>
                    {value.message}
                  </BotMessage>
                )}

                {value.sender === "LEARNINGCOMPLETED" &&
                  firstChat[0].message === "BOOLEAN SOURCING" && (
                    <BotMessage>
                      <Typography>{value.message}</Typography>
                    </BotMessage>
                  )}

                {value.sender === "LEARNINGFULLCOMPLETED" &&
                  firstChat[0].message === "BOOLEAN SOURCING" && (
                    <BotMessage>{value.message}</BotMessage>
                  )}

                {value.sender === "ASSESSMENTSUCCESS" && (
                  <BotMessage>{value.message}</BotMessage>
                )}
                {value.sender === "ASSESSMENTFAILURE" && (
                  <BotMessage>{value.message}</BotMessage>
                )}
                <Box sx={{ borderBottom: "1px solid lightgrey" }} />
              </>
            ))}
          </>
        )}
        {data?.length !== 0 && option === "BOOLEAN" && renderBooleanMessage()}
        {data?.length !== 0 && option === "XRAY" && renderXrayMessage()}
        {data?.length !== 0 &&
          option === "ASSESSMENT" &&
          index < 5 &&
          renderAssignment()}

        {chatHistories?.length !== 0 &&
          showReview &&
          chatHistories[chatHistories?.length - 1].sender ===
            "LEARNINGCOMPLETED" &&
          renderReview()}
        {chatHistories?.length !== 0 &&
          chatHistories[chatHistories?.length - 1].sender ===
            "LEARNINGFULLCOMPLETED" &&
          (chatHistories[chatHistories?.length - 3].sender ===
            "ASSESSMENTSUCCESS" ||
            chatHistories[chatHistories?.length - 2].sender ===
              "ASSESSMENTSUCCESS") && (
            <BotMessage>
              <Button
                variant="contained"
                onClick={() => navigate("/app/learning/certificate")}
              >
                Go To Certificate
              </Button>
            </BotMessage>
          )}
      </Stack>
      <Stack mt={2} direction="row" spacing={1}>
        <TextField
          disabled={!showTextField.current}
          fullWidth
          size="small"
          placeholder="Write your response here..."
          variant="outlined"
          value={text}
          onChange={(e) => setText(e.target.value)}
          autoComplete="off"
        />
        <Button
          disabled={!showTextField.current}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleAssessment(text)}
        >
          <SendIcon />
        </Button>
      </Stack>{" "}
    </>
  );
};

export default LearnSourcingChat;
