import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import { BotMessage } from "./MessageBox";
import { useState, useEffect } from "react";

import "../css/chatsLocked.css"

const ChatsLocked = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  // useEffect(() => {
  //   setOpen(false)
  //   localStorage.setItem("token", process.env.REACT_APP_TOKEN)
  // }, [])

  return (
    <Stack p={1} flexGrow={2}>
      <Dialog open={open} onClose={handleClose}>
        <Stack my={10} mx={5} alignItems='center'>
          <DialogTitle>
            <Typography variant="h5">
              Login to continue using <b> TA Buddy App </b>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2} display='inline-flex'>
              <Paper
                sx={{
                  padding: "10px 20px",
                  textDecoration: "none",
                  border: "1px solid",
                  borderColor: "lightgray",
                }}
                elevation={3}
                component="a"
                href={`${BASE_URL}/auth/google/callback`}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <img
                    style={{
                      maxHeight: 28,
                    }}
                    src="/icon/google.svg"
                    alt="google login"
                  />
                  <Typography variant="h6">Login with Google</Typography>
                </Stack>
              </Paper>
              <Paper
                sx={{
                  padding: "10px 20px",
                  textDecoration: "none",
                  border: "1px solid",
                  borderColor: "lightgray",
                }}
                elevation={3}
                component="a"
                href={`${BASE_URL}/auth/linkedin/callback`}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <img
                    style={{
                      maxHeight: 28,
                    }}
                    src="/icon/linkedin.svg"
                    alt="linkedin login"
                  />
                  <Typography variant="h6">Login with LinkedIn</Typography>
                </Stack>
              </Paper>
            </Stack>
          </DialogContent>
        </Stack>
      </Dialog>

      <BotMessage>
        Hey There 👋, I'm your TABuddy for Sourcing. I have been trained to help
        you in your sourcing. Do have a quick look at this <a target="_blank" style={{textDecoration: "none", color: "blue"}} href={"https://vimeo.com/835716542/d27cb1d9af"}>video</a> on how I help sourcers with some key hacks like:

        <br />
        <br />
        <ul className="dashed">
          <li>
            Inmail hack : Send free inmails to candidates.
          </li>
          <li>
            Company tags : Create your own company clusters, so that you can re-use them. No more keying-in company names again & again.
          </li>
          <li>
            Xray : I help to create multiple permutations of X-ray searches. This helps to overcome the 300 search results limitations of Google.
          </li>
        </ul>
      </BotMessage>
      <Box sx={{ borderBottom: "1px solid lightgrey" }} />
      <BotMessage>
        Before proceding further please make sure to login using the button
        given below:
      </BotMessage>
      <Box sx={{ borderBottom: "1px solid lightgrey" }} />
      <BotMessage>
        <Button
          onClick={handleClickOpen}
          variant="contained"
          endIcon={<LoginIcon />}
        >
          Login
        </Button>
      </BotMessage>
      <Box sx={{ borderBottom: "1px solid lightgrey" }} />
    </Stack>
  );
};

export default ChatsLocked;