import { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import { blue } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { updateShowCompanies, updateCompanyClusterTitleSuccess, updateCompanyClusterTitleFailure } from "../features/chats/chatsSlice";
import useChatData from '../api/useChatData';
import SnackBarCard from "./SnackBarCard";


const CompaniesChatCard = ({
  value, 
  newCluster,
  activeCluster,
  setActiveCluster, 
  setEditCluster,
  setNewClusterTitle,
  popupClose
}) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState("");
  const { loadingCompanyCluster, successCompanyCluster, errorCompanyCluster } = useSelector((state) => state.chats);
  const { updateComanyClusterTitle, deleteComanyCluster } = useChatData()

  // set to initial state
  useEffect(() => {
    dispatch(updateCompanyClusterTitleSuccess(""))
    dispatch(updateCompanyClusterTitleFailure(""))
  }, [])

  useEffect(() => {
    if (!loadingCompanyCluster && successCompanyCluster) {
      setEditCluster(true)
    }

    setIsEditing(false)
    dispatch(updateCompanyClusterTitleSuccess(""))
    dispatch(updateCompanyClusterTitleFailure(""))
  }, [loadingCompanyCluster, successCompanyCluster, errorCompanyCluster])

  const handleUpdateTitle = () => {
    if (value.isNewCluster) {
      setNewClusterTitle(title)
      setIsEditing(false)
    } else {
      updateComanyClusterTitle({
        title: title
      }, value._id)
    }
  };

  const handleDeleteCluster = () => {
    if (value.isNewCluster) {
      setEditCluster(true)
    } else {
      deleteComanyCluster(value._id)
    }
  }

  return (
    <Box
      key={value._id}
      elevation={2}
      onClick={() => {
        if (!value.isNewCluster) {
          setActiveCluster(value)
        }
      }}
      sx={{
        padding: 1,
        textDecoration: "none",
        borderLeft: "0px",
        color: "black",
        borderRadius: "5px",
        "&:hover": {
          color: blue[400],
          background: blue[50],
          cursor: "pointer",
        },
        ...((!newCluster && activeCluster?._id === value?._id) || (newCluster && value?.isNewCluster) ? {
          borderLeft: "5px solid",
          borderColor: "#42a5f5",
          background: blue[50]
        } : null),
      }}
    >
      {
        !loadingCompanyCluster && successCompanyCluster ? (<SnackBarCard message={successCompanyCluster} severity={"success"} />)
          : !loadingCompanyCluster && errorCompanyCluster ? <SnackBarCard message={errorCompanyCluster} severity={"error"} />
            : null
      }
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {isEditing ? (
          <TextField variant="standard" value={title} onChange={(event) => { setTitle(event.target.value) }} />
        ) : (
          <Typography variant="body1">{value.title}</Typography>
        )}

        <Stack direction="row">
          {isEditing ? (
            <IconButton
              size="small"
              color="success"
              onClick={handleUpdateTitle}
            >
              {loadingCompanyCluster ? <CircularProgress size={20} /> : <DoneIcon />}
            </IconButton>
          ) : (
            <IconButton
              size="small"
              color="info"
              sx={{ marginLeft: "30px" }}
              onClick={() => {
                setTitle(value.title)
                setIsEditing(true)
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          {!isEditing && (
            <IconButton
              size="small"
              color="error"
              onClick={() => {
                handleDeleteCluster()
                setIsEditing(false)
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default CompaniesChatCard;
