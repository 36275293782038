import { useState, useEffect } from "react";
import { designationData, experianceData, rolesData } from "../data/FormsData";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Autocomplete,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  IconButton
} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { blue } from "@mui/material/colors";
import VideocamIcon from '@mui/icons-material/Videocam';
import {
  designationExperianceCompany,
  experianceCompany,
  experianceDesignation,
  onlyExperiance,
} from "../utils/XRayLinkGenerator";
import { updateTypeWriterEffect, createCompanyCluterSuccess, createCompanyCluterFailure } from "../features/chats/chatsSlice";
import { useDispatch, useSelector } from "react-redux";
import useChatData from "../api/useChatData";

const XRayForm = ({
  role,
  coustomRole,
  experiance,
  designation,
  designationOptions,
  setDesignationOptions,
  selectedDesignations,
  location,
  mandatoryWords,
  eitherKeyword,
  femaleCheck,
  inputValue,
  newDesignation,
  openForJobs,
  headlineSearch,
  company,
  companyInput,
  errorMandatory,
  input,
  eitherInput,
  setEitherInput,
  setInput,
  setErrorMandatory,
  errorCompany,
  setErrorCompany,
  errorCoustom,
  setErrorCoustom,
  setHeadlineSearch,
  setNewDesignation,
  setInputValue,
  SetFemaleCheck,
  seteitherKeyword,
  setMandatory,
  setLocation,
  setSelectedDesignations,
  setDesignation,
  setExperiance,
  setCoustomRole,
  setRole,
  setOpenForJobs,
  setCompany,
  setCompanyInput,
  setLinks,
  setOutput,
}) => {
  const { createCompanyClusters, updateComanyClusters, getComanyClusters, deleteComanyCluster } = useChatData();
  const { loadingCompanyCluster, successCompanyCluster, errorCompanyCluster, companyCluster } = useSelector((state) => state.chats);

  const [mainError, setMainError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorEither, setErrorEither] = useState(false);
  const [error, setError] = useState(false);
  const [skillType, setSkillType] = useState("Mandatory");
  const [plusIconClicked, setPlusIconClicked] = useState(false);
  const [designationDialog, setDesignationDialog] = useState(false)
  const [clusterDialog, setClusterDialog] = useState(false)
  const [useClusterList, setUseClusterList] = useState(false)
  const [activeCluster, setActiveCluster] = useState({})
  const [clusterList, setClusterList] = useState([])
  const [isClusterEdited, setIsClusterEdited] = useState(false)
  const [newCluster, setNewCluster] = useState(false)
  const [title, setTitle] = useState("")

console.log(openForJobs, femaleCheck)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createCompanyCluterSuccess(""))
    dispatch(createCompanyCluterFailure(""))
  }, [])

  useEffect(() => {
    if (!loadingCompanyCluster && successCompanyCluster) {
      setNewCluster(false)
      setIsClusterEdited(false)
      setTitle("")
      getComanyClusters("XRAY");
    }

    dispatch(createCompanyCluterSuccess(""))
    dispatch(createCompanyCluterFailure(""))
  }, [loadingCompanyCluster, successCompanyCluster, errorCompanyCluster])

  useEffect(() => {
    getComanyClusters("XRAY");
  }, []);

  useEffect(() => {
    if (companyCluster && companyCluster?.length) {
      let tempData = companyCluster
      tempData = tempData?.map(item => {
        return { ...item, isEditing: false }
      })
      setClusterList(tempData)
      setActiveCluster(tempData[0])
    } else {
      setClusterList([])
    }
  }, [companyCluster, clusterDialog])

  useEffect(() => {
    if (role !== "") {
      setDesignationDialog(true)
    }
  }, [role])

  useEffect(() => {
    if (useClusterList) {
      setCompany(activeCluster?.xrayCompanies)
    }
  }, [useClusterList])

  const handleSet = () => {
    setClusterDialog(false);
    setUseClusterList(true)
    setIsClusterEdited(false)
    setNewCluster(false)
    setClusterList([])
  };

  const createNewCluster = async () => {
    const data = {
      type: "XRAY",
      title: activeCluster.title,
      xrayCompanies: activeCluster.xrayCompanies,
    };
    await createCompanyClusters(data);
  };

  const handleClusterUpdate = async () => {
    const body = {
      type: "XRAY",
      title: activeCluster.title,
      xrayCompanies: activeCluster.xrayCompanies
    };
    updateComanyClusters(body, activeCluster._id);
  }

  const handleNewCluster = () => {
    if (newCluster) {
      return
    }

    let tempData = clusterList
    tempData.unshift({
      title: "Default Title",
      _id: "newClusterId",
      xrayCompanies: [],
      isEditing: false
    })

    setClusterList(tempData)
    setNewCluster(true)
    setActiveCluster(tempData[0])
  }

  const handleEditCluster = (id, value, type) => {
    let tempData = clusterList
    tempData = tempData?.map(item => {
      if (item._id === id && type === "TITLE") {
        item.title = value
      }

      if (item._id === id && type === "COMPANY") {
        item.xrayCompanies = value
      }

      if (item._id === id && type === "EDIT_ICON") {
        item.isEditing = value
      }

      return item
    })

    setClusterList(tempData)
  }

  const handleDeleteCluster = (clusterId) => {
    if (clusterId === "newClusterId") {
      let tempData = clusterList
      tempData = tempData.slice(1)
      setClusterList(tempData)
      setNewCluster(false)
      setIsClusterEdited(false)
    } else {
      deleteComanyCluster(clusterId)
    }
  }

  const handleClusterDialogClose = () => {
    setClusterDialog(false)
    setClusterList([])
    setNewCluster(false)
    setIsClusterEdited(false)
    setTitle("")
  }

  const mandatorySkillComponent = () => {
    return (
      <Autocomplete
        multiple
        options={[]}
        freeSolo
        sx={{ width: "250px" }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          mandatoryWords?.length < 10 && setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Mandatory Keywords"
            variant="standard"
            onKeyDown={handleInputKeyDown}
          />
        )}
        onChange={handleTagChange}
        value={mandatoryWords}
      />
    )
  }

  const optionalSkillComponent = () => {
    return (
      <Autocomplete
        multiple
        options={[]}
        freeSolo
        sx={{ width: "250px", }}
        inputValue={eitherInput}
        onInputChange={(event, newKeywords) => {
          setEitherInput(newKeywords);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Keywords 1, Keyword 2"
            label="Either OR Keywords"
            variant="standard"
            onKeyDown={handleEitherKeyDown}
          />
        )}
        onChange={handleEitherChange}
        value={eitherKeyword}
      />
    )
  }

  const handleRole = (event, name) => {
    setMainError(false);
    if (event.target.value === "Software Developer/Engineer") {
      setSelectedDesignations([]);
      setDesignation("");
      setRole(event.target.value);
    }
    if (event.target.value === "Custom Role") {
      setSelectedDesignations([]);
      setDesignation("");
      setRole(event.target.value);
    } else {
      const options = rolesData.find(
        (role) => role.value === event.target.value
      );
      setRole(event.target.value);
      setSelectedDesignations([]);
      setDesignationOptions(options.options);
    }
  };

  const handleCoustomRoleChange = (event) => {
    setMainError(false);
    setCoustomRole(event.target.value);
  };

  const handleCoustomTagChange = (event, values) => {
    setMainError(false);
    setSelectedDesignations(values);
  };

  const handleCoustomInputKeyDown = (event) => {
    setErrorCoustom(false);
    setMainError(false);
    if (event.key === "Enter") {
      const newTag = input.trim();
      if (newTag && !selectedDesignations.includes(newTag)) {
        setSelectedDesignations([...selectedDesignations, newTag]);
      }
      setInput("");
      event.preventDefault();
    }
  };

  const handleTagChange = (event, values) => {
    setMandatory(values);
  };

  const handleInputKeyDown = (event) => {
    setErrorMandatory(false);
    if (event.key === "Enter") {
      const newTag = inputValue.trim();
      if (newTag && !mandatoryWords.includes(newTag)) {
        setMandatory([...mandatoryWords, newTag]);
      }
      setInputValue("");
      event.preventDefault();
    }
  };

  const handleCompanyTagChange = (event, values) => {
    setCompany(values);
  };

  const handleCompanyInputKeyDown = (event) => {
    setErrorCompany(false);
    if (event.key === "Enter") {
      const newTag = companyInput.trim();
      if (newTag && !company.includes(newTag)) {
        setCompany([...company, newTag]);
      }
      setCompanyInput("");
      event.preventDefault();
    }
  };

  const handleEitherChange = (event, values) => {
    seteitherKeyword(values);
  };

  const handleEitherKeyDown = (event) => {
    setErrorEither(false);
    if (event.keyCode === "Enter") {
      const newTag = eitherInput.trim();
      if (newTag && !eitherKeyword.includes(newTag)) {
        seteitherKeyword([...eitherKeyword, newTag]);
      }
      setEitherInput("");
      event.preventDefault();
    }
  };

  // To add the experiance in the search
  const handleExperiance = (event) => {
    setExperiance(event.target.value);
  };

  // To select the Designation option from dropdown.
  const handleDesignation = (event) => {
    setSelectedDesignations([]);
    // setSubRole(newData)
    const options = designationData.find(
      (role) => role.value === event.target.value
    );
    setDesignation(options.value);
    setDesignationOptions(options.options);
  };

  // Selected Designations CheckBoxes
  const handleDesignationChange = (value) => {
    if (!selectedDesignations.includes(value)) {
      setSelectedDesignations((prevSelectedDesignation) => [
        ...prevSelectedDesignation,
        value,
      ]);
    }
  };

  const handleDeleteDesignations = (value) => {
    setSelectedDesignations((prevSelectedDesignations) =>
      prevSelectedDesignations.filter((val) => val !== value)
    );
  };

  // To add new Roles in the already Presented Role.
  const handleKeyDown = (event) => {
    if (!selectedDesignations.includes(newDesignation)) {
      if (event.code === "Enter") {
        setSelectedDesignations((prev) => [...prev, newDesignation]);
        setDesignationOptions((prev) => [...prev, newDesignation]);
        setNewDesignation("");
      }
      if (newDesignation === "Enter") {
        setNewDesignation("");
      }
    }
  };

  // To handle the location field
  const handleLocationChange = (event) => {
    setMainError(false);
    setLocation(event.target.value);
  };

  // For Female CheckBox
  const handleFemaleCheck = (event) => {
    const checked = event.target.checked;
    if (checked) {
      SetFemaleCheck(true);
    } else {
      SetFemaleCheck(false);
    }
  };

  const handleOpenForJobs = (event) => {
    const checked = event.target.checked;
    if (checked) {
      setOpenForJobs(true);
    } else {
      setOpenForJobs(false);
    }
  };

  const handleUserBubble = () => {
    let experianceOutput = false;
    let designationOutput = false;
    let locationOutput = false;
    let mandatoryOutput = false;
    let eitherOutput = false;
    let openForJobsOutput = false;
    let femaleCheckOutput = false;
    let headlineSearchOutput = false;
    let companyOutput = false;
    let roleOutput = false;
    let subDesignationOutput = false;
    let designationOptionsoutput = false;

    if (role) {
      roleOutput = role;
      if (role === "Software Developer/Engineer") {
        subDesignationOutput = designation;
        if (designationOptions?.length !== 0) {
          designationOptionsoutput = designationOptions;
        }
      }

    }
    if (experiance !== "") {
      if (experiance === ">3") {
        experianceOutput = "1-3 Years Experience";
      }
      if (experiance === ">5") {
        experianceOutput = "3-5 Years Experience";
      }
      if (experiance === ">8") {
        experianceOutput = "5-8 Years Experience";
      }
      if (experiance === ">12") {
        experianceOutput = "8-12 Years Experience";
      }
      if (experiance === "<12") {
        experianceOutput = "12+ Years Experience";
      }
    }
    if (selectedDesignations?.length !== 0) {
      designationOutput = selectedDesignations.join(", ");
    }
    if (femaleCheck !== "") {
      femaleCheckOutput = true;
    }
    if (openForJobs !== "") {
      openForJobsOutput = true;
    }
    if (location !== "") {
      locationOutput = location;
    }
    if (headlineSearch !== "") {
      headlineSearchOutput = headlineSearch;
    }
    if (mandatoryWords?.length !== 0) {
      mandatoryOutput = mandatoryWords.join(", ");
    }
    if (eitherKeyword?.length !== 0) {
      eitherOutput = eitherKeyword.join(", ");
    }
    if (company?.length !== 0) {
      companyOutput = company.join(", ");
    }

    setOutput([
      {
        role: roleOutput,
        mandatory: mandatoryOutput,
        either: eitherOutput,
        location: locationOutput,
        experiance: experianceOutput,
        designation: designationOutput,
        femaleCheck: femaleCheckOutput,
        openForJobs: openForJobsOutput,
        headlineSearch: headlineSearchOutput,
        company: companyOutput,
        designationOption: subDesignationOutput,
        designationOptions: designationOptionsoutput,
      },
    ]);
  };

  const checkKeywordsLength = () => {
    let checkEitherLength;
    let length =
      mandatoryWords?.length + selectedDesignations?.length + company?.length;

    if (eitherKeyword?.length !== 0) {
      const filteredEither = eitherKeyword.filter(Boolean);
      const newEitherKeywords = filteredEither.map((elem) => {
        return elem
          .split(",")
          .map((innerElement) => {
            return innerElement.trim();
          })
          .join(" OR ");
      });

      checkEitherLength = newEitherKeywords.flatMap((elem) =>
        elem.split(" OR ")
      );
      length =
        checkEitherLength?.length +
        mandatoryWords?.length +
        selectedDesignations?.length +
        company?.length;
    }

    if (experiance !== "") {
      if (experiance === ">3" || experiance === ">5") {
        length = length + 2;
      }
      if (experiance === ">8") {
        length = length + 3;
      }
      if (experiance === ">12") {
        length = length + 4;
      }
      if (experiance === "<12") {
        length = length + 1;
      }
    }
    if (femaleCheck !== "") {
      length = length + 3;
    }
    if (location !== "") {
      length = length + 1;
    }
    if (openForJobs !== "") {
      length = length + 2;
    }
    return length;
  };

  const handleClick = () => {
    if (!loader) {
      if (location === "" || selectedDesignations?.length === 0) {
        setMainError(true);
        return;
      }

      // To check if there are any tag fields with values that are not selected yet
      if (inputValue !== "") {
        setErrorMandatory(true);
        return;
      }
      if (companyInput !== "") {
        setErrorCompany(true);
        return;
      }
      if (input !== "") {
        setErrorCoustom(true);
        return;
      }
      if (eitherInput !== "") {
        setErrorEither(true);
        return;
      }

      const data = checkKeywordsLength();

      if (data > 32) {
        setError(true);
        return;
      }

      handleSubmit();
    } else {
      console.log("Link is genereting");
    }
    setLoader(true);
  };

  const handleSubmit = () => {
    try {
      let formedLink = "site:linkedin.com/in";
      let transformedArray = [];

      if (company?.length !== 0) {
        if (company.lenhth !== 1) {
          let currentGroup = "";
          for (let i = 0; i < company?.length; i++) {
            if (currentGroup === "") {
              currentGroup = company[i];
            } else {
              currentGroup += " | " + company[i];
              if (i % 3 === 2 || i === company?.length - 1) {
                transformedArray.push(currentGroup);
                currentGroup = "";
              }
            }
          }
        }
        if (company?.length === 1) {
          transformedArray = company;
        }
      }

      if (headlineSearch !== "") {
        formedLink = formedLink + ` inanchor:"${headlineSearch}"`;
      }

      let mandatory = mandatoryWords.join(" ");
      let newEitherKeywords;
      let joinedKeywords;

      if (eitherKeyword?.length !== 0) {
        const filteredEither = eitherKeyword.filter(Boolean);
        joinedKeywords = filteredEither.join(" OR ");
        newEitherKeywords = `(${joinedKeywords})`;
      }

      if(eitherKeyword.length !== 0){
        formedLink = formedLink + ` ${mandatory} ${newEitherKeywords}`
      }else{
        formedLink = formedLink + ` ${mandatory}`
      }

      if (location !== "") {
        formedLink = formedLink + ` "${location}" `;
      }

      if (femaleCheck) {
        formedLink = formedLink + ` "Female OR Women OR Women’s" `;
      }

      if (openForJobs) {
        formedLink = formedLink + ` "looking * job" `;
      }

      let googleSearchUrl = `https://www.google.com/search?q=${encodeURIComponent(
        formedLink
      )}`;

      // if neither Experiance , Designation nor Company field is selected
      if (
        experiance === "" &&
        selectedDesignations?.length === 0 &&
        company?.length === 0
      ) {
        setLinks((prev) => [
          ...prev,
          { link: googleSearchUrl, name: "XRay Sourcing Link" },
        ]);
      }

      // If the Only experiance field is selected.
      if (
        experiance !== "" &&
        selectedDesignations?.length === 0 &&
        company?.length === 0
      ) {
        onlyExperiance(experiance, googleSearchUrl, setLinks);
      }

      // If Experiance and Role/ Designation and Company Cluster are selected.
      if (
        experiance !== "" &&
        selectedDesignations?.length !== 0 &&
        company?.length !== 0
      ) {
        designationExperianceCompany(
          experiance,
          googleSearchUrl,
          selectedDesignations,
          transformedArray,
          setLinks
        );
      }

      // If Experiance and Company Cluster are selected.
      if (
        experiance !== "" &&
        selectedDesignations?.length === 0 &&
        company?.length !== 0
      ) {
        experianceCompany(
          experiance,
          googleSearchUrl,
          transformedArray,
          setLinks
        );
      }

      // Designation And Experiance is selected
      if (
        experiance !== "" &&
        selectedDesignations?.length !== 0 &&
        company?.length === 0
      ) {
        experianceDesignation(
          experiance,
          googleSearchUrl,
          selectedDesignations,
          setLinks
        );
      }

      // if only Designation field is selcted
      if (
        selectedDesignations?.length !== 0 &&
        company?.length === 0 &&
        experiance === ""
      ) {
        selectedDesignations.map((value) => {
          let link = googleSearchUrl + `intitle:"${value}"`;
          setLinks((prev) => [...prev, { link: link, name: value }]);
        });
      }

      // If designation and comopany is selected
      if (
        selectedDesignations?.length !== 0 &&
        company?.length !== 0 &&
        experiance === ""
      ) {
        selectedDesignations.map((designation) => {
          transformedArray.map((company) => {
            let link =
              googleSearchUrl +
              `intitle:"${designation}" + intitle:"${company}"`;
            setLinks((prev) => [
              ...prev,
              {
                link: link,
                name: `Designation ${designation} and Company Cluster ${company}`,
              },
            ]);
          });
        });
      }

      // if only Company field is selcted
      if (
        company?.length !== 0 &&
        selectedDesignations?.length === 0 &&
        experiance === ""
      ) {
        transformedArray.map((value) => {
          let link = googleSearchUrl + `intitle:"${value}"`;
          setLinks((prev) => [...prev, { link: link, name: value }]);
        });
      }

      // user Bubble message generator
      handleUserBubble();

      setLoader(false);
      dispatch(updateTypeWriterEffect(true));
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  return (
    <>
      {/* <Box
        display="flex"
        flexDirection="column"
        sx={{
          padding: "20px",
          borderRadius: "5px",
        }}
      >
        <Box
          display="flex"
          gap={1}
          width={510}
          justifyContent="center"
          alignItems="start"
          flexDirection="row"
          mb={1}
        >
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel id="demo-simple-select-helper-label">*Role</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select"
              value={role}
              label="Role"
              onChange={handleRole}
            >
              {rolesData.map((value, index) => (
                <MenuItem key={index} value={(value.name, value.value)}>
                  {value.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl FormControl sx={{ minWidth: 250 }}>
            <InputLabel id="demo-simple-select-helper-label">
              Experience
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select"
              value={experiance}
              label="Experience"
              onChange={handleExperiance}
            >
              {experianceData.map((value, index) => (
                <MenuItem key={index} value={value.value}>
                  {value.years}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box
          display="flex"
          mt={1}
          justifyContent="start"
          alignItems="centetr"
          flexDirection="row"
        >
          {role !== "" &&
            role !== "Software Developer/Engineer" &&
            role !== "Custom Role" && (
              <Box
                sx={{
                  width: 510,
                  border: 1,
                  borderRadius: 1,
                  borderColor: "gray",
                  padding: "10px 10px 10px 0px",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  gap: 1,
                  padding: "10px",
                  border: 1,
                  borderRadius: 1,
                  borderColor: "gray",
                  alignItems: "center",
                }}
                gap={1}
              >
                {designationOptions.map((value, index) => (
                  <>
                    <Chip
                      key={index}
                      label={value}
                      onClick={() => handleDesignationChange(value)}
                      onDelete={
                        selectedDesignations.includes(value)
                          ? () => handleDeleteDesignations(value)
                          : undefined
                      }
                      color={
                        selectedDesignations.includes(value)
                          ? "primary"
                          : "default"
                      }
                      variant="outlined"
                      style={{ margin: "0.5rem" }}
                      sx={{
                        borderRadius: "4px",
                        fontSize: "14px",
                        height: "58px",
                      }}
                    />

                    {index + 1 === designationOptions?.length && (
                      <TextField
                        sx={{
                          width: "120px",
                          marginLeft: "10px",
                          marginTop: "8px",
                        }}
                        label="+ Add More"
                        variant="outlined"
                        value={newDesignation}
                        onChange={(event) =>
                          setNewDesignation(event.target.value)
                        }
                        onKeyDown={handleKeyDown}
                      />
                    )}
                  </>
                ))}
              </Box>
            )}
        </Box>
        {role !== "" &&
          role !== "Software Developer/Engineer" &&
          role !== "Custom Role" && (
            <Typography
              variant="caption"
              display="block"
              sx={{ marginBottom: "10px", marginTop: "2%" }}
            >
              *Select Designation from above Values.
            </Typography>
          )}

        {role === "Software Developer/Engineer" && (
          <Box
            width={510}
            display="flex"
            flexDirection="column"
            justifyContent="start"
            alignItems="start"
            gap={2}
            mb="20px"
          >
            <FormControl sx={{ minWidth: 250 }}>
              <InputLabel id="demo-simple-select-helper-label">
                Designation
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select"
                value={designation}
                label="Designation"
                onChange={handleDesignation}
              >
                {designationData.map((value, index) => (
                  <MenuItem key={index} value={value.value}>
                    {value.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {designation && (
              <Box
                sx={{
                  width: 510,
                  border: 1,
                  borderRadius: 1,
                  borderColor: "gray",
                  padding: "10px 10px 10px 0px",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  gap: 1,
                  padding: "10px",
                  border: 1,
                  borderRadius: 1,
                  borderColor: "gray",
                  alignItems: "center",
                }}
              >
                {designationOptions.map((value, index) => (
                  <>
                    <Chip
                      key={index}
                      label={value}
                      onClick={() => handleDesignationChange(value)}
                      onDelete={
                        selectedDesignations.includes(value)
                          ? () => handleDeleteDesignations(value)
                          : undefined
                      }
                      color={
                        selectedDesignations.includes(value)
                          ? "primary"
                          : "default"
                      }
                      variant="outlined"
                      style={{ margin: "0.5rem" }}
                      sx={{
                        borderRadius: "4px",
                        fontSize: "14px",
                        height: "58px",
                      }}
                    />

                    {index + 1 === designationOptions.length && (
                      <TextField
                        sx={{
                          width: "120px",
                          marginLeft: "10px",
                          marginTop: "8px",
                        }}
                        label="+ Add More"
                        variant="outlined"
                        value={newDesignation}
                        onChange={(event) =>
                          setNewDesignation(event.target.value)
                        }
                        onKeyDown={handleKeyDown}
                      />
                    )}
                  </>
                ))}
              </Box>
            )}
            {designation && (
              <Typography
                variant="caption"
                display="block"
                sx={{ marginBottom: "0px", marginTop: "2%" }}
              >
                *Select Designation from above Values.
              </Typography>
            )}
          </Box>
        )}

        {role === "Custom Role" && (
          <Box
            display="flex"
            gap={1}
            justifyContent="center"
            alignItems="start"
            flexDirection="row"
          >
            <TextField
              sx={{ width: 250 }}
              label="Add Custom Role"
              autoComplete="off"
              value={coustomRole}
              onChange={handleCoustomRoleChange}
            />

            <Box>
              <Autocomplete
                multiple
                options={[]}
                freeSolo
                sx={{ width: "250px" }}
                inputValue={input}
                onInputChange={(event, newInputValue) => {
                  setInput(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="*Custom Designations"
                    variant="outlined"
                    onKeyDown={handleCoustomInputKeyDown}
                  />
                )}
                onChange={handleCoustomTagChange}
                value={selectedDesignations}
              />
              {!errorCoustom ? (
                <Typography
                  variant="caption"
                  display="block"
                  sx={{ color: "grey", marginBottom: "10px" }}
                >
                  *Seperate the Desigantaions by "Enter"
                </Typography>
              ) : (
                <Typography
                  variant="caption"
                  display="block"
                  sx={{ color: "Red", marginBottom: "10px" }}
                >
                  Select the Designations by "Enter"
                </Typography>
              )}
            </Box>
          </Box>
        )}

        <Box
          display="flex"
          gap={1}
          alignItems="start"
          flexDirection="row"
          mb={1}
        >
          <TextField
            sx={{ width: 250 }}
            label="Headline Search"
            autoComplete="off"
            value={headlineSearch}
            onChange={(event) => setHeadlineSearch(event.target.value)}
          />

          <Box>
            <Autocomplete
              multiple
              options={[]}
              freeSolo
              sx={{ width: "250px" }}
              inputValue={companyInput}
              onInputChange={(event, newInputValue) => {
                setCompanyInput(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Current Company"
                  variant="outlined"
                  onKeyDown={handleCompanyInputKeyDown}
                />
              )}
              onChange={handleCompanyTagChange}
              value={company}
            />
            {!errorCompany ? (
              <Typography
                variant="caption"
                display="block"
                sx={{ color: "grey", marginBottom: "5px" }}
              >
                *Seperate the Companies by "Enter"
              </Typography>
            ) : (
              <Typography
                variant="caption"
                display="block"
                sx={{ color: "Red", marginBottom: "5px" }}
              >
                Please separate the Companies by "Enter".
              </Typography>
            )}
          </Box>
        </Box>

        <Box
          display="flex"
          gap={1}
          justifyContent="center"
          alignItems="start"
          flexDirection="row"
          mb={1}
          width={510}
        >
          <Box>
            <TextField
              sx={{ width: 250 }}
              label="*Location"
              autoComplete="off"
              value={location}
              onChange={handleLocationChange}
            />

            <Typography
              variant="caption"
              display="block"
              sx={{ color: "grey", marginBottom: "5px" }}
            >
              *Select only one city per search.
            </Typography>
          </Box>

          <Box>
            <Autocomplete
              multiple
              options={[]}
              freeSolo
              sx={{ width: "250px" }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                mandatoryWords.length < 10 && setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Mandatory Keywords"
                  variant="outlined"
                  onKeyDown={handleInputKeyDown}
                />
              )}
              onChange={handleTagChange}
              value={mandatoryWords}
            />
            {!errorMandatory ? (
              <Typography
                variant="caption"
                display="block"
                sx={{ color: "grey", marginBottom: "5px" }}
              >
                *Seperate the Keywords by "Enter"
              </Typography>
            ) : (
              <Typography
                variant="caption"
                display="block"
                sx={{ color: "Red", marginBottom: "5px" }}
              >
                Please separate the Keywords by "Enter".
              </Typography>
            )}
          </Box>
        </Box>

        <Box
          width={500}
          display="flex"
          justifyContent="center"
          alignItems="start"
          flexDirection="column"
          gap={1}
        >
          <Autocomplete
            multiple
            options={[]}
            freeSolo
            sx={{ minWidth: "250px", maxWidth: "520px" }}
            inputValue={eitherInput}
            onInputChange={(event, newKeywords) => {
              setEitherInput(newKeywords);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Either OR Keywords"
                variant="outlined"
                onKeyDown={handleEitherKeyDown}
              />
            )}
            onChange={handleEitherChange}
            value={eitherKeyword}
          />
          {!errorEither ? (
            <Typography
              variant="caption"
              display="block"
              sx={{ color: "grey", marginBottom: "15px" }}
            >
              *Write more than 1 keyword & hit "Enter"
            </Typography>
          ) : (
            <Typography
              variant="caption"
              display="block"
              sx={{ color: "Red", marginBottom: "10px" }}
            >
              Please separate your Keywords by "Enter".
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            marginTop: "15px",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                value={femaleCheck}
                onChange={handleFemaleCheck}
                defaultChecked={femaleCheck !== ""}
              />
            }
            label="Female Candidates"
          />

          <FormControlLabel
            control={
              <Checkbox
                value={openForJobs}
                onChange={handleOpenForJobs}
                defaultChecked={openForJobs !== ""}
              />
            }
            label="Open For Jobs"
          />
        </Box>

        <Box marginTop="10px">
          {error && (
            <Typography
              fontWeight={600}
              variant="caption"
              display="block"
              sx={{ color: "Red" }}
            >
              *You can only select upto 32 Keywords.
            </Typography>
          )}

          {mainError && (
            <Typography
              fontWeight={600}
              variant="caption"
              display="block"
              sx={{ color: "red" }}
            >
              *Select both of the Fields (Location and Role)
            </Typography>
          )}
        </Box>

        <Box marginLeft="84%" marginTop="2%">
          <Button
            variant="outlined"
            sx={{ width: "80px", height: "40px" }}
            onClick={handleClick}
          >
            {loader ? <CircularProgress size={20} /> : "Submit"}
          </Button>
        </Box>
      </Box> */}

      {/* Designation Dialog Box */}
      <Dialog
        open={designationDialog}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => { setDesignationDialog(false) }}
        maxWidth="400px"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          mb={2}
          sx={{
            backgroundColor: "white",
            color: "#0778bd",
            width: "100%",
            textAlign: "left",
            borderBottom: "1px solid black",
          }}
        >
          Select Designations
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{
              textAlign: "center",
              padding: "10px",
              borderBottom: "1px solid lightgrey",
            }}
          >
            <Box
              display="flex"
              mt={1}
              justifyContent="start"
              alignItems="centetr"
              flexDirection="row"
            >
              {role !== "" &&
                role !== "Software Developer/Engineer" &&
                role !== "Custom Role" && (
                  <Box
                    sx={{
                      width: 510,
                      border: 1,
                      borderRadius: 1,
                      borderColor: "gray",
                      padding: "10px 10px 10px 0px",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      gap: 1,
                      padding: "10px",
                      border: 1,
                      borderRadius: 1,
                      borderColor: "gray",
                      alignItems: "center",
                    }}
                    gap={1}
                  >
                    {designationOptions.map((value, index) => (
                      <>
                        <Chip
                          key={index}
                          label={value}
                          onClick={() => handleDesignationChange(value)}
                          onDelete={
                            selectedDesignations.includes(value)
                              ? () => handleDeleteDesignations(value)
                              : undefined
                          }
                          color={
                            selectedDesignations.includes(value)
                              ? "primary"
                              : "default"
                          }
                          variant="outlined"
                          style={{ margin: "0.5rem" }}
                          sx={{
                            borderRadius: "4px",
                            fontSize: "14px",
                            height: "58px",
                          }}
                        />

                        {index + 1 === designationOptions?.length && (
                          <TextField
                            sx={{
                              width: "120px",
                              marginLeft: "10px",
                              marginTop: "8px",
                            }}
                            label="+ Add More"
                            variant="outlined"
                            value={newDesignation}
                            onChange={(event) =>
                              setNewDesignation(event.target.value)
                            }
                            onKeyDown={handleKeyDown}
                          />
                        )}
                      </>
                    ))}
                  </Box>
                )}
            </Box>
            {role !== "" &&
              role !== "Software Developer/Engineer" &&
              role !== "Custom Role" && (
                <Typography
                  variant="caption"
                  display="block"
                  sx={{ marginBottom: "10px", marginTop: "2%" }}
                >
                  *Select Designation from above Values.
                </Typography>
              )}

            {role === "Software Developer/Engineer" && (
              <Box
                width={510}
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="start"
                gap={2}
                mb="20px"
              >
                <FormControl sx={{ minWidth: 250 }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Designation
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select"
                    value={designation}
                    label="Designation"
                    onChange={handleDesignation}
                  >
                    {designationData.map((value, index) => (
                      <MenuItem key={index} value={value.value}>
                        {value.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {designation && (
                  <Box
                    sx={{
                      width: 510,
                      border: 1,
                      borderRadius: 1,
                      borderColor: "gray",
                      padding: "10px 10px 10px 0px",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      gap: 1,
                      padding: "10px",
                      border: 1,
                      borderRadius: 1,
                      borderColor: "gray",
                      alignItems: "center",
                    }}
                  >
                    {designationOptions.map((value, index) => (
                      <>
                        <Chip
                          key={index}
                          label={value}
                          onClick={() => handleDesignationChange(value)}
                          onDelete={
                            selectedDesignations.includes(value)
                              ? () => handleDeleteDesignations(value)
                              : undefined
                          }
                          color={
                            selectedDesignations.includes(value)
                              ? "primary"
                              : "default"
                          }
                          variant="outlined"
                          style={{ margin: "0.5rem" }}
                          sx={{
                            borderRadius: "4px",
                            fontSize: "14px",
                            height: "58px",
                          }}
                        />

                        {index + 1 === designationOptions?.length && (
                          <TextField
                            sx={{
                              width: "120px",
                              marginLeft: "10px",
                              marginTop: "8px",
                            }}
                            label="+ Add More"
                            variant="outlined"
                            value={newDesignation}
                            onChange={(event) =>
                              setNewDesignation(event.target.value)
                            }
                            onKeyDown={handleKeyDown}
                          />
                        )}
                      </>
                    ))}
                  </Box>
                )}
                {designation && (
                  <Typography
                    variant="caption"
                    display="block"
                    sx={{ marginBottom: "0px", marginTop: "2%" }}
                  >
                    *Select Designation from above Values.
                  </Typography>
                )}
              </Box>
            )}

            {role === "Custom Role" && (
              <Box
                display="flex"
                gap={1}
                justifyContent="center"
                alignItems="start"
                flexDirection="row"
              >
                <TextField
                  sx={{ width: 250 }}
                  label="Add Custom Role"
                  autoComplete="off"
                  value={coustomRole}
                  onChange={handleCoustomRoleChange}
                />

                <Box>
                  <Autocomplete
                    multiple
                    options={[]}
                    freeSolo
                    sx={{ width: "250px" }}
                    inputValue={input}
                    onInputChange={(event, newInputValue) => {
                      setInput(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="*Custom Designations"
                        variant="outlined"
                        onKeyDown={handleCoustomInputKeyDown}
                      />
                    )}
                    onChange={handleCoustomTagChange}
                    value={selectedDesignations}
                  />
                  {!errorCoustom ? (
                    <Typography
                      variant="caption"
                      display="block"
                      sx={{ color: "grey", marginBottom: "10px" }}
                    >
                      *Seperate the Desigantaions by "Enter"
                    </Typography>
                  ) : (
                    <Typography
                      variant="caption"
                      display="block"
                      sx={{ color: "Red", marginBottom: "10px" }}
                    >
                      Select the Designations by "Enter"
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ margin: "0px 15px 15px 10px" }}>
          <Button variant="outlined" onClick={() => { setDesignationDialog(false) }}>
            Done
          </Button>
        </DialogActions>
      </Dialog>

      {/* Cluster Dialog Box */}
      <Dialog
        open={clusterDialog}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClusterDialogClose}
        maxWidth={"md"}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          mb={2}
          sx={{
            backgroundColor: "white",
            color: "#0778bd",
            width: "900px",
            textAlign: "left",
            borderBottom: "1px solid black",
          }}
        >
          <Grid container md={12}>
            <Grid item md={4}>
              Select Company Cluster
            </Grid>
            <Grid item md={3} />
            <Grid item md={5}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <span style={{ fontSize: "14px" }}>
                  How to use this?
                </span>
                <ArrowForwardIcon fontSize="small" style={{ margin: "0px 5px 0px 5px" }} />
                <a target="_blank" href={"https://vimeo.com/835716542/d27cb1d9af"} style={{ textDecoration: "none" }}>
                  <Button variant="outlined" >
                    <VideocamIcon fontSize="small" style={{ paddingRight: "5%" }} />
                    Watch Video
                  </Button>
                </a>
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{
              textAlign: "center",
              padding: "10px",
              borderBottom: "1px solid lightgrey",
            }}
          >
            <Stack display="flex" flexDirection="row" gap={2} height="55vh">
              <Stack sx={{ borderRight: "1px solid lightgrey", width: "30%" }}>
                <Box sx={{ margin: "0px 10px 10px 10px" }}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={handleNewCluster}
                  >
                    New Companies
                  </Button>
                </Box>
                <Stack
                  sx={{
                    overflowY: "scroll",
                  }}
                  px={1}
                  spacing={1}
                  flexGrow={0.025}
                  gap={1}
                  p={1}
                >
                  <Grid container md={12}>
                    {clusterList?.map(value => {
                      return (
                        <Grid item md={12}>
                          <Box
                            key={value._id}
                            elevation={2}
                            onClick={() => {
                              setActiveCluster(value)
                            }}
                            sx={{
                              padding: 1,
                              textDecoration: "none",
                              borderLeft: "0px",
                              marginTop: "5px",
                              color: "black",
                              borderRadius: "5px",
                              "&:hover": {
                                color: blue[400],
                                background: blue[50],
                                cursor: "pointer",
                              },
                              ...((activeCluster?._id === value?._id) ? {
                                borderLeft: "5px solid",
                                borderColor: "#42a5f5",
                                background: blue[50]
                              } : null),
                            }}
                          >
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                              {value.isEditing ? (
                                <TextField variant="standard" value={title} onChange={(event) => { setTitle(event.target.value) }} />
                              ) : (
                                <Typography variant="body1">{value.title}</Typography>
                              )}

                              <Stack direction="row">
                                {value.isEditing ? (
                                  <IconButton
                                    size="small"
                                    color="success"
                                    onClick={() => {
                                      handleEditCluster(value._id, title, "TITLE")
                                      handleEditCluster(value._id, false, "EDIT_ICON")
                                    }}
                                  >
                                    {false ? <CircularProgress size={20} /> : <DoneIcon />}
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    size="small"
                                    color="info"
                                    sx={{ marginLeft: "30px" }}
                                    onClick={() => {
                                      setTitle(value.title)
                                      handleEditCluster(value._id, true, "EDIT_ICON")
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                )}
                                {!value.isEditing && (
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() => {
                                      handleDeleteCluster(value._id)
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                )}
                              </Stack>
                            </Box>
                          </Box>
                        </Grid>
                      )
                    })}
                  </Grid>

                </Stack>
              </Stack>

              <Stack display="flex" width="70%">
                <Stack
                  position="sticky"
                  top="0"
                  width="100%"
                  direction="row"
                  justifyContent="end"
                  sx={{
                    backgroundColor: "white",
                    zIndex: "100",
                    paddingRight: "10px",
                    // borderBottom: "1px solid lightgrey",
                    paddingBottom: "10px",
                  }}
                >

                  {
                    isClusterEdited ? (
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => {
                          if (newCluster) {
                            createNewCluster()
                          } else {
                            handleClusterUpdate()
                          }
                        }}
                        style={{ marginLeft: "10px" }}
                      >
                        Save Changes
                      </Button>
                    ) : null
                  }
                </Stack>
                {activeCluster && Object.keys(activeCluster)?.length ? (
                  <AutoCompleteXrayComponent
                    value={activeCluster}
                    clusterList={clusterList}
                    setClusterList={setClusterList}
                    setIsClusterEdited={setIsClusterEdited}
                  />
                ) : null}
              </Stack>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ margin: "0px 15px 15px 10px" }}>
          <Button variant="outlined" onClick={handleSet}>
            Use this List
          </Button>
        </DialogActions>
      </Dialog>

      {/* Form */}
      <Box
        display="flex"
        flexWrap={"wrap"}
        flexGrow={2}
        sx={{
          marginTop: "10px",
          '& > *:not(:last-child)': {
            marginRight: "10px",
          },
        }}
      >
        <p style={{ marginRight: "15px" }}>
          Search for candidates for the role
        </p>
        <FormControl variant="standard" sx={{ minWidth: 250, marginTop: "-10px" }}>
          <InputLabel id="demo-simple-select-standard-label">*Role</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={role}
            label="Role"
            onChange={handleRole}
            onClick={() => { if (role) setDesignationDialog(true) }}
          >
            {rolesData.map((value, index) => (
              <MenuItem key={index} value={(value.name, value.value)}>
                {value.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedDesignations?.length ? (
          <p>
            ({selectedDesignations?.length} Designations)
          </p>
        ) : null}
        <p>.</p>
        <p>
          These candidates should have
        </p>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} disabled={plusIconClicked}>
          <Select
            labelId={`demo-simple-select-${plusIconClicked ? "disabled" : "standard"}-label`}
            id={`demo-simple-select-${plusIconClicked ? "disabled" : "standard"}`}
            value={skillType}
            style={{ height: "auto" }}
            onChange={(event) => {
              event.preventDefault();
              setSkillType(event.target.value)
            }}
          >
            {["Mandatory", "Optional"].map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <p>
          skills like
        </p>
        {
          skillType === "Mandatory" ? (
            mandatorySkillComponent()
          ) : (optionalSkillComponent())
        }

        <p>
          (use ENTER to separate)
        </p>

        {!plusIconClicked ? (
          <Button style={{ border: "none", fontSize: "24px" }} onClick={() => {
            setPlusIconClicked(true)
          }}>
            +
          </Button>
        ) : (
          <>
            <p>
              And These candidates should have
            </p>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, marginTop: "10px" }} disabled>
              <Select
                labelId={`demo-simple-select-disabled-label`}
                id={`demo-simple-select-disabled`}
                style={{ height: "auto" }}
                defaultValue={skillType === "Mandatory" ? "Optional" : "Mandatory"}
                value={skillType === "Mandatory" ? "Optional" : "Mandatory"}
              >
                {["Mandatory", "Optional"].map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <p>
              skills like
            </p>
            <div>
              {
                skillType === "Mandatory" ? (
                  optionalSkillComponent()
                ) : (mandatorySkillComponent())
              }
            </div>
            <p>
              (use ENTER to separate).
            </p>
          </>
        )}
        <p>
          Candidates should be from the city
        </p>
        <TextField
          sx={{ width: 250 }}
          label="*Location"
          autoComplete="off"
          value={location}
          variant="standard"
          onChange={handleLocationChange}
        />
        <p>Also, target candidates from this</p>
          <Button
            style={{ padding: "0px 10px 0px 10px", border: "1px solid #2196f3", height: "40px" }}
            onClick={() => { setClusterDialog(true) }}
          >
            {useClusterList ? activeCluster?.title : "Company List"}
          </Button>
        <p>.</p>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <p style={{ marginRight: "10px" }}>
            The experience range of candidates should be between
          </p>
          <FormControl variant="standard" FormControl sx={{ minWidth: 250 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Experience
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={experiance}
              label="Experience"
              onChange={handleExperiance}
            >
              {experianceData.map((value, index) => (
                <MenuItem key={index} value={value.value}>
                  {value.years}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ display: "flex", flexDirection: "row", }}>
          <p style={{ marginRight: "10px" }}>
            Search on the headline of linkedin profile for
          </p>
          <TextField
            sx={{ width: 250 }}
            label="Headline Search"
            autoComplete="off"
            variant="standard"
            value={headlineSearch}
            onChange={(event) => setHeadlineSearch(event.target.value)}
          />
        </div>
      </Box>

      <Box
        sx={{
          marginTop: "15px",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              value={femaleCheck}
              onChange={handleFemaleCheck}
              defaultChecked={femaleCheck !== ""}
            />
          }
          label="Female Candidates"
        />

        <FormControlLabel
          control={
            <Checkbox
              value={openForJobs}
              onChange={handleOpenForJobs}
              defaultChecked={openForJobs !== ""}
            />
          }
          label="Open For Jobs"
        />
      </Box>

      <Box marginTop="10px">
        {error && (
          <Typography
            fontWeight={600}
            variant="caption"
            display="block"
            sx={{ color: "Red" }}
          >
            *You can only select upto 32 Keywords.
          </Typography>
        )}

        {mainError && (
          <Typography
            fontWeight={600}
            variant="caption"
            display="block"
            sx={{ color: "red" }}
          >
            *Select both of the Fields (Location and Role)
          </Typography>
        )}
      </Box>

      <Box marginLeft="84%" marginTop="2%">
        <Button
          variant="outlined"
          sx={{ width: "80px", height: "40px" }}
          onClick={handleClick}
        >
          {loader ? <CircularProgress size={20} /> : "Submit"}
        </Button>
      </Box>
    </>
  );
};

const AutoCompleteXrayComponent = ({ value, clusterList, setClusterList, setIsClusterEdited }) => {
  const [tags, setTags] = useState([])

  useEffect(() => {
    if (!clusterList?.length) {
      setTags([])
    }
  }, [clusterList])

  useEffect(() => {
    if (value && Object.keys(value)?.length) {
      let companies = value?.xrayCompanies || []
      setTags(companies)
    }
  }, [value])

  const handleTagChange = (event, newTags) => {
    setTags(newTags);
    setIsClusterEdited(true);

    let tempData = clusterList
    tempData = tempData.map(item => {
      if (item._id === value._id) {
        item.xrayCompanies = newTags
      }

      return item
    })

    setClusterList(tempData)
  };

  const handleDeleteTag = (tagToDelete) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
    setIsClusterEdited(true)
  };
  return (
    <Autocomplete
      multiple
      freeSolo
      value={tags}
      onChange={handleTagChange}
      options={[]}
      style={{ marginTop: "10px" }}
      renderTags={(value, getTagProps) =>
        value?.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={() => handleDeleteTag(tag)}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Company Names"
          placeholder="Add Company Names"
        />
      )}
    />
  )
}

export default XRayForm;
