import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  totalChats: 0,
  chats: [],
  chatHistories: [],
  firstChat:[],
  currentChat: false,
  booleanString: false,
  error: false,
  currentQuestions: [],
  assessmentCompleted: true,
  learningCompleted:true,
  typeWriterEffect: false,
  showCountinue: false,
  showGeneratedLinks:false,
  userRatings:false,
  companyCluster:[],
  showCompanies:[],
  formCompanyCluster:false,

  // update/delete company cluster title
  loadingCompanyCluster: false,
  successCompanyCluster: "",
  errorCompanyCluster: ""
};

export const chatsSlice = createSlice({
  name: "chats",
  initialState,

  reducers: {
    chatsRequest: (state) => {
      state.loading = true;
    },
    getChatsSuccess: (state, action) => {
      state.loading = false;
      state.chats = action.payload.data;
      state.totalChats = action.payload.totalLength;
      state.error = false;
    },
    getChatsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createChatSuccess: (state, action) => {
      state.loading = false;
      state.currentChat = action.payload;
    },
    createChatFailure: (state) => {
      state.loading = false;
      state.error = "Some Error";
    },
    deleteChatSuccess: (state) => {
      state.loading = false;
      state.error = false;
    },
    deleteChatFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.meta.message;
    },
    updateChatTitleSuccess: (state) => {
      state.loading = false;
      state.error = false;
    },
    updateChatTitleFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.meta.message;
    },
    getChatHistoriesSuccess: (state, action) => {
      // state.loading = false;
      state.chatHistories = action.payload.data
    },
    getFirstChatSuccess: (state, action) => {
      // state.loading = false;
      state.firstChat = action.payload.data
    },
    getChatHistoriesFailure: (state) => {
      state.loading = false;
    },
    updateChatHistoriesSuccess: (state, action) => {
      // state.loading = false;
    },
    updateChatHistoriesFailure: (state) => {
      state.loading = false;
    },
    getBooleanSuccess: (state, action) => {
      state.loading = false;
      state.booleanString = action.payload;
    },
    getBooleanFailure: (state) => {
      state.loading = false;
    },
    updateLearningCompleted: (state, action) => {
      state.learningCompleted = action.payload;
    },
    updateAssessmentCompleted: (state, action) => {
      state.assessmentCompleted = action.payload;
    },
    updateTypeWriterEffect: (state, action) => {
      state.typeWriterEffect = action.payload;
    },
    updateShowCountinue: (state, action) => {
      state.showCountinue = action.payload;
    },
    updateShowGeneratedLinks: (state, action) => {
      state.showGeneratedLinks = action.payload;
    },
    updateUserRatings:(state, action) => {
      state.userRatings = action.payload;
    },
    getCompanyCluster: (state, action) => {
      state.companyCluster = action.payload.data;
    },
    updateShowCompanies: (state, action) => {
      state.showCompanies = action.payload;
    },
    updateFormCompanyCluster: (state, action) => {
      state.formCompanyCluster = action.payload;
    },

    // create company cluster
    createCompanyCluster: (state) => {
      state.loadingCompanyCluster = true;
    },
    createCompanyCluterSuccess: (state, action) => {
      state.loadingCompanyCluster = false;
      state.successCompanyCluster = action.payload;
      state.errorCompanyCluster = "";
    },
    createCompanyCluterFailure: (state, action) => {
      state.loadingCompanyCluster = false;
      state.successCompanyCluster = "";
      state.errorCompanyCluster = action.payload;
    },

    // update company cluster
    updateCompanyCluster: (state) => {
      state.loadingCompanyCluster = true;
    },
    updateCompanyClusterSuccess: (state, action) => {
      state.loadingCompanyCluster =false;
      state.successCompanyCluster = action.payload;
      state.errorCompanyCluster = "";
    },
    updateCompanyClusterFailure: (state, action) => {
      state.loadingCompanyCluster = false;
      state.errorCompanyCluster = action.payload;
      state.successCompanyCluster = "";
    },

    // update company cluster title
    updateCompanyClusterTitle: (state) => {
      state.loadingCompanyCluster = true;
    },
    updateCompanyClusterTitleSuccess: (state, action) => {
      state.loadingCompanyCluster =false;
      state.successCompanyCluster = action.payload;
      state.errorCompanyCluster = "";
    },
    updateCompanyClusterTitleFailure: (state, action) => {
      state.loadingCompanyCluster = false;
      state.errorCompanyCluster = action.payload;
      state.successCompanyCluster = "";
    },

    // delete company cluster
    deleteCompanyCluster: (state) => {
      state.loadingCompanyCluster = true;
    },
    deleteCompanyClusterSuccess: (state, action) => {
      state.loadingCompanyCluster =false;
      state.successCompanyCluster = action.payload;
      state.errorCompanyCluster = "";
    },
    deleteCompanyClusterFailure: (state, action) => {
      state.loadingCompanyCluster = false;
      state.errorCompanyCluster = action.payload;
      state.successCompanyCluster = "";
    },

    // convert to xray company cluster
    convertToXrayCompanyCluster: (state) => {
      state.loadingCompanyCluster = true;
    },
    convertToXrayCompanyClusterSuccess: (state, action) => {
      state.loadingCompanyCluster =false;
      state.successCompanyCluster = action.payload;
      state.errorCompanyCluster = "";
    },
    convertToXrayCompanyClusterFailure: (state, action) => {
      state.loadingCompanyCluster = false;
      state.errorCompanyCluster = action.payload;
      state.successCompanyCluster = "";
    }
  },
});

export const {
  // common loader for chats
  chatsRequest,
  // get all chats
  getChatsSuccess,
  getChatsFailure,
  // create a new chat
  createChatSuccess,
  createChatFailure,
  // delete an existing chat
  deleteChatSuccess,
  deleteChatFailure,
  // update a chat (basically update it's title)
  updateChatTitleSuccess,
  updateChatTitleFailure,
  // chat histories
  getChatHistoriesSuccess,
  getFirstChatSuccess,
  getChatHistoriesFailure,
  // get boolean data
  getBooleanSuccess,
  getBooleanFailure,
  updateUserRatings,
  // update when the assessment is completed
  updateAssessmentCompleted,
  // To apply the typewriter effect
  updateLearningCompleted,
  updateTypeWriterEffect,
  updateShowCountinue,
  getCompanyCluster,
  updateShowGeneratedLinks,
  updateFormCompanyCluster,
  updateShowCompanies,

  // create company cluster
  createCompanyCluster,
  createCompanyCluterSuccess,
  createCompanyCluterFailure,

  // update company cluster
  updateCompanyCluster,
  updateCompanyClusterSuccess,
  updateCompanyClusterFailure,

  // update company cluster title
  updateCompanyClusterTitle,
  updateCompanyClusterTitleSuccess,
  updateCompanyClusterTitleFailure,

  // delete company cluster
  deleteCompanyCluster,
  deleteCompanyClusterSuccess,
  deleteCompanyClusterFailure,

  // Convert to xray company cluster
  convertToXrayCompanyCluster,
  convertToXrayCompanyClusterSuccess,
  convertToXrayCompanyClusterFailure,

} = chatsSlice.actions;

export default chatsSlice.reducer;
