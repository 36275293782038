import { Avatar, Box, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import UserAvatar from "./UserAvatar";
import { useSelector } from "react-redux";

const BotMessage = ({ children }) => {
  return (
    <Stack
      gap={4}
      sx={{ padding: "20px 15%" }}
      alignSelf="center"
      alignItems="flex-start"
      width="100%"
      direction="row"
      backgroundColor={grey[50]}
    >
      <UserAvatar name="" logoUrl="/img/tabdy-logo-icon-with padding.png"></UserAvatar>
      <Box mt={1}>
        {children}
      </Box>
    </Stack>
  );
};

const UserMessage = ({ children }) => {
  const userDetails = useSelector((state) => state.auth.user);
  return (
    <Stack
      gap={4}
      sx={{ padding: "20px 15%" }}
      alignSelf="center"
      alignItems="flex-start"
      width="100%"
      direction="row"
      backgroundColor={grey[100]}
    >
      <Avatar
        sx={{
          width: "35px",
          height: "35px",
          borderRadius: "5px",
          fontSize: 16,
        }}
        variant="square"
        alt={userDetails.firstName || "Unknown"}
        src={userDetails?.picture}
      />
      {/* <UserAvatar name="BOT"></UserAvatar> */}
      <Box mt={1}>{children}</Box>
    </Stack>
  );
};

export { BotMessage, UserMessage };
