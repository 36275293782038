const booleanLearning = [
  {
    value:
      "Boolean is nothing but a lingo understood by search engines. This lingo has only 5 words(operators) : AND, OR, NOT, “ “ , ( )<br/>",
    button: "Next Module",
  },
  {
    value:
      "Lets learn about the 5 words(operators) of Boolean and how to use them.<br/><br/>AND Operator : <br/><br/>Assume that you are very hungry and you’d want to eat a burger, pizza & also have a coke.<br/><br/>Burger AND Pizza AND Coke<br/><br/>This way, you tell the search engine to get you all three. AND is used whenever you want a keyword or skill to be made mandatory.",
    button: "Next : OR Operator",
  },
  {
    value:
      "OR Operator :<br/><br/>Case 1 : Assume, you aint too hungry and just want any snack/drink & you fine to have either burger, pizza or just a coke.<br/><br/>(Burger OR Pizza OR coke)<br/><br/>Case 2 : Assume, you want to have maybe burger or pizza and a coke<br/><br/>(Burger OR Pizza) AND Coke<br/><br/>Whenever a OR operator is used, embrace it with a bracket, just like case 1. So that search engine knows, its one loop and it will pick anyone out of it.<br/><br/>In case 2, if you notice we didnt put brackets to coke. Brackets is just for OR operator like (Burger OR Pizza) in this case.",
    button: "Next : NOT Operator",
  },
  {
    value:
      "NOT Operator :<br/><br/>This is used to negate anything which you don’t want. Let’s say, you’re a vegan and all your friends love chicken. You go for dinner and you’re ordering burger/pizza and coke/pepsi, your boolean will look like<br/><br/>(Burger OR Pizza) AND (Coke OR Pepsi) NOT Chicken<br/><br/>Now, you see there is NOT chicken. The boolean is saying, you want your food but it should not have chicken. And, there is OR to coke/pepsi, coz you want anyone in this case.",
    button: "Next : “ “ Operator",
  },
  {
    value:
      "“ “ Operator :<br/><br/>Whenever there is more than one word, but you want it to act like one one entity then “ “ to be used.<br/><br/>For example, you’re searching a restaurant menu using Boolean and you are looking for chicken burger<br/><br/>Case 1: Chicken Burger = Chicken AND Burger<br/><br/>When you search without double quotes, then it would also pick those results which has chicken in some corner of the menu and burger in some other corner. Maybe the restaurant serves Chicken Sandwich and Egg Burger, still you’d get the result. Because, when chicken burger is given without “ “, then its treated as two different words Chicken AND Burger.<br/><br/>Case 2: “Chicken Burger” = Chicken Burger<br/><br/>When you search with double quotes, then its treated as one entity and you’d get results if and only if the menu has Chicken Burger.<br/><br/>Awesome, now you have learnt the Boolean lingo. Click on the sample boolean to view how a complete boolean looks like",
    button: "Sample Boolean",
  },
  {
    value:
      "(”Veg Burger” OR “Veg Pizza”) AND (Coke OR Pepsi) NOT Chicken<br/><br/>Double quotes used for veg burger and pizza, to ensure it picks specifically veg burger or veg pizza. NOT is used to negate chicken, assuming you are a vegan.",
    button: "NEXT",
  },
  {
    value:
      "Hurray !!! You’ve completed the basic boolean concepts. I hope I didnt get your mouth watery by picking up a foodie example. ;)",
    button: false,
  },
];

const xrayLearning = [
  {
    value:
      "All of us would have used Google or any search engine for searching gadgets, booking a flight or hotel etc… But, we would not have searched it by knowing the operators of that search engine.<br/><br/>Xray is all about leveraging a search engine to perform targeted searches on a specific website (Public sites only). In this module we will be focusing on Google as a search engine.<br/><br/> All set for the learning?",
    button: "Yes, let’s start",
  },
  {
    value:
      "site:<br/>This is the primary operator of xray, this targets a specific website you’d want to index. For example, you’d want to search data from Linkedin.<br/><br/>site:linkedin.com —> This simple command tells google to index data only from linkedin.<br/><br/>Note: There is no space after site: & ensure it is in lower case.",
    button: "Next Module",
  },
  {
    value:
      "Let us understand domain names & subdirectories or subfolders. If you visit any website, www.abc.com (this is a domain). Now, a website can have one or more subfolders.<br/> What is a subfolder?<br/><br/>It's a way of organizing the data in that website in a structured format. For example : www.abc.com/jobs → abc.com is the domain name & abc.com/jobs is the subfolder which consists of all the jobs.<br/><br/> How to identify subfolders in any website?<br/><br/>Firstly, all websites may not have subfolders, some do and some don’t. Let us learn how to figure out a subfolder.<br/><br/>Recruiters always focus on getting candidates from any given website. Lets pick linkedin as an example, we learnt site:linkedin.com helps us index data specific to LinkedIn. But, we want data specific to candidates (We dont need jobs/companies related data)<br/><br/>Step 1: In Google, enter <b>site:linkedin.com</b><br/><br/>Step 2: Write any common or generic name along with it → <b>site:linkedin.com Joseph</b><br/><br/>Step 3: Open a few links from the search results & observe the URL of the candidate profiles opened up, the URL will look something like this<br/><br/>https://www.linkedin.com/in/joseph-joe-george-1760605<br/><br/>https://www.linkedin.com/in/kjosephabraham/<br/><br/>You will observe a pattern, all of the profiles have one thing in common: linkedin.com/in & followed by the unique name or user ID.<br/><br/>/in : This is a subfolder in linkedin which consists of all the candidate data. There are other subfolders in linkedin like /company, /groups, /jobs etc….<br/><br/>site:linkedin.com/in → This is our query to index candidate data from linkedin.",
    button: "Next : intitle",
  },
  {
    value:
      "intitle: This operator is used to index the title. From the previous example, key in <b>site:linkedin.com/in Marketing.</b> Observe the results, you’d find a blue color text consisting of name & designations. Below is one such example :<br/><br/> Joseph Abraham - Founder and CEO - Venture Atom<br/><br/>If you want to search candidates who have a certain designation as their current designation then the intitle operator comes handy.<br/><br/> With vs Without intitle:<br/><br/>With : <b>site:linkedin.com/in intitle:Marketing</b><br/><br/>This will give you data of all those candidates who have marketing as their current designation<br/><br/>Without : <b>site:linkedin.com/in Marketing</b><br/><br/>This will give you candidates who would have the word marketing anywhere on their profile, not necessarily as their current designation<br/><br/>",
    button: "Next : AND OR ()",
  },
  {
    value:
      "Underlying methodology of search even for Xray is Boolean.<br/><br/>AND : If you want two words as mandatory, then just use space. For example : SEO Ads, this means SEO AND Ads. OR : If you want to use synonyms or alternate words then use OR (or) | . Example : (Writing OR Blogs ) or you could also write  (Writing | Blogs )<br/><br/> ( ) : Whenever you are using OR use ( )<br/><br/>- : To negate anything you can use minus. Example: -Sales<br/><br/>If you want to negate more than one word, example : -Sales -Seller -”Business Development”.<br/><br/>Note: Since Business Development consists of two words, but it is one entity we have embraced it with “ “ and a minus to negate.<br/><br/>Sample : <br/><br/>site:linkedin.com/in SEO Ads (Writing | Blogs) intitle:Marketing -sales -seller -”Business Development”",
    button: "Next : Location",
  },
  {
    value:
      "How to target a specific city using xray?<br/><br/>Method 1 : This method will bring broader results and will read the entire profile.<br/><br/>Look at the writing behavior of users for each city on top of linkedin<br/><br/>San Diego, California → “San Diego, California”<br/><br/>Bengaluru, Karnataka → “Bengaluru, Karnataka”<br/><br/>Xray example with location : <br/><br/>site:linkedin.com/in SEO Ads (Writing | Blogs) intitle:Marketing “San Diego, California”  -sales -seller -”Business Development”<br/><br/> Method 2: This method will specifically index or look for the city name mentioned right below the name of the candidate. Which potentially means the candidate is residing in that city.<br/><br/>inanchor:”San Diego”<br/><br/>inanchor:Bengaluru<br/><br/>Xray example with inanchor : <br/><br/>site:linkedin.com/in SEO Ads (Writing | Blogs) intitle:Marketing inanchor:“San Diego”  -sales -seller -”Business Development” <br/><br/>Important Notes : <br/><br/>This method will bring false positives as well. If a candidate has studied in “UC San Diego'' university or Bengaluru University.Don’t write the complete city name including the state name like method 1.",
    button: "Take Quiz",
  },
  // {
  //   value:
  //     "Hurray !!! You’ve completed the basic Xray concepts. I hope I helped you learn the fundamentals. Take up a quick quiz of 5mins & get certified by the world's first BOT driven training.",
  //   button: "",
  // },
];

const assessmentLearning = [
  {
    value: "Q1: What is the purpose of Xray?",
    type: "MCQ",
    options: [
      "Search across web",
      "Search a targeted site",
      "Automated Search",
      "Search via all search engines",
    ],
    correctAnswer: "Search a targeted site",
  },
  {
    value:
      "Q2: Select the operator to index a designation of a candidate ?",
    type: "MCQ",
    options: ["Title:", "intitle:", "site:", "Intitle:"],
    correctAnswer: "intitle:",
  },
  {
    value:
      "Q3: Which of the below will give data specific to candidates?",
    type: "MCQ",
    options: [
      "linkedin.com/candidates",
      "linkedin.com/profiles",
      "linkedin.com/profile",
      "linkedin.com/in",
    ],
    correctAnswer: "linkedin.com/in",
  },
  {
    value:
      "Q4: From your learnings of domain names & subfolders, figure out the URL to spot candidates from www.stackoverflow.com. (Implement your step by step learnings to figure out subfolder)<br/>Your answer should be site:stackoverflow.com/? (fill the question mark)?",
    type: "TEXT",
    correctAnswer: "site:stackoverflow.com/users",
  },
  {
    value:
      "Q5:Which is an appropriate keyword to look for candidates currently residing in Bengaluru ?",
    type: "MCQ",
    options: [
      "“Bengaluru, Karnataka”",
      "intitle:”Bengaluru”",
      "Bengaluru",
      "inanchor:Bengaluru",
    ],
    correctAnswer: "inanchor:Bengaluru",
  },
];

export { booleanLearning, xrayLearning, assessmentLearning };
