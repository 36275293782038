import {
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import ChatCard from "./ChatCard";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";

const SideBar = () => {
  const chatCards = useSelector((state) => state.chats.chats);
  const { assessmentCompleted } = useSelector((state) => state.chats);
  const isChatsLoading = useSelector((state) => state.chats.loading);

  return (
    <>
      {isChatsLoading ? (
        <Box
          flexGrow={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          m={2}
        >
          <CircularProgress size={40} />
        </Box>
      ) : (
        <>
          <Box m={1}>
            <Button
              fullWidth
              disabled={!assessmentCompleted}
              to="/app/chat"
              LinkComponent={Link}
              variant="outlined"
            >
              New Chat
            </Button>
          </Box>
          <Stack
            sx={{
              overflowY: "scroll",
            }}
            px={1}
            spacing={1}
            flexGrow={2}
            // divider={<Divider orientation="horizontal" flexItem />}
          >
            {chatCards.map((item) => (
              <ChatCard key={item["_id"]} item={item} />
            ))}
          </Stack>
          <Divider />
          <List
            sx={{
              p: 0,
            }}
          >
            <ListItem disablePadding>
              <ListItemButton LinkComponent={Link} to="about">
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText primary="About" />
              </ListItemButton>
            </ListItem>
            {/* <ListItem disablePadding>
                <ListItemButton LinkComponent={Link} to='services'>
                  <ListItemIcon>
                    <MiscellaneousServicesIcon />
                  </ListItemIcon>
                  <ListItemText primary="Services" />
                </ListItemButton>
              </ListItem> */}
          </List>
        </>
      )}
    </>
  );
};

export default SideBar;
