import {  Stack, Typography } from "@mui/material";
import React from "react";
import LockIcon from "@mui/icons-material/Lock";

const SideBarLocked = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      p={1}
      mt={2}
      flexGrow={2}
    >
      <Stack direction="row" alignItems="flex-end">
        <LockIcon fontSize="large" />
        <Typography variant="h5">Locked!!</Typography>
      </Stack>
      <Typography variant="h6">Please login to continue</Typography>
    </Stack>
  );
};

export default SideBarLocked;
