import { Box, IconButton } from "@mui/material";
import React from "react";

const TopBarLocked = () => {
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <IconButton size="small" edge="start" sx={{ mr: 2 }}>
        <img
          src="/img/tabdy-logo-no padding.png"
          alt="TA Buddy logo"
          style={{
            maxHeight: "35px",
          }}
        />
      </IconButton>
    </Box>
  );
};

export default TopBarLocked;
