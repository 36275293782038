import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useChatData from "../api/useChatData";
import XRayForm from "./XRayForm";
import TypeWriter from "./TypeWriter";
import { BotMessage, UserMessage } from "./MessageBox";
import {
  updateShowCountinue,
  updateTypeWriterEffect,
} from "../features/chats/chatsSlice";
import TypeWriterMultiple from "./TypeWriterMultiple";
import { rolesData } from "../data/FormsData";

const XRayChat = ({ stackRef, userDetails }) => {
  const [chats, setChats] = useState([]);
  const [value, setValue] = useState("");
  const [links, setLinks] = useState([]);
  const [output, setOutput] = useState([]);
  // XRAY form States
  const [role, setRole] = useState("");
  const [coustomRole, setCoustomRole] = useState("");
  const [experiance, setExperiance] = useState("");
  const [designation, setDesignation] = useState("");
  const [designationOptions, setDesignationOptions] = useState([]);
  const [selectedDesignations, setSelectedDesignations] = useState([]);
  const [location, setLocation] = useState("");
  const [mandatoryWords, setMandatory] = useState([]);
  const [eitherKeyword, seteitherKeyword] = useState([]);
  const [eitherInput, setEitherInput] = useState("");
  const [femaleCheck, SetFemaleCheck] = useState("");
  const [error, setError] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [input, setInput] = useState("");
  const [newDesignation, setNewDesignation] = useState("");
  const [openForJobs, setOpenForJobs] = useState("");
  const [headlineSearch, setHeadlineSearch] = useState("");
  const [company, setCompany] = useState([]);
  const [companyInput, setCompanyInput] = useState("");
  const [errorMandatory, setErrorMandatory] = useState(false);
  const [errorCompany, setErrorCompany] = useState(false);
  const [errorCoustom, setErrorCoustom] = useState(false);
  const [notSelected, setNotSelected] = useState(false);
  const [linkIndex, setLinkIndex] = useState(0);

  const { updateChatHistory } = useChatData();
  let { chatId, category } = useParams();
  const chatHistories = useSelector((state) => state.chats.chatHistories);
  const { typeWriterEffect } = useSelector((state) => state.chats);
  const { showCountinue } = useSelector((state) => state.chats);

  const dispatch = useDispatch();

  useEffect(() => {
    stackRef.current.scrollTop = stackRef.current.scrollHeight;
  }, [chatHistories, showCountinue]);

  useEffect(() => {
    dispatch(updateShowCountinue(false));

    if (chatHistories?.length !== 0) {
      if (chatHistories?.length !== 0 && !typeWriterEffect) {
        if (chatHistories[chatHistories?.length - 1].sender === "XRAYBOT") {
          dispatch(updateShowCountinue(true));
        }
      }
    }
  }, [chatHistories]);

  const currentDate = new Date();
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  const formattedDate = currentDate
    .toLocaleString("en-US", options)
    .replace(",", "")
    .replace(/[/]/g, "-")
    .replace(" ", " ");

  useEffect(() => {
    async function saveXRay() {
      const userData = JSON.stringify(output);
      const stringiFyLinks = JSON.stringify(links);

      await updateChatHistory(
        chatId,
        {
          message: userData,
          sender: "XRAYUSER",
          sentAt: formattedDate,
        },
        category
      );

      await updateChatHistory(
        chatId,
        {
          message: stringiFyLinks,
          sender: "XRAYBOT",
          sentAt: formattedDate,
        },
        category
      );
    }

    if (output?.length) {
      saveXRay();
    }
  }, [links]);

  const handleEdit = async () => {
    const prevData = JSON.parse(
      chatHistories[chatHistories?.length - 2].message
    );
    dispatch(updateTypeWriterEffect(false));
    setLinks([]);
    setOutput([]);
    setLinkIndex(0);

    if (prevData[0].role) {
      setRole(prevData[0].role);
      if (
        prevData[0].role !== "Software Developer/Engineer" ||
        prevData[0].role !== "Custom Role"
      ) {
        const options = rolesData.find(
          (role) => role.value === prevData[0].role
        );
        setDesignationOptions(options.options);
      }
    }
    if (prevData[0].designation) {
      const arrDesignation = prevData[0].designation.split(", ");
      setSelectedDesignations(arrDesignation);
    }
    if (prevData[0].experiance) {
      let val = prevData[0].experiance;
      if (val === "1-3 Years Experience") {
        setExperiance(">3");
      }
      if (val === "3-5 Years Experience") {
        setExperiance(">5");
      }
      if (val === "5-8 Years Experience") {
        setExperiance(">8");
      }
      if (val === "8-12 Years Experience") {
        setExperiance(">12");
      }
      if (val === "12+ Years Experience") {
        setExperiance("<12");
      }
    }
    if (prevData[0].location) {
      setLocation(prevData[0].location);
    }
    if(prevData[0].designationOption){
      setDesignationOptions(prevData[0].designationOption)
    }
    if (prevData[0].headlineSearch) {
      setHeadlineSearch(prevData[0].headlineSearch);
    }
    if(prevData[0].company){
      const companyArr = (prevData[0].company).split(", ")
      setCompany(companyArr);
    }
    if(prevData[0].mandatory){
      const mandatoryArr = (prevData[0].mandatory).split(", ")
      setMandatory(mandatoryArr);
    }
    if(prevData[0].either){
      const eitherArr = (prevData[0].either).split(", ")
      seteitherKeyword(eitherArr);
    }
    if(prevData[0].openForJobs){
      setOpenForJobs(true);
    }
    if(prevData[0].femaleCheck){
      SetFemaleCheck(true);
    }
    if(prevData[0].designationOption){
      setDesignation(prevData[0].designationOption)
    }  
    if(prevData[0].designationOptions){
      setDesignationOptions(prevData[0].designationOptions)
    }    

    await updateChatHistory(
      chatId,
      {
        message: "Generate New XRay Sourcing Links",
        sender: "XRAYNEW",
        sentAt: formattedDate,
      },
      category
    );
  };

  const handleContinue = async () => {
    dispatch(updateTypeWriterEffect(false));
    setLinks([]);
    setLinkIndex(0);
    setOutput([]);
    setRole("");
    setCoustomRole("");
    setExperiance("");
    setDesignation("");
    setDesignationOptions([]);
    setSelectedDesignations([]);
    setLocation("");
    setMandatory([]);
    seteitherKeyword([]);
    SetFemaleCheck("");
    setCompany([]);
    setCompanyInput("");
    setOpenForJobs("");
    setHeadlineSearch("");
    setInputValue("");
    setInput("");
    setNewDesignation("");
    await updateChatHistory(
      chatId,
      {
        message: "Generate New XRay Sourcing Links",
        sender: "XRAYNEW",
        sentAt: formattedDate,
      },
      category
    );
  };

  const renderMessage = (data) => {
    if (data?.length === 1) {
      return (
        <>
          {data?.map((value, index) => (
            <Typography key={index}>
              {value.name} -{" "}
              <Link href={value.link} target="_blank">
                <TypeWriter text={`Generated Link ${index + 1}`}></TypeWriter>
              </Link>
            </Typography>
          ))}
        </>
      );
    } else {
      if (linkIndex < data?.length) {
        const value = data[linkIndex];

        return (
          <Typography>
            {value.name} -{" "}
            <Link href={value.link} target="_blank">
              <TypeWriterMultiple
                text={`Generated Link ${linkIndex + 1}`}
                linkIndex={linkIndex}
                setLinkIndex={setLinkIndex}
              ></TypeWriterMultiple>
            </Link>
          </Typography>
        );
      } else {
        dispatch(updateShowCountinue(true));
      }
    }
  };

  return (
    <>
      <Stack
        p={1}
        flexGrow={2}
        sx={{
          overflowY: "scroll",
        }}
        ref={stackRef}
      >
        <Typography variant="h4">XRAY</Typography>
        <Divider />

        {chatHistories?.length === 0 && (
          <>
            <BotMessage>
              <Typography variant="button">
                Type in the below form & I will generate your X-ray strings and
                also I will organise the searches for you.
              </Typography>
              <FormControl>
                <XRayForm
                  role={role}
                  coustomRole={coustomRole}
                  experiance={experiance}
                  designation={designation}
                  designationOptions={designationOptions}
                  selectedDesignations={selectedDesignations}
                  location={location}
                  mandatoryWords={mandatoryWords}
                  eitherKeyword={eitherKeyword}
                  femaleCheck={femaleCheck}
                  error={error}
                  inputValue={inputValue}
                  input={input}
                  newDesignation={newDesignation}
                  openForJobs={openForJobs}
                  headlineSearch={headlineSearch}
                  company={company}
                  companyInput={companyInput}
                  errorMandatory={errorMandatory}
                  errorCompany={errorCompany}
                  errorCoustom={errorCoustom}
                  notSelected={notSelected}
                  eitherInput={eitherInput}
                  setEitherInput={setEitherInput}
                  setNotSelected={setNotSelected}
                  setErrorMandatory={setErrorMandatory}
                  setErrorCompany={setErrorCompany}
                  setErrorCoustom={setErrorCoustom}
                  setCompany={setCompany}
                  setCompanyInput={setCompanyInput}
                  setRole={setRole}
                  setCoustomRole={setCoustomRole}
                  setExperiance={setExperiance}
                  setDesignation={setDesignation}
                  setDesignationOptions={setDesignationOptions}
                  setSelectedDesignations={setSelectedDesignations}
                  setLocation={setLocation}
                  setMandatory={setMandatory}
                  seteitherKeyword={seteitherKeyword}
                  SetFemaleCheck={SetFemaleCheck}
                  setError={setError}
                  setInputValue={setInputValue}
                  setInput={setInput}
                  setNewDesignation={setNewDesignation}
                  setOpenForJobs={setOpenForJobs}
                  setHeadlineSearch={setHeadlineSearch}
                  setLinks={setLinks}
                  setOutput={setOutput}
                ></XRayForm>
              </FormControl>
            </BotMessage>
            <Box sx={{ borderBottom: "1px solid lightgrey" }} />
          </>
        )}

        {chatHistories?.length !== 0 && (
          <>
            {chatHistories?.map((value, mainIndex) => (
              <>
                {value.sender === "XRAYUSER" && (
                  <UserMessage>
                    {JSON.parse(value.message)?.map((value) => (
                      <>
                        {value.role && (
                          <>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Role 
                            </Typography>
                            {value.role + "\n"}
                          </>
                        )}
                        {value.headlineSearch && (
                          <>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Headline Search
                            </Typography>
                            {value.headlineSearch + "\n"}
                          </>
                        )}
                        {value.designation && (
                          <>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Designation
                            </Typography>
                            {value.designation + "\n"}
                          </>
                        )}
                        {value.experiance && (
                          <>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Experience
                            </Typography>
                            {value.experiance + "\n"}
                          </>
                        )}
                        {value.company && (
                          <>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Company Cluster
                            </Typography>
                            {value.company + "\n"}
                          </>
                        )}
                        {value.mandatory && (
                          <>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Mandatory Keywords
                            </Typography>
                            {value.mandatory + "\n"}
                          </>
                        )}
                        {value.either && (
                          <>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Either OR Keywords
                            </Typography>
                            {value.either + "\n"}
                          </>
                        )}
                        {value.location && (
                          <>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Location
                            </Typography>
                            {value.location + "\n"}
                          </>
                        )}
                        {value.femaleCheck && (
                          <>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Female Candidate
                            </Typography>
                            {value.femaleCheck + "\n"}
                          </>
                        )}
                        {value.openForJobs && (
                          <>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Open For Jobs
                            </Typography>
                            {value.openForJobs + "\n"}
                          </>
                        )}
                      </>
                    ))}
                  </UserMessage>
                )}

                {value.sender === "XRAYBOT" && (
                  <BotMessage>
                    {JSON.parse(value.message)?.length === 1 ? (
                      <>
                        <Typography>
                          Hoollaa {userDetails && `${userDetails.firstName}`},
                          here you go!!!
                        </Typography>

                        <Typography>
                          I have clustered your input into X-ray search as below
                        </Typography>
                        <br />
                      </>
                    ) : (
                      <>
                        <Typography>
                          Hoollaa {userDetails && `${userDetails.firstName}`},
                          here you go!!!
                        </Typography>
                        <Typography>
                          I have clustered your input into multiple X-ray
                          searches as below
                        </Typography>
                        <br />
                      </>
                    )}

                    {chatHistories?.length === mainIndex + 1 &&
                    typeWriterEffect ? (
                      <>
                        {JSON.parse(value.message)
                          ?.slice(0, linkIndex)
                          ?.map((value, index) => (
                            <Typography key={index}>
                              {value.name} -{" "}
                              <Link href={value.link} target="_blank">
                                {`Generated Link ${index + 1}`}
                              </Link>
                            </Typography>
                          ))}

                        {renderMessage(JSON.parse(value.message))}
                      </>
                    ) : (
                      JSON.parse(value.message)?.map((value, index) => (
                        <Typography key={index}>
                          {value.name} -{" "}
                          <Link href={value.link} target="_blank">
                            {`Generated Link ${index + 1}`}
                          </Link>
                        </Typography>
                      ))
                    )}
                  </BotMessage>
                )}

                {value.sender === "XRAYNEW" && (
                  <>
                    <UserMessage>{value.message}</UserMessage>
                  </>
                )}

                {mainIndex + 1 === chatHistories?.length &&
                  value.sender === "XRAYBOT" &&
                  showCountinue && (
                    <>
                      <Box sx={{ borderTop: "1px solid lightgrey" }} />
                      <BotMessage>
                        <FormControl>
                          <Typography mb={2} variant="button">
                            Continue To generate a New Boolean OR Edit the
                            Existing Form
                          </Typography>
                          <Box gap={1} display="flex">
                            <Button
                              variant="outlined"
                              sx={{ width: "100px" }}
                              onClick={handleContinue}
                            >
                              Continue
                            </Button>
                            <Button
                              variant="outlined"
                              sx={{ width: "100px" }}
                              onClick={handleEdit}
                            >
                              Edit
                            </Button>
                          </Box>
                        </FormControl>
                      </BotMessage>
                    </>
                  )}

                {mainIndex + 1 === chatHistories?.length &&
                  value.sender === "XRAYNEW" && (
                    <>
                      <Box sx={{ borderTop: "1px solid lightgrey" }} />
                      <BotMessage>
                        <Typography variant="button">
                          Fill out The Given Below Form.
                        </Typography>
                        <XRayForm
                          role={role}
                          coustomRole={coustomRole}
                          experiance={experiance}
                          designation={designation}
                          designationOptions={designationOptions}
                          selectedDesignations={selectedDesignations}
                          location={location}
                          mandatoryWords={mandatoryWords}
                          eitherKeyword={eitherKeyword}
                          femaleCheck={femaleCheck}
                          error={error}
                          eitherInput={eitherInput}
                          setEitherInput={setEitherInput}
                          inputValue={inputValue}
                          input={input}
                          newDesignation={newDesignation}
                          openForJobs={openForJobs}
                          headlineSearch={headlineSearch}
                          company={company}
                          companyInput={companyInput}
                          errorMandatory={errorMandatory}
                          errorCompany={errorCompany}
                          errorCoustom={errorCoustom}
                          notSelected={notSelected}
                          setNotSelected={setNotSelected}
                          setErrorMandatory={setErrorMandatory}
                          setErrorCompany={setErrorCompany}
                          setErrorCoustom={setErrorCoustom}
                          setCompany={setCompany}
                          setCompanyInput={setCompanyInput}
                          setRole={setRole}
                          setCoustomRole={setCoustomRole}
                          setExperiance={setExperiance}
                          setDesignation={setDesignation}
                          setDesignationOptions={setDesignationOptions}
                          setSelectedDesignations={setSelectedDesignations}
                          setLocation={setLocation}
                          setMandatory={setMandatory}
                          seteitherKeyword={seteitherKeyword}
                          SetFemaleCheck={SetFemaleCheck}
                          setError={setError}
                          setInputValue={setInputValue}
                          setInput={setInput}
                          setNewDesignation={setNewDesignation}
                          setOpenForJobs={setOpenForJobs}
                          setHeadlineSearch={setHeadlineSearch}
                          setLinks={setLinks}
                          setOutput={setOutput}
                        ></XRayForm>
                      </BotMessage>
                    </>
                  )}

                <Box sx={{ borderBottom: "1px solid lightgrey" }} />
              </>
            ))}
          </>
        )}
      </Stack>
      <Stack mt={2} direction="row" spacing={1}>
        <TextField
          disabled
          fullWidth
          value={value}
          onChange={(e) => setValue(e.target.value)}
          size="small"
          placeholder="Write your response here..."
          variant="outlined"
        />
        <Button
          disabled
          onClick={() => setChats([...chats, value])}
          variant="contained"
          color="primary"
          size="small"
        >
          <SendIcon />
        </Button>
      </Stack>{" "}
    </>
  );
};

export default XRayChat;
