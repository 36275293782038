import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateShowCountinue } from "../features/chats/chatsSlice";

function TypeWriterBoolean({ text }) {
  const [displayedText, setDisplayedText] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof text !== "string") {
      return;
    }

    let currentIndex = -1;
    const interval = setInterval(() => {
      if (currentIndex >= text?.length - 1) {
        clearInterval(interval);
        dispatch(updateShowCountinue(true));
        return;
      }

      currentIndex++;
      setDisplayedText(text.slice(0, currentIndex + 1));
    }, 25);

    return () => {
      clearInterval(interval);
    };
  }, [text]);

  return <span>{displayedText}</span>;
}

export default TypeWriterBoolean;
