import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useAuthData from "../api/useAuthData";

const LoginSuccess = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { getUserDetails } = useAuthData();

  useEffect(() => {
    const token =
      searchParams.get("accessToken") || localStorage.getItem("token");
    if (token) {
      getUserDetails(token);
    }
  }, []);

  return <div>loading...</div>;
};

export default LoginSuccess;
