import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  Typography,
  RadioGroup,
  Stack,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BotMessage } from "./MessageBox";
import useChatData from "../api/useChatData";

const NewChat = () => {
  const authData = useSelector((state) => state.auth);
  const chatData = useSelector((state) => state.chats);
  const { createChat } = useChatData();

  const [catValue, setCatValue] = useState(false);
  const categories = ["BOOLEAN", "LINKEDIN", "XRAY", "HIRING_EFFORT"]; // Removed 

  const handleChange = (event) => {
    setCatValue(event.target.value);
  };

  useEffect(() => {
    if (catValue) {
      createChat({
        title: "Default Title",
        category: categories[catValue],
      });
    }
  }, [catValue]);

  if (authData.loading || chatData.loading)
    return (
      <Box
        flexGrow={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        m={2}
      >
        <CircularProgress size={40} />
      </Box>
    );
  else
    return (
      <Stack
        p={1}
        flexGrow={2}
        sx={{
          overflowY: "scroll",
        }}
      >
        <BotMessage>
          Hey There, I'm your TABuddy for Sourcing. I have been trained to help you in your sourcing.
        </BotMessage>
        <Box sx={{ borderBottom: '1px solid lightgrey' }} />
        <Box sx={{ borderBottom: '1px solid lightgrey' }} />
        <BotMessage>
          <FormControl>
            <Typography mb={2}>
              Click on any of the below & get started.
            </Typography>
            <RadioGroup
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 18,
                  color:"#2196f3"
                },
              }}
              name="category"
              value={catValue}
              onChange={handleChange}
            >
              {categories.map((item, index) => (
                <FormControlLabel
                  sx={{color:"#2196f3", border:"1px solid #2196f3" ,borderRadius:"20px", marginBottom:"10px", width:"200px"}}
                  key={index}
                  value={index}
                  control={<Radio sx={{margin:"5px 10px"}}/>}
                  label={<Typography sx={{fontSize:"15px"}} variant="button">{item}</Typography>}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </BotMessage>
        <Box sx={{ borderBottom: '1px solid lightgrey' }} />

      </Stack>
    );
};

export default NewChat;
