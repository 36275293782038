import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useChatData from "../api/useChatData";
import { BotMessage, UserMessage } from "./MessageBox";
import HiringEffortCalculatorForm from "./HiringEffortCalculatorForm";
import TypeWriter from "./TypeWriter";
import {
  updateShowCountinue,
  updateTypeWriterEffect,
} from "../features/chats/chatsSlice";

const HiringEffortCalculatorChat = ({ stackRef, userDetails }) => {
  const chatHistories = useSelector((state) => state.chats.chatHistories);
  const { typeWriterEffect } = useSelector((state) => state.chats);
  const { updateChatHistory } = useChatData();
  let { chatId, category } = useParams();
  const dispatch = useDispatch();
  const { showCountinue } = useSelector((state) => state.chats);

  const currentDate = new Date();
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  const formattedDate = currentDate
    .toLocaleString("en-US", options)
    .replace(",", "")
    .replace(/[/]/g, "-")
    .replace(" ", " ");

  useEffect(() => {
    stackRef.current.scrollTop = stackRef.current.scrollHeight;
  }, [chatHistories]);

  useEffect(() => {
    if (
      chatHistories.length !== 0 &&
      chatHistories[chatHistories.length - 1].sender === "HIRINGBOT"
    ) {
      dispatch(updateShowCountinue(true));
    }
  }, []);


  const [position, setPosition] = useState("");
  const [joining, setJoining] = useState("");
  const [interview, setInterview] = useState("");
  const [submission, setSubmission] = useState("");
  const [profiles, setProfiles] = useState("");
  const [initialData, setInitialData] = useState("");
  const [newProfile, setNewProfile] = useState("");
  const [output, setOutput] = useState(false);
  const [userOutput, setUserOutput] = useState(false);

  useEffect(() => {
    async function updateChat() {
      const userData = JSON.stringify(userOutput);

      await updateChatHistory(
        chatId,
        {
          message: userData,
          sender: "HIRINGUSER",
          sentAt: formattedDate,
        },
        category
      );

      await updateChatHistory(
        chatId,
        {
          message: output,
          sender: "HIRINGBOT",
          sentAt: formattedDate,
        },
        category
      );
    }

    // Calling UpdateChat if output
    if (userOutput) {
      updateChat();
    }
  }, [output]);

  const handleContinue = async () => {
    setOutput("");
    setUserOutput("");
    setPosition("");
    setJoining("");
    setSubmission("");
    setInterview("");
    setProfiles("");
    setInitialData("");
    setNewProfile("");
    dispatch(updateTypeWriterEffect(false));
    dispatch(updateShowCountinue(false));
    await updateChatHistory(
      chatId,
      {
        message: "Generate New Calcuations for Hiring Effort",
        sender: "HIRINGNEW",
        sentAt: formattedDate,
      },
      category
    );
  };

  const handleEdit = async () => {
    setOutput("");
    setUserOutput("");
    dispatch(updateTypeWriterEffect(false));
    dispatch(updateShowCountinue(false));
    await updateChatHistory(
      chatId,
      {
        message: "Edit Previous Values for Calculating Hiring Effort",
        sender: "HIRINGNEW",
        sentAt: formattedDate,
      },
      category
    );
  };

  return (
    <>
      <Stack
        p={1}
        flexGrow={2}
        sx={{
          overflowY: "scroll",
        }}
        ref={stackRef}
      >
        <Typography variant="h4">Hiring Effort Calculator</Typography>
        <Divider />

        {chatHistories?.length === 0 && (
          <>
            <BotMessage>
              <Typography variant="button">
                This hiring calculator is for those who use job boards & have
                limited set of active profiles of job seekers for a given
                duration.
                <br />
                <br />
                This calculator will help you understand what duration will it
                take to make X number of hires, based on the hiring pattern at
                your company.
                <br />
                <br />
                Fill in the required details based on the hiring pattern of your
                company and ensure these numbers are role specific.
              </Typography>
              <FormControl sx={{marginTop:"20px"}}>
                <HiringEffortCalculatorForm
                  position={position}
                  joining={joining}
                  interview={interview}
                  submission={submission}
                  profiles={profiles}
                  initialData={initialData}
                  newProfile={newProfile}
                  setPosition={setPosition}
                  setJoining={setJoining}
                  setInterview={setInterview}
                  setSubmission={setSubmission}
                  setProfiles={setProfiles}
                  setInitialData={setInitialData}
                  setNewProfile={setNewProfile}
                  setOutput={setOutput}
                  setUserOutput={setUserOutput}
                />
              </FormControl>
            </BotMessage>
            <Box sx={{ borderBottom: "1px solid lightgrey" }} />
          </>
        )}

        {chatHistories?.length !== 0 && (
          <>
            {chatHistories.map((value, mainIndex) => (
              <>
                {value.sender === "HIRINGUSER" && (
                  <UserMessage>
                    {JSON.parse(value.message).map((value) => (
                      <>
                        <>
                          <Typography sx={{ fontWeight: "bold" }}>
                            No of positions
                          </Typography>
                          {value.position + "\n"}
                        </>

                        <>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Joining %
                          </Typography>
                          {value.joining + "\n"}
                        </>

                        <>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Interview (Offer %)
                          </Typography>
                          {value.interview + "\n"}
                        </>

                        <>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Submissions (Interview %)
                          </Typography>
                          {value.submission + "\n"}
                        </>

                        <>
                          <Typography sx={{ fontWeight: "bold" }}>
                            No of Profile views per submission
                          </Typography>
                          {value.profiles + "\n"}
                        </>

                        <>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Initial data available
                          </Typography>
                          {value.initialData + "\n"}
                        </>

                        <>
                          <Typography sx={{ fontWeight: "bold" }}>
                            New Profiles every week
                          </Typography>
                          {value.newProfile + "\n"}
                        </>
                      </>
                    ))}
                  </UserMessage>
                )}

                {value.sender === "HIRINGBOT" && (
                  <BotMessage>
                    <Typography>
                      Hoollaa {userDetails && `${userDetails.firstName}`}, here
                      you go!!!
                    </Typography>
                    <Typography>
                      We have done the Hiring Effort Calculation for you.
                    </Typography>
                    <br />

                    {mainIndex + 1 === chatHistories?.length &&
                    typeWriterEffect ? (
                      <TypeWriter text={value.message} />
                    ) : (
                      <span
                        dangerouslySetInnerHTML={{ __html: value.message }}
                      ></span>
                    )}
                  </BotMessage>
                )}

                {value.sender === "HIRINGNEW" && (
                  <>
                    <UserMessage>{value.message}</UserMessage>
                  </>
                )}

                {value.sender === "HIRINGBOT" && showCountinue && (
                  <>
                    <Box sx={{ borderTop: "1px solid lightgrey" }} />
                    <BotMessage>
                      <FormControl>
                        <Typography mb={2} variant="button">
                          Continue To generate a New Boolean OR Edit the
                          Existing Form
                        </Typography>
                        <Box gap={1} display="flex">
                          <Button
                            variant="outlined"
                            sx={{ width: "100px" }}
                            onClick={handleContinue}
                          >
                            Continue
                          </Button>
                          <Button
                            variant="outlined"
                            sx={{ width: "100px" }}
                            onClick={handleEdit}
                          >
                            Edit
                          </Button>
                        </Box>
                      </FormControl>
                    </BotMessage>
                  </>
                )}

                {mainIndex + 1 === chatHistories?.length &&
                  value.sender === "HIRINGNEW" && (
                    <>
                      <Box sx={{ borderTop: "1px solid lightgrey" }} />
                      <BotMessage>
                        <Typography variant="button">
                          Fill out The Given Below Form.
                        </Typography>
                        <FormControl>
                          <HiringEffortCalculatorForm
                            position={position}
                            joining={joining}
                            interview={interview}
                            submission={submission}
                            profiles={profiles}
                            initialData={initialData}
                            newProfile={newProfile}
                            setPosition={setPosition}
                            setJoining={setJoining}
                            setInterview={setInterview}
                            setSubmission={setSubmission}
                            setProfiles={setProfiles}
                            setInitialData={setInitialData}
                            setNewProfile={setNewProfile}
                            setOutput={setOutput}
                            setUserOutput={setUserOutput}
                          />
                        </FormControl>
                      </BotMessage>
                    </>
                  )}

                <Box sx={{ borderBottom: "1px solid lightgrey" }} />
              </>
            ))}
          </>
        )}
      </Stack>
      <Stack mt={2} direction="row" spacing={1}>
        <TextField
          disabled
          fullWidth
          size="small"
          placeholder="Write your response here..."
          variant="outlined"
        />
        <Button disabled variant="contained" color="primary" size="small">
          <SendIcon />
        </Button>
      </Stack>{" "}
    </>
  );
};

export default HiringEffortCalculatorChat;
