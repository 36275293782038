import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({children}) => {
  const isLoggedIn = useSelector((state) => state.auth.isAuthorized);
  if (!isLoggedIn) {
    return <Navigate to="/" replace={true} />;
    }
    return children;
};

export default ProtectedRoute;
