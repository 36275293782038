import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import LinkedinChat from "./LinkedinChat";
import BooleanChat from "./BooleanChat";
import useChatData from "../api/useChatData";
import XRayChat from "./XRayChat";
import AssignmentChat from "./AssessmentChat";
import {
  updateShowCountinue,
  updateTypeWriterEffect,
} from "../features/chats/chatsSlice";
import LearnSourcingChat from "./LearnSourcingChat";
import HiringEffortCalculatorChat from "./HiringEffortCalculatorChat";

const ChatSection = () => {
  const authData = useSelector((state) => state.auth);
  const chatData = useSelector((state) => state.chats);
  let { chatId, category } = useParams();
  const { getChatHistory } = useChatData();
  const [role, setRole] = useState("");
  const [value, setValue] = useState("");
  const [takeInput, setTakeInput] = useState(false);
  const dispatch = useDispatch();
  const stackRef = useRef(null);
  const userDetails = useSelector((state) => state.auth.user);

  useEffect(() => {
    console.log("executes on chatId / route change", chatId, category);
    dispatch(updateTypeWriterEffect(false));
    getChatHistory(chatId, category);
    dispatch(updateShowCountinue(false));
  }, [chatId]);

  if (authData?.loading || chatData?.loading)
    return (
      <Box
        flexGrow={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        // m={2}
      >
        <CircularProgress size={40} />
      </Box>
    );
  else {
    return category === "LINKEDIN" ? (
      <LinkedinChat stackRef={stackRef} userDetails={userDetails} />
    ) : category === "BOOLEAN" ? (
      <BooleanChat
        stackRef={stackRef}
        takeInput={takeInput}
        role={role}
        value={value}
        setTakeInput={setTakeInput}
        setRole={setRole}
        setValue={setValue}
        userDetails={userDetails}
      />
    ) : category === "XRAY" ? (
      <XRayChat stackRef={stackRef} userDetails={userDetails} />
    ) : category === "LEARN_SOURCING" ? (
      <LearnSourcingChat stackRef={stackRef} userDetails={userDetails} />
    ) : category === "HIRING_EFFORT" ? (
      <HiringEffortCalculatorChat stackRef={stackRef} userDetails={userDetails}/>
    ) : (
      <Navigate to="/chat" replace="true" />
    );
  }
};

export default ChatSection;
