import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  isAuthorized: localStorage.getItem("token") ? true : false,
  error: false,
  user: false
};

export const authSlice = createSlice({
  name: "auth",
  initialState,

  reducers: {
    loginRequest: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
        state.user = action.payload;
      //   state.userAccess = action.payload.subscription;
      state.error = false;
      state.isAuthorized = true;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      //   state.error = action.payload;
      state.error = "some error occured";
      state.isAuthorized = false;
    },
    logoutSuccess: (state) => {
      state.loading = false;
      state.error = "";
      state.isAuthorized = false;
      localStorage.removeItem("token");
    },
  },
});

export const { loginRequest, loginSuccess, loginFailure, logoutSuccess, getUserFailure, getUserRequest, getUserSuccess  } =
  authSlice.actions;

export default authSlice.reducer;
