import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import useChatData from "../api/useChatData";
import { useSelector } from "react-redux";

import "../css/companyCluster.css"

const NewCompanyClusterForm = ({
  newCluster,
  setNewCluster,
  newClusterTitle,
  setEditCluster,
  setSnackbarError,
  setSnackbarMessage,
  setSnackbarType,
}) => {
  // const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [tags, setTags] = useState([]);
  const { createCompanyClusters, getComanyClusters } = useChatData();
  const [urlError, setUrlError] = useState(false);
  const [tagsError, setTagsError] = useState(false);
  const { loadingCompanyCluster, successCompanyCluster, errorCompanyCluster  } =
    useSelector((state) => state.chats);

  useEffect(() => {
    if (!loadingCompanyCluster && successCompanyCluster) {
      setNewCluster(false);
      setEditCluster(true);
    }
  }, [loadingCompanyCluster, successCompanyCluster, errorCompanyCluster]);

  const handleTagChange = (_, newTags) => {
    setTagsError(false);
    setTags(newTags);
  };

  const handleDeleteTag = (tagToDelete) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };

  const handleUrl = (event) => {
    setUrlError(false);
    setUrl(event.target.value);
  };

  const isValidLinkedInFormat = (input) => {
    const regex =
      /^https?:\/\/www\.linkedin\.com\/search\/results\/people\/\?(currentCompany|pastCompany)=/i;
    return regex.test(input);
  };

  const createNewCluster = async () => {
    if (url === "") {
      setUrlError(true);
      return;
    }
    if (tags.length === 0) {
      setTagsError(true);
      return;
    }

    if (!isValidLinkedInFormat(url)) {
      setSnackbarError(true);
      setSnackbarMessage("Added Url is wrong kindly watch the video");
      setSnackbarType("error");
      return;
    }

    const data = {
      type: "LINKEDIN",
      title: newClusterTitle,
      linkedinCompanies: [
        {
          url:  url,
          companies:  tags,
        },
      ],
    };
    await createCompanyClusters(data);
  };

  return (
    <Stack
      padding="0px 5px 5px 5px"
      gap={2}
      flexGrow={2}
      sx={{
        alignItems: "center",
        justifyContent: "center",
        width: "560px",
        overflow: "hidden",
        overflowY: "scroll",
      }}
    >
      <Box
        sx={{
          width: "95%",
          border: "1px solid grey",
          padding: "5px",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: "20px",
        }}
      >
        <Box
          display="flex"
          alignItems="start"
          flexDirection="column"
          gap={2}
          sx={{ padding: "5px" }}
        >
          <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: "3%", width: "100%"}}>
            <span style={{ color: "black" }}>Using it for 1st time?
            </span>
            <span className="text-shake">
              <a style={{ color: "blue", textDecoration: "none" }} target="_blank" href="https://vimeo.com/835716542/d27cb1d9af">Watch Video</a>
            </span>
          </div>
          {/* <TextField
            value={title}
            label="Title"
            onChange={(event) => setTitle(event.target.value)}
            sx={{ width: "490px" }}
          /> */}

          <TextField
            label="URL (Copy & paste target firm URL from LinkedIn)"
            value={url}
            onChange={handleUrl}
            sx={{ width: "490px" }}
          />
          {urlError && (
            <Typography sx={{ fontSize: "12px", color: "red" }}>
              *Select Companies, Company Field can't be Empty
            </Typography>
          )}
        </Box>

        {/* <Typography variant="button" color="black">
          Companies Related To URL {showCompanies.linkedinCompanies.length + 1}:
        </Typography> */}
        <Box
          sx={{
            width: "500px",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: 1,
            padding: "5px",
            alignItems: "center",
          }}
        >
          <Autocomplete
            multiple
            freeSolo
            value={tags}
            onChange={handleTagChange}
            options={[]}
            renderTags={(value, getTagProps) =>
              value?.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  onDelete={() => handleDeleteTag(tag)}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Company Names"
                placeholder="Add Company Names"
              />
            )}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "start",
            }}
          >
            {!tagsError ? (
              <Typography sx={{ fontSize: "12px" }}>
                *Press Enter to Select the Companies
              </Typography>
            ) : (
              <Typography sx={{ fontSize: "12px", color: "red" }}>
                *Select Companies, Company Field can't be Empty
              </Typography>
            )}
          </Box>

          <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
            <Button
              variant="outlined"
              color="success"
              onClick={createNewCluster}
            >
              Create New Cluster
            </Button>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

export default NewCompanyClusterForm;
