import {useState} from "react";
import {
    Alert,
    AlertTitle,
    Snackbar,
} from "@mui/material";


function SnackBarCard({ message, severity }) {
    const [open, setOpen] = useState(true)
    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            autoHideDuration={3000}
            onClose={() => setOpen(false)}
        >
            <Alert severity={severity} onClose={() => setOpen(false)}>
                <AlertTitle>Success</AlertTitle>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default SnackBarCard;
