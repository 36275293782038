import React from "react";
import Main from "./pages/Main";
import Box from "@mui/material/Box";
import { Route, Routes } from "react-router-dom";
import ChatSection from "./components/ChatSection";
import ErrorPage from "./pages/ErrorPage";
import NewChat from "./components/NewChat";
import LoginSuccess from "./pages/LoginSuccess";
import InitialLoginPage from "./pages/InitialLoginPage";
import ProtectedRoute from "./components/ProtectedRoute";
import LoginFailure from "./pages/LoginFailure";
import About from "./pages/About";
import Services from "./pages/Services";
import PreviewCertificate from "./pages/PreviewCertificate";
import ShareCertificate from "./pages/ShareCertificate";

import {
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
} from "@mui/material";

function App() {
  const isMobile = useMediaQuery("(max-width: 768px)");

  // Transition for dialog box
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="100vh"
        maxHeight="100vh"
        sx={{ flexGrow: 1 }}
      >
        {isMobile ? (
          <>
            <Dialog
              open={true}
              TransitionComponent={Transition}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"TA BUDDY"}</DialogTitle>
              <DialogContent>
                <p>Hey TA,</p>
                <p style={{ textAlign: "justify" }}>
                  Thanks for hitting up on me. Unfortunately I'm available only
                  on web mode right now. Check me out via laptop/desktop.
                </p>
                <p>
                  Meanwhile, you can follow{" "}
                  <a
                    target="_blank"
                    href={"https://www.linkedin.com/in/ashfaqahmedhr/"}
                    style={{ textDecoration: "none", color: "blue" }}
                  >
                    Ashfaq
                  </a>{" "}
                  on LinkedIn, the inventor of tabuddy
                </p>
              </DialogContent>
            </Dialog>
          </>
        ) : (
          <Routes>
            <Route exact path="/" element={<InitialLoginPage />} />
            <Route path="login-success" element={<LoginSuccess />} />
            <Route path="login-failure" element={<LoginFailure />} />
            <Route
              exact
              path="learning/certificate/:imageUrl"
              element={<ShareCertificate />}
            />
            <Route
              path="app"
              element={
                <ProtectedRoute>
                  <Main />
                </ProtectedRoute>
              }
            >
              <Route exact path="chat" element={<NewChat />} />
              <Route
                exact
                path="learning/certificate"
                element={<PreviewCertificate />}
              />
              <Route
                exact
                path="chats/:category/:chatId"
                element={<ChatSection />}
              />
              <Route exact path="about" element={<About />} />
              <Route exact path="services" element={<Services />} />
            </Route>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        )}
      </Box>
    </>
  );
}

export default App;
