import { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Chip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import { Link, useParams, useNavigate } from "react-router-dom";
import useChatData from "../api/useChatData";
import { blue } from "@mui/material/colors";
import AlertDialog from "./AlertDailog";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAssessmentCompleted,
  updateLearningCompleted,
} from "../features/chats/chatsSlice";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const ChatCard = ({ item }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(item.title);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const { updateChatTitle, deleteChat } = useChatData();
  let { chatId, category } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const { assessmentCompleted } = useSelector((state) => state.chats);
  const { learningCompleted } = useSelector((state) => state.chats);

  const date = new Date(item.createdAt);

  useEffect(() => {
    setTitle(item.title);
  }, [item]);

  const handleClick = () => {
    if (
      (category === "LEARN_SOURCING" && learningCompleted === false) ||
      (category === "ASSESSMENT" && assessmentCompleted === false)
    ) {
      if (chatId === item["_id"]) {
        return;
      }
      if (category === "ASSESSMENT") {
        setMessage(
          "Assessment is not completed yet, If you still try to navigate then this Chat will be Deleted."
        );
      } else {
        setMessage(
          "Learning is not completed yet, If you still try to navigate then this Chat will be Deleted."
        );
      }
      setModalOpen(true);
    } else {
      navigate(`/app/chats/${item.category}/${item["_id"]}`);
    }
  };

  return (
    <>
      {/* Alert for deleting a chat */}
      <AlertDialog
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        chatId={chatId}
        item={item}
        message={message}
      ></AlertDialog>
      <Dialog open={open}>
        <DialogTitle>{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will permanently delete the chat conversation.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={1}>
            <Button
              variant="outlined"
              color="info"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              LinkComponent={Link}
              to="/app/chat"
              variant="contained"
              color="error"
              onClick={() => {
                deleteChat(item["_id"]);
                setOpen(false);
                dispatch(updateAssessmentCompleted(true));
                dispatch(updateLearningCompleted(true));
              }}
              autoFocus
            >
              Delete
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Paper
        key={item["_id"]}
        elevation={2}
        sx={{
          cursor: "pointer",
          margin: "0 20",
          padding: 1,
          textDecoration: "none",
          borderLeft: item["_id"] === chatId ? "5px solid" : "0px",
          borderColor: blue[400],
        }}
        // component={Link}
        onClick={handleClick}
      >
        <Box>
          <Typography color="gray" variant="subtitle2">
            {item.category}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {isEditing ? (
            <TextField
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              variant="standard"
            />
          ) : (
            <Typography variant="body1">{item.title}</Typography>
          )}

          <Stack direction="row" spacing={1}>
            {isEditing ? (
              <IconButton
                onClick={() => {
                  setIsEditing(false);
                  updateChatTitle({ title }, item["_id"]);
                }}
                size="small"
                color="success"
              >
                <DoneIcon />
              </IconButton>
            ) : (
              item["_id"] === chatId && (
                <>
                  {assessmentCompleted && learningCompleted && (
                    <IconButton
                      onClick={() => setIsEditing(true)}
                      size="small"
                      color="info"
                    >
                      <EditIcon />
                    </IconButton>
                  )}

                  <IconButton
                    onClick={() => setOpen(true)}
                    size="small"
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              )
            )}
          </Stack>
        </Box>
        <Stack mt="2px" direction="row" justifyContent="space-between">
          <Chip
            size="small"
            icon={<CalendarMonthIcon />}
            label={date.toLocaleDateString()}
          />
          <Chip
            size="small"
            icon={<AccessTimeIcon />}
            label={date.toLocaleTimeString()}
          />
        </Stack>
      </Paper>
    </>
  );
};

export default ChatCard;
