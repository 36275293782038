import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Autocomplete,
  CircularProgress,
  Select,
  Switch,
  MenuItem,
  InputLabel,
  FormControl,
  Radio,
  RadioGroup,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateTypeWriterEffect } from "../features/chats/chatsSlice";
import CompaniesClusterPopup from "./CompaniesClusterPopup";

const LinkedinForm = ({
  exceedLimit,
  seperateTitles,
  titleTags,
  title,
  eitherKeyword,
  mandatory,
  mandatoryTags,
  eitherInput,
  searchInGroup,
  setsearchInGroup,
  setEitherInput,
  setLinks,
  setTitleTags,
  setOutput,
  setExceedLimit,
  setMandatoryTags,
  setSeperateTitles,
  setMandatory,
  seteitherKeyword,
  setTitle,
}) => {
  const [loader, setLoader] = useState(false);
  const [mainError, setMainError] = useState(false);
  const [errorMandatory, setErrorMandatory] = useState(false);
  const [errorEither, setErrorEither] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [open, setOpen] = useState(false);
  const [skillType, setSkillType] = useState("Mandatory");
  const [plusIconClicked, setPlusIconClicked] = useState(false);
  const [useClusterList, setUseClusterList] = useState(false);
  const [activeCluster, setActiveCluster] = useState({});
  const [companyClusterType, setCompanyClusterType] = useState("")
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const dispatch = useDispatch();
  const { formCompanyCluster } = useSelector((state) => state.chats);

  useEffect(() => {
    if (formCompanyCluster && formCompanyCluster?.linkedinCompanies?.length) {
      const data = formCompanyCluster.linkedinCompanies;
      var companyCodes = [];

      data.forEach(function (string) {
        var searchParams = new URLSearchParams(string);
        var match = searchParams.get("currentCompany");

        if (match) {
          var codes = match[1].split("%22%2C%22");
          companyCodes = companyCodes.concat(codes);
        }
      });

      var combinedString =
        "currentCompany=%5B%22" + companyCodes.join("%22%2C%22") + "%22%5D";

    }
  }, [formCompanyCluster]);

  useEffect(() => {
    if (useClusterList && !companyClusterType) {
      setCompanyClusterType("current")
    }
  }, [useClusterList])

  const mandatorySkillComponent = () => {
    return (
      <Autocomplete
        multiple
        options={[]}
        freeSolo
        sx={{ width: "250px" }}
        inputValue={mandatory}
        onInputChange={(event, newTitle) => {
          mandatoryTags?.length < 6 && setMandatory(newTitle);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Mandatory Keywords"
            variant="standard"
            onKeyDown={handleMandatoryInputKeyDown}
          />
        )}
        onChange={handleMandatoryTagChange}
        value={mandatoryTags}
      />
    )
  }

  const optionalSkillComponent = () => {
    return (
      <Autocomplete
        multiple
        options={[]}
        freeSolo
        sx={{ width: "250px", }}
        inputValue={eitherInput}
        onInputChange={(event, newKeywords) => {
          setEitherInput(newKeywords);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Keywords 1, Keyword 2"
            label="Either OR Keywords"
            variant="standard"
            onKeyDown={handleEitherKeyDown}
          />
        )}
        onChange={handleEitherChange}
        value={eitherKeyword}
      />
    )
  }

  const handleTagChange = (event, values) => {
    setExceedLimit(false);
    setMainError(false);
    setTitleTags(values);
  };

  const handleInputKeyDown = (event) => {
    setExceedLimit(false);
    setMainError(false);
    setErrorTitle(false);
    if (event.key === "Enter") {
      const newTag = title.trim();
      if (newTag && !titleTags.includes(newTag)) {
        setTitleTags([...titleTags, newTag]);
      }
      setTitle("");
      event.preventDefault();
    }
  };

  const handleEitherChange = (event, values) => {
    setExceedLimit(false);
    setMainError(false);
    seteitherKeyword(values);
  };

  const handleEitherKeyDown = (event) => {
    setExceedLimit(false);
    setMainError(false);
    setErrorEither(false);
    if (event.keyCode === "Enter") {
      const newTag = eitherInput.trim();
      if (newTag && !eitherKeyword.includes(newTag)) {
        seteitherKeyword([...eitherKeyword, newTag]);
      }
      setEitherInput("");
      event.preventDefault();
    }
  };

  const handleMandatoryTagChange = (event, values) => {
    setExceedLimit(false);
    setMainError(false);
    setMandatoryTags(values);
  };

  const handleMandatoryInputKeyDown = (event) => {
    setExceedLimit(false);
    setErrorMandatory(false);
    setMainError(false);
    if (event.key === "Enter") {
      const newTag = mandatory.trim();
      if (newTag && !mandatoryTags.includes(newTag)) {
        setMandatoryTags([...mandatoryTags, newTag]);
      }
      setMandatory("");
      event.preventDefault();
    }
  };

  const handleSeperateTitles = (event) => {
    event.preventDefault();
    if (event.target.checked) {
      setSeperateTitles(true);
    } else {
      setSeperateTitles(false);
    }
  };

  const handlesearchInGroup = (event) => {
    event.preventDefault();
    if (event.target.checked) {
      setsearchInGroup(true);
    } else {
      setsearchInGroup(false);
    }
  };

  const handleClick = () => {
    if (!loader) {
      const filteredEither = eitherKeyword.filter(Boolean);

      if (filteredEither?.length !== 0) {
        const newEitherKeywords = filteredEither.map((elem) => {
          return elem
            .split(",")
            .map((innerElement) => {
              return innerElement.trim();
            })
            .join(" OR ");
        });

        const checkEitherLength = newEitherKeywords.flatMap((elem) =>
          elem.split(" OR ")
        );

        if (
          checkEitherLength?.length + mandatoryTags?.length > 6
        ) {
          setErrorMessage("Mandatory and optional must not contain more than 6 keywords")
          setIsError(true)
          setExceedLimit(true);
          return;
        }

        if (titleTags?.length > 6) {
          setErrorMessage("Title must not contain more than 6 keywords")
          setIsError(true)
          setExceedLimit(true);
          return;
        }
      }

      if (
        titleTags?.length === 0 &&
        mandatoryTags?.length === 0 &&
        eitherKeyword?.length === 0
      ) {
        setErrorMessage("Type atleast one keyword")
        setIsError(true)
        setMainError(true);
        return;
      }

      if (mandatory !== "") {
        setErrorMessage("Ensure ENTER button at every keyword")
        setIsError(true)
        setErrorMandatory(true);
        return;
      }

      if (eitherInput !== "") {
        setErrorMessage("Ensure ENTER button at every keyword")
        setIsError(true)
        setErrorEither(true);
        return;
      }

      if (title !== "") {
        setErrorMessage("Ensure ENTER button at every keyword")
        setIsError(true)
        setErrorTitle(true);
        return;
      }

      handleSubmit();
    } else {
      console.log("Link is genereting");
    }
    setLoader(true);
  };

  const handleSubmit = () => {
    try {
      let urlKeywords;
      let newEitherKeywords;
      let newKeywords;
      let newClusterKeywords;
      let userOutputEither = false;
      let userOutputMandatory = false;
      let userOutputTitle = false;
      let userSeperateTitles = false;
      let userSearchInGroup = false;

      // Adding company clusters
      let clusterCompanies = null
      if (activeCluster && Object.keys(activeCluster)?.length) {
        let companyCodes = activeCluster?.linkedinCompanies?.map(item => {
          let params = new URLSearchParams(new URL(item.url).search);
          let currentCompany = params.get('currentCompany');
          let pastCompany = params.get('pastCompany');

          currentCompany = currentCompany ? currentCompany = JSON.parse(currentCompany) : []
          pastCompany = pastCompany ? pastCompany = JSON.parse(pastCompany) : []

          return [...currentCompany, ...pastCompany]
        })

        companyCodes = companyCodes.flat()
        if (companyClusterType === "current") {
          clusterCompanies = "currentCompany=%5B%22" + companyCodes.join("%22%2C%22") + "%22%5D&";
        } else if (companyClusterType === "past") {
          clusterCompanies = "pastCompany=%5B%22" + companyCodes.join("%22%2C%22") + "%22%5D&";
        } 
        // else if (companyClusterType === "both") {
        //   clusterCompanies =
        //     "currentCompany=%5B%22" + companyCodes.join("%22%2C%22") + "%22%5D&" +
        //     "pastCompany=%5B%22" + companyCodes.join("%22%2C%22") + "%22%5D&"
        // }
      }

      if (eitherKeyword?.length !== 0) {
        const filteredEither = eitherKeyword.filter(Boolean);
        newEitherKeywords = filteredEither.map((elem) => {
          return elem
            .split(",")
            .map((innerElement) => {
              return innerElement.trim();
            })
            .join(" OR ");
        });

        newKeywords = mandatoryTags.join(" AND ");
        newClusterKeywords = newEitherKeywords
          .map((elem) => `${elem}`)
          .join(" OR ");

        // If Mandatory Keywords Are Nill
        if (mandatoryTags?.length === 0) {
          urlKeywords = newClusterKeywords;
        } else {
          urlKeywords = newKeywords + " AND " + `(${newClusterKeywords})`;
        }
      } else {
        newKeywords = mandatoryTags.join(" AND ");
        urlKeywords = newKeywords;
      }

      const encodedUrl = encodeURIComponent(urlKeywords);
      if (searchInGroup) {
        if (titleTags?.length === 0) {
          const link = `https://www.linkedin.com/search/results/people/?${clusterCompanies || ""}keywords=${encodedUrl}&network=%5B"A"%5D&origin=GLOBAL_SEARCH_HEADER&sid=tDq&`;
          setLinks((prev) => [
            ...prev,
            { link: link, name: "Linkedin Sourcing Link", searchInGroup: true },
          ]);
        } else {
          if (mandatoryTags?.length !== 0 || eitherKeyword?.length !== 0) {
            if (seperateTitles) {
              titleTags.map((value) => {
                const link = `https://www.linkedin.com/search/results/people/?${clusterCompanies || ""}keywords=${encodedUrl}&network=%5B"A"%5D&origin=GLOBAL_SEARCH_HEADER&sid=tDq&titleFreeText=${value}`;
                setLinks((prev) => [
                  ...prev,
                  { link: link, name: value, searchInGroup: true },
                ]);
              });
            } else {
              const Title = titleTags.join(" OR ");
              const encodedTitle = encodeURIComponent(Title);
              const link = `https://www.linkedin.com/search/results/people/?${clusterCompanies || ""}keywords=${encodedUrl}&network=%5B"A"%5D&origin=GLOBAL_SEARCH_HEADER&sid=tDq&titleFreeText=${encodedTitle}`;
              setLinks((prev) => [
                ...prev,
                {
                  link: link,
                  name: "Linkedin Sourcing Link",
                  searchInGroup: true,
                },
              ]);
            }
          } else {
            if (seperateTitles) {
              titleTags.map((value) => {
                const link = `https://www.linkedin.com/search/results/people/?${clusterCompanies || ""}network=%5B"A"%5D&origin=FACETED_SEARCH&sid=VZ%40&titleFreeText=${value}`;
                setLinks((prev) => [
                  ...prev,
                  { link: link, name: value, searchInGroup: true },
                ]);
              });
            } else {
              const Title = titleTags.join(" OR ");
              const encodedTitle = encodeURIComponent(Title);
              const link = `https://www.linkedin.com/search/results/people/?${clusterCompanies || ""}network=%5B"A"%5D&origin=FACETED_SEARCH&sid=VZ%40&titleFreeText=${encodedTitle}`;
              setLinks((prev) => [
                ...prev,
                {
                  link: link,
                  name: "Linkedin Sourcing Link",
                  searchInGroup: true,
                },
              ]);
            }
          }
        }
      } else {
        if (titleTags?.length === 0) {
          const link = `https://www.linkedin.com/search/results/people/?${clusterCompanies || ""}keywords=${encodedUrl}&&origin=SWITCH_SEARCH_VERTICAL&sid=K*y`;
          setLinks((prev) => [
            ...prev,
            {
              link: link,
              name: "Linkedin Sourcing Link",
              searchInGroup: false,
            },
          ]);
        } else {
          if (seperateTitles) {
            titleTags.map((value) => {
              const link = `https://www.linkedin.com/search/results/people/?${clusterCompanies || ""}keywords=${encodedUrl}&origin=FACETED_SEARCH&sid=N%3Bf&titleFreeText=${value}`;
              setLinks((prev) => [
                ...prev,
                { link: link, name: value, searchInGroup: false },
              ]);
            });
          } else {
            const Title = titleTags.join(" OR ");
            const encodedTitle = encodeURIComponent(Title);
            const link = `https://www.linkedin.com/search/results/people/?${clusterCompanies || ""}keywords=${encodedUrl}&origin=FACETED_SEARCH&sid=N%3Bf&titleFreeText=${encodedTitle}`;
            setLinks((prev) => [
              ...prev,
              {
                link: link,
                name: "Linkedin Sourcing Link",
                searchInGroup: false,
              },
            ]);
          }
        }
      }

      // User Message Bubble Generator

      if (mandatoryTags?.length !== 0) {
        userOutputMandatory = mandatoryTags.join(", ");
      }
      if (searchInGroup) {
        userSearchInGroup = true;
      }
      if (newEitherKeywords) {
        userOutputEither = newEitherKeywords.join(", ");
      }
      if (titleTags?.length !== 0) {
        if (seperateTitles) {
          userOutputTitle = titleTags.join(", ");
        } else {
          userOutputTitle = titleTags.join(" OR ");
        }
        if (seperateTitles) {
          userSeperateTitles = true;
          userOutputTitle = titleTags.join(", ");
        } else {
          userOutputTitle = titleTags.join(" OR ");
        }
      }
      setOutput([
        {
          title: userOutputTitle,
          mandatory: userOutputMandatory,
          either: userOutputEither,
          seperateTitles: userSeperateTitles,
          searchInGroup: userSearchInGroup,
        },
      ]);
      dispatch(updateTypeWriterEffect(true));
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }

    // reset state
    setActiveCluster({})
    setUseClusterList(false)
    setCompanyClusterType("")
    setSkillType("Mandatory")
    setOpen(false)
  };

  return (
    <>
      <Snackbar open={isError}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={4000} onClose={() => { setIsError(false); setErrorMessage("") }} >
        <Alert severity="error" sx={{ width: '100%' }} onClose={() => { setIsError(false); setErrorMessage("") }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <CompaniesClusterPopup open={open} setOpen={setOpen} setUseClusterList={setUseClusterList} activeCluster={activeCluster} setActiveCluster={setActiveCluster}></CompaniesClusterPopup>
      {/* <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box
          display="flex"
          paddingTop="10px"
          gap={1}
          justifyContent="center"
          alignItems="start"
          flexDirection="row"
        >
          <Box
            display="flex"
            gap={1}
            flexDirection="column"
            marginBottom="20px"
          >
            <Autocomplete
              multiple
              options={[]}
              freeSolo
              sx={{ width: "250px" }}
              inputValue={title}
              onInputChange={(event, newTitle) => {
                titleTags.length < 6 && setTitle(newTitle);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Title"
                  variant="outlined"
                  onKeyDown={handleInputKeyDown}
                />
              )}
              onChange={handleTagChange}
              value={titleTags}
            />

            {errorTitle ? (
              <Typography
                variant="caption"
                display="block"
                sx={{ color: "Red" }}
              >
                Please separate your Titles by "Enter".
              </Typography>
            ) : titleTags.length < 6 ? (
              <Typography
                variant="caption"
                display="block"
                sx={{ color: "grey" }}
              >
                *Seperate the Titles by "Enter"
              </Typography>
            ) : (
              <Typography
                variant="caption"
                display="block"
                sx={{ color: "Red" }}
              >
                *You can only select up to 6 titles.
              </Typography>
            )}

            <FormControlLabel
              control={
                <Checkbox
                  value={seperateTitles}
                  disabled={titleTags.length <= 1}
                  defaultChecked={seperateTitles}
                  onChange={handleSeperateTitles}
                />
              }
              label="Separate each title into a search"
            />
          </Box>

          <Box
            display="flex"
            gap={1}
            flexDirection="column"
            marginBottom="70px"
          >
            <Autocomplete
              multiple
              options={[]}
              freeSolo
              sx={{ width: "250px" }}
              inputValue={mandatory}
              onInputChange={(event, newTitle) => {
                mandatoryTags.length < 6 && setMandatory(newTitle);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Mandatory Keywords"
                  variant="outlined"
                  onKeyDown={handleMandatoryInputKeyDown}
                />
              )}
              onChange={handleMandatoryTagChange}
              value={mandatoryTags}
            />
            {errorMandatory ? (
              <Typography
                variant="caption"
                display="block"
                sx={{ color: "Red" }}
              >
                Please separate your keyword by "Enter".
              </Typography>
            ) : mandatoryTags.length < 6 ? (
              <Typography
                variant="caption"
                display="block"
                sx={{ color: "grey" }}
              >
                *Seperate the Keywords by "Enter"
              </Typography>
            ) : (
              <Typography
                variant="caption"
                display="block"
                sx={{ color: "Red" }}
              >
                *You can only select 6 Keywords.
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          width={530}
          display="flex"
          justifyContent="center"
          alignItems="start"
          flexDirection="row"
          gap={1}
        >
          <Box>
            <Autocomplete
              multiple
              options={[]}
              freeSolo
              sx={{ width: "250px" }}
              inputValue={eitherInput}
              onInputChange={(event, newKeywords) => {
                setEitherInput(newKeywords);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Keywords 1, Keyword 2"
                  label="Either OR Keywords"
                  variant="outlined"
                  onKeyDown={handleEitherKeyDown}
                />
              )}
              onChange={handleEitherChange}
              value={eitherKeyword}
            />
            {errorEither ? (
              <Typography
                variant="caption"
                display="block"
                sx={{ color: "Red" }}
              >
                Please separate your keyword by "Enter".
              </Typography>
            ) : titleTags.length < 6 ? (
              <Typography
                variant="caption"
                display="block"
                sx={{ color: "grey" }}
              >
                *Write more than 1 keyword & hit "Enter"
              </Typography>
            ) : (
              <Typography
                variant="caption"
                display="block"
                sx={{ color: "Red" }}
              >
                *You can only select up to 6 Keywords.
              </Typography>
            )}

            <FormControlLabel
              control={
                <Checkbox
                  value={searchInGroup}
                  defaultChecked={searchInGroup}
                  onChange={handlesearchInGroup}
                />
              }
              label="Search in groups"
            />
          </Box>

          <Box width={530} marginTop="10px">
            {exceedLimit && (
              <Typography
                fontWeight={600}
                variant="caption"
                display="block"
                sx={{ color: "Red" }}
              >
                *You can only select upto 6 Keywords
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            <Autocomplete
              multiple
              options={[]}
              freeSolo
              sx={{ width: "250px" }}
              // inputValue={eitherInput}
              // onInputChange={(event, newKeywords) => {
              //   setEitherInput(newKeywords);
              // }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Company"
                  variant="outlined"
                  // onKeyDown={handleEitherKeyDown}
                />
              )}
              // onChange={handleEitherChange}
              // value={eitherKeyword}
            />

            {errorEither ? (
              <Typography
                variant="caption"
                display="block"
                sx={{ color: "Red" }}
              >
                Please separate your keyword by "Enter".
              </Typography>
            ) : eitherKeyword.length < 6 ? (
              <Typography
                variant="caption"
                display="block"
                sx={{ color: "grey" }}
              >
                *Please separate your keyword by "Enter".
              </Typography>
            ) : (
              <Typography
                variant="caption"
                display="block"
                sx={{ color: "Red" }}
              >
                *You can only select up to 6 Keywords.
              </Typography>
            )}

            <Button
              sx={{ width: "131px", marginTop: "10px" }}
              onClick={() => setOpen(true)}
            >
              Add Companies
            </Button>
          </Box>
        </Box>

        <Box marginRight="100px" marginTop="10px">
          {mainError && (
            <Typography
              fontWeight={600}
              variant="caption"
              mb={1}
              display="block"
              sx={{ color: "red" }}
            >
              *Select atleast one of the Above Fields (Mandatory, Either OR
              Keywords, Title)
            </Typography>
          )}
        </Box>
        <Box marginLeft="85%" mt={2}>
          <Button
            variant="outlined"
            sx={{ width: "80px", height: "40px" }}
            onClick={handleClick}
          >
            {loader ? <CircularProgress size={20} /> : "Submit"}
          </Button>
        </Box>
      </Box> */}

      <Box display="flex" flexWrap={"wrap"} flexGrow={2} sx={{
        marginTop: "10px",
        '& > *:not(:last-child)': {
          marginRight: "10px",
        },
      }}>
        <p>
          Search for candidates for the designations like
        </p>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <Autocomplete
            multiple
            options={[]}
            freeSolo
            sx={{ width: "250px", marginTop: "-10px" }}
            inputValue={title}
            onInputChange={(event, newTitle) => {
              titleTags?.length < 6 && setTitle(newTitle);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Title"}
                variant="standard"
                onKeyDown={handleInputKeyDown}
              />
            )}
            onChange={handleTagChange}
            value={titleTags}
          />

          <div style={{ display: "flex", flexDirection: "row", marginTop: "-5px", }}>
            <FormControlLabel
              control={
                <Checkbox
                  size={"small"}
                  value={seperateTitles}
                  disabled={titleTags?.length <= 1}
                  defaultChecked={seperateTitles}
                  onChange={handleSeperateTitles}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                />
              }
            // label="Separate each title into a search"
            />
            <p style={{ marginLeft: "-20px", fontSize: "14px", color: titleTags?.length >= 2 ? "black" : "gray" }}>Separate each title into a search</p>
          </div>

        </div>
        <p>
          (use ENTER to separate).
        </p>
        <p>
          These candidates should have
        </p>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} disabled={plusIconClicked}>
          <Select
            labelId={`demo-simple-select-${plusIconClicked ? "disabled" : "standard"}-label`}
            id={`demo-simple-select-${plusIconClicked ? "disabled" : "standard"}`}
            value={skillType}
            style={{ height: "auto" }}
            onChange={(event) => {
              event.preventDefault();
              setSkillType(event.target.value)
            }}
          >
            {["Mandatory", "Optional"].map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <p>
          skills like
        </p>
        {
          skillType === "Mandatory" ? (
            mandatorySkillComponent()
          ) : (optionalSkillComponent())
        }
        <p>
          (use ENTER to separate)
        </p>

        {!plusIconClicked ? (
          <Button style={{ border: "none", fontSize: "24px" }} onClick={() => {
            setPlusIconClicked(true)
          }}>
            +
          </Button>
        ) : (
          <>
            <p>
              And These candidates should have
            </p>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120, marginTop: "10px" }} disabled>
              <Select
                labelId={`demo-simple-select-disabled-label`}
                id={`demo-simple-select-disabled`}
                style={{ height: "auto" }}
                defaultValue={skillType === "Mandatory" ? "Optional" : "Mandatory"}
                value={skillType === "Mandatory" ? "Optional" : "Mandatory"}
              >
                {["Mandatory", "Optional"].map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <p>
              skills like
            </p>
            <div style={{ marginTop: "10px" }}>
              {
                skillType === "Mandatory" ? (
                  optionalSkillComponent()
                ) : (mandatorySkillComponent())
              }
            </div>
            <p>
              (use ENTER to separate)
            </p>
          </>
        )}
      </Box>
      <Box display="flex" flexWrap={"wrap"} flexGrow={2} sx={{
        '& > *:not(:last-child)': {
          marginRight: "10px",
        },
      }}>
        <Grid container md={12} style={{ marginTop: "10px" }}>
          <Grid item md={4} style={{ paddingTop: "10px" }}>
            Also, target candidates from this
          </Grid>
          <Grid item md={8}>
            <Button style={{ padding: "5px 30px 5px 30px", border: "1px solid #2196f3" }} onClick={() => { setOpen(true) }}>
              {useClusterList ? activeCluster?.title : "Company List"}
            </Button>
            <RadioGroup
              row
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 10,
                  marginLeft: "10px"
                },
              }}
              name="category"
              value={companyClusterType}
              onChange={(event) => { setCompanyClusterType(event.target.value) }}
            >
              {["current", "past"].map((item) => (
                <FormControlLabel
                  value={item}
                  control={<Radio />}
                  label={item}
                />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>


        <Box display="flex" flexWrap={"wrap"} flexGrow={2} sx={{
          marginTop: "20px",
          '& > *:not(:last-child)': {
            marginRight: "10px",
          },
        }}>
          <FormControlLabel control={
            <Switch defaultChecked={searchInGroup} value={searchInGroup} onChange={handlesearchInGroup} />
          } label="Get me candidates from my groups, so that I can send a free inmail." />
        </Box>
        <Box marginLeft="85%" mt={2}>
          <Button
            variant="outlined"
            sx={{ width: "80px", height: "40px" }}
            onClick={handleClick}
          >
            {loader ? <CircularProgress size={20} /> : "Submit"}
          </Button>
        </Box>

      </Box>
    </>
  );
};

export default LinkedinForm;
