import ChatsLocked from "../components/ChatsLocked";
import SideBarLocked from "../components/SideBarLocked";
import { AppBar, Box, Divider, Stack, Toolbar } from "@mui/material";
import TopBarLocked from "../components/TopBarLocked";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const InitialLoginPage = () => {
  const isLoggedIn = useSelector((state) => state.auth.isAuthorized);


  if (isLoggedIn) {
    return <Navigate to="/app/chat" replace={true} />;
  }
  return (
    <>
      <AppBar position="static">
        <Toolbar sx={{ backgroundColor: "white" }}>
          <TopBarLocked />
        </Toolbar>
      </AppBar>

      <Stack overflow="hidden" flexGrow={1} direction="row">
        <Box
          display="flex"
          flexDirection="column"
          m={2}
          minWidth={280}
          maxWidth={280}
        >
          <SideBarLocked />
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box flexGrow={1} display="flex" flexDirection="column" m={2}>
          <ChatsLocked />
        </Box>
      </Stack>
    </>
  );
};

export default InitialLoginPage;
