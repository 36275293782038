import { useState, useEffect } from 'react';

function TypeWriterMultiple({ text, linkIndex, setLinkIndex }) {
    const [displayedText, setDisplayedText] = useState('');

    useEffect(() => {
        if (typeof text !== 'string') {
            return;
        }

        let currentIndex = -1;
        const interval = setInterval(() => {
            if (currentIndex >= text?.length - 1) {
                clearInterval(interval);
                setLinkIndex(linkIndex + 1)
                return;
            }

            currentIndex++;
            setDisplayedText(text.slice(0, currentIndex + 1));
        }, 25);

        return () => {
            clearInterval(interval);
        };
    }, [text]);

    return <span>{displayedText}</span>;
}

export default TypeWriterMultiple;
