import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Box, TextField, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { useSelector } from "react-redux";
import useChatData from "../api/useChatData";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  open,
  setOpen,
  option,
  chatId,
  formattedDate,
  category,
}) {
  const [star, setStar] = React.useState(0);
  const rating = useSelector((state) => state.chats.userRatings);
  const [review, setReview] = React.useState("");
  const { submitRatings, updateChatHistory, getRatings } = useChatData();

  const handleClose = () => {
    setOpen(false);
    setStar(0);
    setReview("");
  };

  const handleSubmit = async () => {

    if (review === "") {
      return;
    }
    if (star === 0) {
      return;
    }

    const data = await submitRatings({
      rating: star,
      review: review,
      ratingType: option,
    });

    if (data.message === "Review submitted successfully") {
      await updateChatHistory(
        chatId,
        {
          message: "Review Submitted Successfully",
          sender: "LEARNINGFULLCOMPLETED",
          sentAt: formattedDate,
        },
        category
      );

      await getRatings();
      setOpen(false);
      setStar(0);
      setReview("");
    }

  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          mb={2}
          sx={{
            backgroundColor: "#2196f3",
            color: "white",
            width: "100%",
            textAlign: "center",
            borderBottom: "1px solid black",
          }}
        >
          {"We'd love your Feedback!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{ textAlign: "center" }}
          >
            <Typography mb={1}>How much would you rate us!!!</Typography>
            <Box
              mb={3}
              sx={{
                blackgroundColor: "red",
                display: "flex",
                width: "400px",
                alignItems: "center",
                justifyContent: "center",
              }}
              gap={3}
            >
              {star <= 0 ? (
                <StarBorderIcon
                  sx={{ fontSize: "40px", cursor: "pointer" }}
                  onClick={() => setStar(1)}
                />
              ) : (
                star > 0 && (
                  <StarIcon
                    onClick={() => setStar(1)}
                    sx={{
                      color: "gold",
                      fontSize: "40px",
                      cursor: "pointer",
                      background: "#ffd90030",
                      borderRadius: "10px",
                    }}
                  />
                )
              )}
              {star <= 1 ? (
                <StarBorderIcon
                  sx={{ fontSize: "40px", cursor: "pointer" }}
                  onClick={() => setStar(2)}
                />
              ) : (
                star > 1 && (
                  <StarIcon
                    onClick={() => setStar(2)}
                    sx={{
                      color: "gold",
                      fontSize: "40px",
                      cursor: "pointer",
                      background: "#ffd90030",
                      borderRadius: "10px",
                    }}
                  />
                )
              )}
              {star <= 2 ? (
                <StarBorderIcon
                  sx={{ fontSize: "40px", cursor: "pointer" }}
                  onClick={() => setStar(3)}
                />
              ) : (
                star > 2 && (
                  <StarIcon
                    onClick={() => setStar(3)}
                    sx={{
                      color: "gold",
                      fontSize: "40px",
                      cursor: "pointer",
                      background: "#ffd90030",
                      borderRadius: "10px",
                    }}
                  />
                )
              )}
              {star <= 3 ? (
                <StarBorderIcon
                  sx={{ fontSize: "40px", cursor: "pointer" }}
                  onClick={() => setStar(4)}
                />
              ) : (
                star > 3 && (
                  <StarIcon
                    onClick={() => setStar(4)}
                    sx={{
                      color: "gold",
                      fontSize: "40px",
                      cursor: "pointer",
                      background: "#ffd90030",
                      borderRadius: "10px",
                    }}
                  />
                )
              )}
              {star <= 4 ? (
                <StarBorderIcon
                  sx={{ fontSize: "40px", cursor: "pointer" }}
                  onClick={() => setStar(5)}
                />
              ) : (
                star > 4 && (
                  <StarIcon
                    onClick={() => setStar(5)}
                    sx={{
                      color: "gold",
                      fontSize: "40px",
                      cursor: "pointer",
                      background: "#ffd90030",
                      borderRadius: "10px",
                    }}
                  />
                )
              )}
            </Box>
            <TextField
              // label="Review"
              multiline
              placeholder={
                option === "GENERAL"
                  ? "Describe your Experience"
                  : option === "BOOLEAN"
                  ? "Describe your Boolean Learning Experience"
                  : "Describe your XRAY Learning Experience"
              }
              rows={3}
              value={review}
              onChange={(event) => setReview(event.target.value)}
              sx={{ width: "400px" }}
            ></TextField>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box mr={2} mb={2}>
            <Button onClick={handleSubmit} variant="outlined">
              Submit
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
