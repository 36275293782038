import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import "./services.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DoneIcon from "@mui/icons-material/Done";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}));

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div hidden={value !== index}>
      {value === index && <Box sx={{ p: 6 }}>{children}</Box>}
    </div>
  );
}

function createData(feature, standard, pro) {
  return { feature, standard, pro };
}

const rows = [
  createData("Price", "₹ 3199", "₹ 5499"),
  createData("Logins", "5 Logins", "15 Logins"),
  createData("Jobs", "Upto 10 Jobs", "Upto 25 Jobs"),
  createData("Candidate Interactions", "Unlimited", "Unlimited"),
  createData("Integrations", "None", "Google Sheets"),
];

const Services = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box p={2}>
      <Typography variant="h5">Our Services :</Typography>
      <Divider />
      <Paper
        elevation={1}
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="Sourcing" />
          <Tab label="Engagement" />
          <Tab label="Training" />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Stack direction="row" spacing={12} alignItems="flex-start">
            <Box>
              <Paper
                elevation={3}
                sx={{
                  borderRadius: 1000,
                  textAlign: "center",
                  position: "relative",
                  zIndex: 10,
                }}
              >
                <span className="ptext" data-silver="silver">
                  Silver
                </span>
              </Paper>
              <Paper
                sx={{
                  textAlign: "center",
                  minWidth: "450px",
                  border: "0px 1px 1px solid",
                  borderColor: "lightgray",
                  position: "relative",
                  zIndex: 5,
                  paddingTop: "20px",
                  marginTop: "-20px",
                }}
              >
                <List
                  dense={true}
                  sx={{
                    padding: "20px 10px",
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon
                      sx={{
                        minWidth: "36px ",
                      }}
                    >
                      <LinkedInIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="body1">
                        Get advanced LinkedIn Filters
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                  <List
                    dense={true}
                    component="div"
                    disablePadding
                    sx={{ pl: 4 }}
                  >
                    <ListItem>
                      <ListItemIcon
                        sx={{
                          minWidth: "28px",
                        }}
                      >
                        <DoneIcon color="success" />
                      </ListItemIcon>
                      <ListItemText primary="Bypass the 6 keyword limitations on LinkedIn search" />
                    </ListItem>

                    <ListItem>
                      <ListItemIcon
                        sx={{
                          minWidth: "28px",
                        }}
                      >
                        <DoneIcon color="success" />
                      </ListItemIcon>
                      <ListItemText primary="Target candidates from mutual groups" />
                    </ListItem>
                  </List>

                  {/* point 2 */}
                  <ListItemButton>
                    <ListItemIcon
                      sx={{
                        minWidth: "36px ",
                      }}
                    >
                      <FilterAltIcon color="warning" />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="body1">
                        Get advanced Xray Filters like
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                  <List
                    dense={true}
                    component="div"
                    disablePadding
                    sx={{ pl: 4 }}
                  >
                    <ListItem>
                      <ListItemIcon
                        sx={{
                          minWidth: "28px",
                        }}
                      >
                        <DoneIcon color="success" />
                      </ListItemIcon>
                      <ListItemText primary="Previously Worked at X year to Y year" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon
                        sx={{
                          minWidth: "28px",
                        }}
                      >
                        <DoneIcon />
                      </ListItemIcon>
                      <ListItemText primary="Duration in current company" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon
                        sx={{
                          minWidth: "28px",
                        }}
                      >
                        <DoneIcon color="success" />
                      </ListItemIcon>
                      <ListItemText primary="Split data by the size of LinkedIn network" />
                    </ListItem>{" "}
                    <ListItem>
                      <ListItemIcon
                        sx={{
                          minWidth: "28px",
                        }}
                      >
                        <DoneIcon color="success" />
                      </ListItemIcon>
                      <ListItemText primary="Download data in excel" />
                    </ListItem>
                  </List>
                </List>
                <Divider />
                <Box
                  py={1}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h4" color="gray">
                    $10
                    <Typography
                      display="inline-block"
                      variant="h6"
                      color="gray"
                    >
                      /month USD
                    </Typography>
                  </Typography>
                </Box>
              </Paper>
            </Box>

            {/* Gold */}
            <Box>
              <Paper
                elevation={3}
                sx={{
                  borderRadius: 1000,
                  textAlign: "center",
                  position: "relative",
                  zIndex: 10,
                }}
              >
                <span className="ptext" data-gold="gold">
                  Gold
                </span>
              </Paper>
              <Paper
                sx={{
                  textAlign: "center",
                  minWidth: "450px",
                  border: "0px 1px 1px solid",
                  borderColor: "lightgray",
                  position: "relative",
                  zIndex: 5,
                  paddingTop: "20px",
                  marginTop: "-20px",
                }}
              >
                <List
                  dense={true}
                  sx={{
                    padding: "20px 10px",
                  }}
                >
                  <List dense={true} component="div" disablePadding>
                    <ListItem>
                      <ListItemIcon
                        sx={{
                          minWidth: "28px",
                        }}
                      >
                        <DoneIcon color="success" />
                      </ListItemIcon>
                      <ListItemText primary="Set your sourcing expectations" />
                    </ListItem>

                    <ListItem>
                      <ListItemIcon
                        sx={{
                          minWidth: "28px",
                        }}
                      >
                        <DoneIcon color="success" />
                      </ListItemIcon>
                      <ListItemText primary="Generate live/real time data of candidates across the globe" />
                    </ListItem>

                    <ListItem>
                      <ListItemIcon
                        sx={{
                          minWidth: "28px",
                        }}
                      >
                        <DoneIcon color="success" />
                      </ListItemIcon>
                      <ListItemText primary="Our tool auto searches candidates like a top sourcer" />
                    </ListItem>

                    <ListItem>
                      <ListItemIcon
                        sx={{
                          minWidth: "28px",
                        }}
                      >
                        <DoneIcon color="success" />
                      </ListItemIcon>
                      <ListItemText primary="Set a monthly or quarterly automation to get a new data set" />
                    </ListItem>
                  </List>
                </List>
                <Divider />
                <Box
                  py={1}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h4" color="gray">
                    Custom Plan
                  </Typography>
                </Box>
              </Paper>
            </Box>
          </Stack>
        </Stack>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Typography mb={2} textAlign="center" fontStyle="italic" variant="h5">
          "Automate your candidate engagement on Whatsapp"
        </Typography>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <TableContainer
            sx={{
              maxWidth: "900px",
            }}
            component={Paper}
            elevation={3}
          >
            <Table sx={{ minWidth: 650, padding: 20 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell
                    sx={{
                      bgcolor: "black",
                      color: "white",
                    }}
                    align="right"
                  >
                    <Typography variant="h6">Standard</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Typography variant="h6">PRO</Typography>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <StyledTableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography variant="button">{row.feature}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="subtitle2">
                        {row.standard}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="subtitle2">{row.pro}</Typography>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Stack spacing={3} justifyContent="center" alignItems="center">
          <Stack spacing={1} maxWidth="1200px">
            <Typography lineHeight={1} fontSize={22} textAlign="center">
              The majority of recruiters have entered the field of hiring
              through shadowing and observing senior recruiters, without
              receiving a strong foundation in the fundamentals.
            </Typography>
            <Typography fontSize={20} textAlign="center" fontStyle="italic">
              "Keeping this in mind we have crafted our trainings, transforming
              Recruiters to Recruiting Monks"
            </Typography>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Card elevation={3} sx={{ maxWidth: 345 }}>
              <CardHeader
                title="Mini Diploma"
                subheader="for Tech Recruiters"
              />
              <CardMedia
                component="img"
                height="194"
                image="/diploma.jpg"
                alt="Mini Diploma"
              />
              <CardContent>
                <Typography>
                  10-12 Weeks program, focused on tech recruitment. We cover a
                  range of topics, Persona understanding, Bigdata concepts in
                  sourcing, technology etc
                </Typography>
              </CardContent>
            </Card>
            <Card elevation={3} sx={{ maxWidth: 345 }}>
              <CardHeader
                title="Corporate Training"
                subheader="for Anyone"
              />
              <CardMedia
                component="img"
                height="194"
                image="/ctraining.jpg"
                alt="Corporate Training"
              />
              <CardContent>
                <Typography>
                  We deliver workshops and consulting assignments to specific
                  corporates or agencies. In this, we craft our content based on
                  your specific needs
                </Typography>
              </CardContent>
            </Card>
          </Stack>
        </Stack>
      </TabPanel>
    </Box>
  );
};

export default Services;
