import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useChatData from "../api/useChatData";
import LinkedinForm from "./LinkedinForm";
import TypeWriter from "./TypeWriter";
import { BotMessage, UserMessage } from "./MessageBox";
import TypeWriterMultiple from "./TypeWriterMultiple";
import {
  updateShowCountinue,
  updateTypeWriterEffect,
} from "../features/chats/chatsSlice";
import SimpleTypeWriter from "./SimpleTypeWriter";

const LinkedinChat = ({ stackRef, userDetails }) => {
  const [links, setLinks] = useState([]);
  const [output, setOutput] = useState(false);
  const [mandatoryTags, setMandatoryTags] = useState([]);
  const [mandatory, setMandatory] = useState("");
  const [eitherKeyword, seteitherKeyword] = useState([]);
  const [eitherInput, setEitherInput] = useState("");
  const [title, setTitle] = useState("");
  const [titleTags, setTitleTags] = useState([]);
  const [seperateTitles, setSeperateTitles] = useState(false);
  const [exceedLimit, setExceedLimit] = useState(false);
  const [linkIndex, setLinkIndex] = useState(0);
  const [searchInGroup, setsearchInGroup] = useState(false);
  const chatHistories = useSelector((state) => state.chats.chatHistories);
  const { typeWriterEffect } = useSelector((state) => state.chats);
  const { updateChatHistory } = useChatData();
  let { chatId, category } = useParams();
  const dispatch = useDispatch();
  const { showCountinue } = useSelector((state) => state.chats);

  useEffect(() => {
    stackRef.current.scrollTop = stackRef.current.scrollHeight;
  }, [chatHistories, showCountinue]);

  useEffect(() => {
    dispatch(updateShowCountinue(false));

    if (chatHistories?.length !== 0) {
      if (chatHistories?.length !== 0 && !typeWriterEffect) {
        if (chatHistories[chatHistories?.length - 1].sender === "LINKEDINBOT") {
          dispatch(updateShowCountinue(true));
        }
      }
    }
  }, [chatHistories]);

  const currentDate = new Date();
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  const formattedDate = currentDate
    .toLocaleString("en-US", options)
    .replace(",", "")
    .replace(/[/]/g, "-")
    .replace(" ", " ");

  useEffect(() => {
    async function updateChat() {
      const userData = JSON.stringify(output);
      const stringiFyLinks = JSON.stringify(links);

      await updateChatHistory(
        chatId,
        {
          message: userData,
          sender: "LINKEDINUSER",
          sentAt: formattedDate,
        },
        category
      );

      await updateChatHistory(
        chatId,
        {
          message: stringiFyLinks,
          sender: "LINKEDINBOT",
          sentAt: formattedDate,
        },
        category
      );
    }

    // Calling UpdateChat if output
    if (output) {
      updateChat();
    }
  }, [links]);

  const handleEdit = async () => {
    setLinks([]);
    setOutput(false);
    setLinkIndex(0);
    dispatch(updateTypeWriterEffect(false));

    const prevData = JSON.parse(
      chatHistories[chatHistories?.length - 2].message
    );
    let titles;

    if (prevData[0].title) {
      if (prevData[0].seperateTitles) {
        titles = prevData[0].title.split(",");
        setSeperateTitles(true);
        setTitleTags(titles);
      } else {
        titles = prevData[0].title.split(" OR ");
        setTitleTags(titles);
      }
    }
    if (prevData[0].mandatory) {
      let mandatory = prevData[0].mandatory.split(", ");
      setMandatoryTags(mandatory);
    }
    if (prevData[0].either) {
      let either = prevData[0].either.split(", ");
      seteitherKeyword(either);
    }
    if (prevData[0].searchInGroup) {
      setsearchInGroup(true);
    }

    await updateChatHistory(
      chatId,
      {
        message: "Generate New LinkedIn Sourcing Links",
        sender: "LINKEDINNEW",
        sentAt: formattedDate,
      },
      category
    );
  };

  const handleContinue = async () => {
    setLinks([]);
    setOutput(false);
    setSeperateTitles(false);
    setLinkIndex(0);
    setMandatoryTags([]);
    seteitherKeyword([]);
    setTitleTags([]);
    setsearchInGroup(false);
    dispatch(updateTypeWriterEffect(false));
    await updateChatHistory(
      chatId,
      {
        message: "Generate New LinkedIn Sourcing Links",
        sender: "LINKEDINNEW",
        sentAt: formattedDate,
      },
      category
    );
  };

  const renderMessage = (data) => {
    if (!seperateTitles) {
      console.log(data);
      return (
        <>
          {data.map((value, index) => (
            <Typography key={index}>
              {value.name} -{" "}
              <Link href={value.link} target="_blank">
                {value.searchInGroup ? (
                  <SimpleTypeWriter
                    text={`Generated Link ${index + 1}`}
                  ></SimpleTypeWriter>
                ) : (
                  <TypeWriter text={`Generated Link ${index + 1}`}></TypeWriter>
                )}
              </Link>
            </Typography>
          ))}

          {data[0].searchInGroup && (
            <Typography mt={2}>
              <TypeWriter
                text={`<strong>Free Inmail Hack</strong><br/><br/> - Click any of the generated links and click on any candidate.<br/>- Once you visit the profile of a candidate, you can see mutual groups.<br/>- Click on the mutual group --> Select a group --> In the group, click on members (right corner) --> Search with the candidate name --> Boom, send a free inmail.<br/><br/>Ensure you join some top groups in your Industry, so that you can message max candidates and save $$ on inmails.`}
              ></TypeWriter>
            </Typography>
          )}
        </>
      );
    } else {
      if (linkIndex < data?.length + 1) {
        const value = data[linkIndex];

        if (linkIndex < data?.length) {
          return (
            <Typography>
              {value.name} -{" "}
              <Link href={value.link} target="_blank">
                <TypeWriterMultiple
                  text={`Generated Link ${linkIndex + 1}`}
                  linkIndex={linkIndex}
                  setLinkIndex={setLinkIndex}
                ></TypeWriterMultiple>
              </Link>
            </Typography>
          );
        } else {
          return (
            <>
              {links[0].searchInGroup ? (
                <Typography mt={2}>
                  <TypeWriter
                    text={`<strong>Free Inmail Hack</strong><br/><br/> - Click any of the generated links and click on any candidate.<br/>- Once you visit the profile of a candidate, you can see mutual groups.<br/>- Click on the mutual group --> Select a group --> In the group, click on members (right corner) --> Search with the candidate name --> Boom, send a free inmail.<br/><br/>Ensure you join some top groups in your Industry, so that you can message max candidates and save $$ on inmails.`}
                  ></TypeWriter>
                </Typography>
              ) : (
                setLinkIndex(linkIndex + 1)
              )}
            </>
          );
        }
      } 
      else {
        dispatch(updateShowCountinue(true));
      }
    }
  };

  return (
    <>
      <Stack
        p={1}
        flexGrow={2}
        sx={{
          overflowY: "scroll",
        }}
        ref={stackRef}
      >
        <Typography variant="h4">Linkedin</Typography>
        <Divider />

        {chatHistories?.length === 0 && (
          <>
            <BotMessage>
              <Typography variant="button">
                Type in the below form & I will generate your Boolean & direct
                you to linkedin.
              </Typography>
              <FormControl>
                <LinkedinForm
                  exceedLimit={exceedLimit}
                  seperateTitles={seperateTitles}
                  titleTags={titleTags}
                  title={title}
                  eitherKeyword={eitherKeyword}
                  mandatory={mandatory}
                  mandatoryTags={mandatoryTags}
                  eitherInput={eitherInput}
                  searchInGroup={searchInGroup}
                  setsearchInGroup={setsearchInGroup}
                  setEitherInput={setEitherInput}
                  setLinks={setLinks}
                  setOutput={setOutput}
                  setExceedLimit={setExceedLimit}
                  setMandatoryTags={setMandatoryTags}
                  setMandatory={setMandatory}
                  seteitherKeyword={seteitherKeyword}
                  setTitle={setTitle}
                  setTitleTags={setTitleTags}
                  setSeperateTitles={setSeperateTitles}
                ></LinkedinForm>
              </FormControl>
            </BotMessage>
            <Box sx={{ borderBottom: "1px solid lightgrey" }} />
          </>
        )}

        {chatHistories?.length !== 0 && (
          <>
            {chatHistories.map((value, mainIndex) => (
              <>
                {value.sender === "LINKEDINUSER" && (
                  <UserMessage>
                    {JSON.parse(value.message).map((value) => (
                      <>
                        {value.title && (
                          <>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Title
                            </Typography>
                            {value.title + "\n"}
                          </>
                        )}
                        {value.mandatory && (
                          <>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Mandatory Keywords
                            </Typography>
                            {value.mandatory + "\n"}
                          </>
                        )}
                        {value.either && (
                          <>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Either Keywords
                            </Typography>
                            {value.either}
                          </>
                        )}
                        {value.searchInGroup && (
                          <>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Search In Group
                            </Typography>
                            True
                          </>
                        )}
                      </>
                    ))}
                  </UserMessage>
                )}

                {value.sender === "LINKEDINBOT" && (
                  <BotMessage>
                    {JSON.parse(value.message)?.length === 1 ? (
                      <>
                        <Typography>
                          Hoollaa {userDetails && `${userDetails.firstName}`},
                          here you go!!!
                        </Typography>

                        <Typography>
                          I have clustered your input into Linkedin search as
                          below
                        </Typography>
                        <br />
                      </>
                    ) : (
                      <>
                        <Typography>
                          Hoollaa {userDetails && `${userDetails.firstName}`},
                          here you go!!!
                        </Typography>
                        <Typography>
                          I have clustered your input into multiple Linkedin
                          searches as below
                        </Typography>
                        <br />
                      </>
                    )}

                    {chatHistories?.length === mainIndex + 1 &&
                    typeWriterEffect ? (
                      <>
                        {JSON.parse(value.message)
                          .slice(0, linkIndex)
                          .map((value, index) => (
                            <Typography key={index}>
                              {value.name} -{" "}
                              <Link href={value.link} target="_blank">
                                {`Generated Link ${index + 1}`}
                              </Link>
                            </Typography>
                          ))}

                        {renderMessage(JSON.parse(value.message))}
                      </>
                    ) : (
                      JSON.parse(value.message).map((value, index, array) => (
                        <>
                          <Typography key={index}>
                            {value.name} -{" "}
                            <Link href={value.link} target="_blank">
                              {`Generated Link ${index + 1}`}
                            </Link>
                          </Typography>
                          {value.searchInGroup && index === array?.length - 1 &&(
                            <Typography
                            mt={2}
                              dangerouslySetInnerHTML={{
                                __html:
                                  "<strong>Free Inmail Hack</strong><br/><br/> - Click any of the generated links and click on any candidate.<br/>- Once you visit the profile of a candidate, you can see mutual groups.<br/>- Click on the mutual group --> Select a group --> In the group, click on members (right corner) --> Search with the candidate name --> Boom, send a free inmail.<br/><br/>Ensure you join some top groups in your Industry, so that you can message max candidates and save $$ on inmails.",
                              }}
                            ></Typography>
                          )}
                        </>
                      ))
                    )}
                  </BotMessage>
                )}

                {value.sender === "LINKEDINNEW" && (
                  <>
                    <UserMessage>{value.message}</UserMessage>
                  </>
                )}

                {mainIndex + 1 === chatHistories?.length &&
                  value.sender === "LINKEDINBOT" &&
                  showCountinue && (
                    <>
                      <Box sx={{ borderTop: "1px solid lightgrey" }} />
                      <BotMessage>
                        <FormControl>
                          <Typography mb={2} variant="button">
                            Continue To generate a New Boolean OR Edit the
                            Existing Form
                          </Typography>
                          <Box gap={1} display="flex">
                            <Button
                              variant="outlined"
                              sx={{ width: "100px" }}
                              onClick={handleContinue}
                            >
                              Continue
                            </Button>
                            <Button
                              variant="outlined"
                              sx={{ width: "100px" }}
                              onClick={handleEdit}
                            >
                              Edit
                            </Button>
                          </Box>
                        </FormControl>
                      </BotMessage>
                    </>
                  )}

                {mainIndex + 1 === chatHistories?.length &&
                  value.sender === "LINKEDINNEW" && (
                    <>
                      <Box sx={{ borderTop: "1px solid lightgrey" }} />
                      <BotMessage>
                        <Typography variant="button">
                          Fill out The Given Below Form.
                        </Typography>
                        <LinkedinForm
                          exceedLimit={exceedLimit}
                          seperateTitles={seperateTitles}
                          titleTags={titleTags}
                          title={title}
                          eitherKeyword={eitherKeyword}
                          mandatory={mandatory}
                          mandatoryTags={mandatoryTags}
                          eitherInput={eitherInput}
                          searchInGroup={searchInGroup}
                          setsearchInGroup={setsearchInGroup}
                          setEitherInput={setEitherInput}
                          setLinks={setLinks}
                          setOutput={setOutput}
                          setExceedLimit={setExceedLimit}
                          setMandatoryTags={setMandatoryTags}
                          setMandatory={setMandatory}
                          seteitherKeyword={seteitherKeyword}
                          setTitle={setTitle}
                          setTitleTags={setTitleTags}
                          setSeperateTitles={setSeperateTitles}
                        ></LinkedinForm>
                      </BotMessage>
                    </>
                  )}

                <Box sx={{ borderBottom: "1px solid lightgrey" }} />
              </>
            ))}
          </>
        )}
      </Stack>
      <Stack mt={2} direction="row" spacing={1}>
        <TextField
          disabled
          fullWidth
          size="small"
          placeholder="Write your response here..."
          variant="outlined"
        />
        <Button disabled variant="contained" color="primary" size="small">
          <SendIcon />
        </Button>
      </Stack>{" "}
    </>
  );
};

export default LinkedinChat;
