import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { updateTypeWriterEffect } from "../features/chats/chatsSlice";
import { useDispatch } from "react-redux";

const HiringEffortCalculatorForm = ({
  position,
  joining,
  interview,
  submission,
  profiles,
  initialData,
  newProfile,
  setPosition,
  setJoining,
  setInterview,
  setSubmission,
  setProfiles,
  setInitialData,
  setNewProfile,
  setOutput,
  setUserOutput,
}) => {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const handleClick = () => {
    if (
      position === "" ||
      joining === "" ||
      interview === "" ||
      submission === "" ||
      profiles === "" ||
      newProfile === "" ||
      initialData === ""
    ) {
      setError(true);
      return;
    }
    setLoader(true);
    calculateHiringEffort();
  };

  const calculateHiringEffort = () => {
    try {
      //calculation of hiring effort
      const no_of_offer = Math.ceil(position / (joining / 100));
      const no_of_interviews = Math.ceil(no_of_offer / (interview / 100));
      const no_of_submission = Math.ceil(no_of_interviews / (submission / 100));
      const no_of_profiles = Math.ceil(no_of_submission * profiles);
      const no_of_week = Math.ceil((no_of_submission * profiles) / newProfile);

      //set the output after calculatiing hiring effort to show in the output screen
      // setOutput([{
      //   "No of offers required": no_of_offer,
      //   "No of interviews": no_of_interviews,
      //   "No of submissions": no_of_submission,
      //   "No of profiles to be viewed": no_of_profiles,
      //   "No of weeks required": no_of_week,
      // }]);

      setOutput(`No of offers required: <b>${no_of_offer}</b><br/>
      No of interviews: <b>${no_of_interviews}</b><br/>
      No of submissions: <b>${no_of_submission}</b><br/>
      No of profiles to be viewed: <b>${no_of_profiles}</b><br/>
      No of weeks required: <b>${no_of_week}</b>`);

      // User Data Ouptut
      setUserOutput([
        {
          position: position,
          joining: joining,
          interview: interview,
          submission: submission,
          profiles: profiles,
          initialData: initialData,
          newProfile: newProfile,
        },
      ]);
      dispatch(updateTypeWriterEffect(true));
      setLoader(false);
    } catch (error) {
      console.log(error.message);
      setLoader(false);
    }
  };

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 250 }}>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
          <Box sx={{ display: "flex", gap: 2, width: "500px" }}>
            <TextField
              sx={{ width: "250px" }}
              autoComplete="off"
              id="outlined-basic"
              label="Number of  Positions*"
              variant="outlined"
              value={position}
              onChange={(e) => {
                setPosition(e.target.value);
                if (error) {
                  setError(false);
                }
              }}
            />

            <TextField
              sx={{ width: "250px" }}
              autoComplete="off"
              id="outlined-basic"
              label="Joining %*"
              variant="outlined"
              value={joining}
              onChange={(e) => {
                setJoining(e.target.value);
                if (error) {
                  setError(false);
                }
              }}
            />
          </Box>
          <Box sx={{ display: "flex", gap: 2, width: "500px" }}>
            <TextField
              sx={{ width: "250px" }}
              autoComplete="off"
              id="outlined-basic"
              label="Interview (Offer %)*"
              variant="outlined"
              value={interview}
              onChange={(e) => {
                setInterview(e.target.value);
                if (error) {
                  setError(false);
                }
              }}
            />

            <TextField
              sx={{ width: "250px" }}
              autoComplete="off"
              id="outlined-basic"
              label="Submission (Interview %)*"
              variant="outlined"
              value={submission}
              onChange={(e) => {
                setSubmission(e.target.value);
                if (error) {
                  setError(false);
                }
              }}
            />
          </Box>
          <Box sx={{ display: "flex", gap: 2, width: "500px" }}>
            <TextField
              autoComplete="off"
              id="outlined-basic"
              label="No of Profile views per Submission*"
              variant="outlined"
              sx={{ width: "500px" }}
              value={profiles}
              onChange={(e) => {
                setProfiles(e.target.value);
                if (error) {
                  setError(false);
                }
              }}
            />
          </Box>
          <Box sx={{ display: "flex", gap: 2, width: "500px" }}>
            <TextField
              sx={{ width: "250px" }}
              autoComplete="off"
              id="outlined-basic"
              label="Initial data available*"
              variant="outlined"
              value={initialData}
              onChange={(e) => {
                setInitialData(e.target.value);
                if (error) {
                  setError(false);
                }
              }}
            />

            <TextField
              sx={{ width: "250px" }}
              autoComplete="off"
              id="outlined-basic"
              label="New Profiles every week*"
              variant="outlined"
              value={newProfile}
              onChange={(e) => {
                setNewProfile(e.target.value);
                if (error) {
                  setError(false);
                }
              }}
            />
          </Box>
        </Box>
        <Box marginLeft="73%" mt={3}>
          <Button
            variant="outlined"
            sx={{ width: "80px", height: "40px" }}
            onClick={handleClick}
          >
            {loader ? <CircularProgress size={20} /> : "Submit"}
          </Button>
        </Box>
        <Box m={1} width="500px">
          {error && (
            <Typography sx={{ color: "red" }}>
              *All fields are Compulsary
            </Typography>
          )}
        </Box>
      </FormControl>
    </>
  );
};

export default HiringEffortCalculatorForm;
