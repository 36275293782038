// import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useChatData from '../api/useChatData';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateAssessmentCompleted, updateLearningCompleted } from '../features/chats/chatsSlice';

const AlertDialog = ({ modalOpen, setModalOpen, chatId, item, message }) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { deleteChat } = useChatData();

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleDelete = () => {
    setModalOpen(false);
    deleteChat(chatId);
    navigate(`/app/chat`)
    dispatch(updateAssessmentCompleted(true));
    dispatch(updateLearningCompleted(true));

  }

  return (
    <div>
      <Dialog
        open={modalOpen}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleDelete}>Agree</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog