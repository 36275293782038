import IconButton from "@mui/material/IconButton";
import {
  Avatar,
  Box,
  Button,
  ListItemIcon,
  Menu,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Typography,
  DialogActions,
  Stack,
  Divider,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch, useSelector } from "react-redux";
import { logoutSuccess } from "../features/auth/authSlice";
import { useEffect, useState } from "react";
import useAuthData from "../api/useAuthData";

export default function TopBar() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const userDetails = useSelector((state) => state.auth.user);
  const { getUserDetails } = useAuthData();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      getUserDetails(token);
    } else {
      dispatch(logoutSuccess());
    }
  }, []);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const [profileDialogOpen, setProfileDialogOpen] = useState(false);

  const handleProfileDialogOpen = () => {
    setProfileDialogOpen(true);
  };

  const handleProfileDialogClose = () => {
    setProfileDialogOpen(false);
  };

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Dialog open={profileDialogOpen} onClose={handleProfileDialogClose}>
        <DialogTitle variant="h4" textAlign="center">
          {"User Profile"}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Stack justifyContent="center" alignItems="center" spacing={2}>
            <Avatar
              sx={{ width: 100, height: 100 }}
              alt="Profile Pic"
              src={userDetails?.picture}
            >
              <PersonIcon />
            </Avatar>
            <Stack pt={1}>
              <Stack direction="row" spacing={3} alignItems="center">
                <Typography fontSize="24px" fontWeight={500}>
                  Name:
                </Typography>
                <Typography fontSize="22px">
                  {" "}
                  {userDetails.firstName + " " + userDetails.lastName ||
                    "Unknown"}{" "}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={3} alignItems="center">
                <Typography fontSize="24px" fontWeight={500}>
                  Email Id:
                </Typography>
                <Typography fontSize="22px">
                  {userDetails.emailId || "N/A"}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={3} alignItems="center">
                <Typography fontSize="24px" fontWeight={500}>
                  Subscription:
                </Typography>
                <Typography fontSize="22px">
                  {userDetails.subscription || "N/A"}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will log you out of your account
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleDialogClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDialogClose();
              dispatch(logoutSuccess());
            }}
          >
            Logout
          </Button>
        </DialogActions>
      </Dialog>

      <IconButton size="small" edge="start" sx={{ mr: 2 }}>
        <img
          src="/img/tabdy-logo-no padding.png"
          alt="TA Buddy logo"
          style={{
            maxHeight: "35px",
          }}
        />
      </IconButton>

      <Button onClick={handleClick} variant="text">
        <Avatar alt="Profile Pic" src={userDetails?.picture}>
          <PersonIcon />
        </Avatar>
        <Typography
          ml={1}
          color="GrayText"
          variant="h6"
          textTransform="capitalize"
        >
          {userDetails.firstName || "Unknown"}
        </Typography>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleProfileDialogOpen();
            handleClose();
          }}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem
          sx={{
            color: "red",
          }}
          onClick={() => {
            handleDialogOpen();
            handleClose();
          }}
        >
          <ListItemIcon
            sx={{
              color: "red",
            }}
          >
            <LogoutIcon />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
}
