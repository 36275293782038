import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TopBarLocked from "../components/TopBarLocked";
import { Helmet } from "react-helmet";
import { Box, Button, Typography } from "@mui/material";

const ShareCertificate = () => {
  const [certificate, setCertificate] = useState("");
  const { imageUrl } = useParams();

  useEffect(() => {
    setCertificate("https://" + imageUrl);
  }, []);
  return (
    <>
      {/* <Helmet>
        <meta
          name="description"
          content={`Hello Connections! I am delighted to share that I have successfully completed XRAY - BASICS Course by Ashfaq Ahmed on the TABYDDY`}
        />
        <meta name="title" content="Elearning By Recruiting Monk" />
        <meta name="medium" content="mult" />
        <meta
          property="og:title"
          content="RecruitingMonk Course Completion Certificate"
        />
        <meta
          property="og:description"
          content={`Hello Connections! I am delighted to share that I have successfully completed XRAY - BASICS Course by Ashfaq Ahmed on the TABYDDY`}
        />
        <meta property="og:image" content={certificateImage} />
        <meta property="og:type" content="video_lecture" />
        <meta property="og:site_name" content="Elearning By TABUDDY" />
        <meta property="og:locale" content="en_US" />
        <meta itemprop="name" content="TABUDDY Course Completion Certificate" />
        <meta
          itemprop="description"
          content={`Hello Connections! I am delighted to share that I have successfully completed XRAY - BASICS Course by Ashfaq Ahmed on the TABYDDY`}
        />
        <meta itemprop="image" content={certificateImage} />
      </Helmet> */}

      <Box>
        <TopBarLocked />
        <Box
          style={{
            display: "flex",
            padding: "1rem",
            gap: "6rem",
            justifyContent: "center",
          }}
        >
          <Box>
            <img
              src={certificate}
              alt="Certificate"
            />
          </Box>

          <Box>
            <Box
              sx={{
                border: "2px solid lightgrey",
                padding: "2rem 0",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
                textAlign: "center",
              }}
            >
              <img
                src="/img/tabdy-logo-icon-with padding.png"
                alt="TABuddy"
                style={{ height: "120px" }}
              />
              <img src="/img/tabdy-logo-no padding.jpg" alt="TABUDDY" style={{width:"250px", height:"50px"}} />
              <Typography
              mb={2}
                sx={{ width: "300px", fontWeight: "500", fontSize: "25px" }}
              >
                by Ashfaq Ahmad
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  window.open("https://www.tabuddy.co", "_blank");
                }}
              >
                Get Your Certificate Now
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ShareCertificate;
