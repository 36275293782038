import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  CircularProgress,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useParams } from "react-router-dom";
import useChatData from "../api/useChatData";

import BooleanSelectRole from "./BooleanSelectRole";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { BotMessage, UserMessage } from "./MessageBox";
import {
  updateShowCountinue,
  updateTypeWriterEffect,
} from "../features/chats/chatsSlice";
import TypeWriterBoolean from "./TypeWriterBoolean";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const BooleanChat = ({
  role,
  value,
  setRole,
  setValue,
  takeInput,
  setTakeInput,
  stackRef,
}) => {
  const chatHistories = useSelector((state) => state.chats.chatHistories);
  const { booleanString } = useSelector((state) => state.chats);
  const { typeWriterEffect } = useSelector((state) => state.chats);
  const { showCountinue } = useSelector((state) => state.chats);
  const [loader, setLoader] = useState(false);
  const [exclude, setExclude] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    stackRef.current.scrollTop = stackRef.current.scrollHeight;
  }, [chatHistories, showCountinue]);

  useEffect(() => {
    dispatch(updateShowCountinue(false));

    if (booleanString){
      if ((booleanString.excludeArray)?.length !== 0){
        const message = (booleanString.excludeArray).join(",");
        setExclude(message);
      }
    }

    if (chatHistories?.length !== 0) {
      if (chatHistories[chatHistories?.length - 1].sender !== "BOOLEANBOT") {
        setTakeInput(true);
      } else {
        setTakeInput(false);
      }
    }

    if (chatHistories?.length !== 0 && !typeWriterEffect) {
      if (
        chatHistories[chatHistories?.length - 1].sender ===
          "BOOLEANBOTGENERATED" ||
        chatHistories[chatHistories?.length - 1].sender === "BOOLEANBOTINCLUDED"
      ) {
        dispatch(updateShowCountinue(true));
      }
    }
  }, [chatHistories]);

  // Call APIs
  const { updateChatHistory, createBoolean } = useChatData();
  const roleRef = useRef();

  // Usse Parameters
  let { chatId, category } = useParams();

  const currentDate = new Date();
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  const formattedDate = currentDate
    .toLocaleString("en-US", options)
    .replace(",", "")
    .replace(/[/]/g, "-")
    .replace(" ", " ");

  const handleSubmit = async () => {
    if (role === "") {
      const newRole = chatHistories[chatHistories?.length - 2].message;
      roleRef.current = newRole;
    }

    if (roleRef !== "" && value) {
      await updateChatHistory(
        chatId,
        {
          message: value,
          sender: "BOOLEANUSER",
          sentAt: formattedDate,
        },
        category
      );
      await createBoolean(
        chatId,
        {
          role: roleRef.current,
          skills: value,
        },
        formattedDate,
        category
      );
      roleRef.current = "";
      setValue("");

      dispatch(updateTypeWriterEffect(true));
    }
    setLoader(false);
  };

  const handleClick = () => {
    if (!loader) {
      handleSubmit();
    } else {
      console.log("Link is genereting");
    }
    setLoader(true);
  };

  const handleKeyDown = (event) => {
    if (event.code === "Enter") {
      handleClick();
    }
  };

  const handleShow = async () => {
    await updateChatHistory(
      chatId,
      {
        message: "Generate New Boolean",
        sender: "BOOLEANUSER",
        sentAt: formattedDate,
      },
      category
    );
  };

  const handleShowExclude = async () => {
    await updateChatHistory(
      chatId,
      {
        message: `Show Boolean With Excluded Skills`,
        sender: "BOOLEANUSER",
        sentAt: formattedDate,
      },
      category
    );
    await updateChatHistory(
      chatId,
      {
        message: `${booleanString.excludedSkills}`,
        sender: "BOOLEANBOTGENERATED",
        sentAt: formattedDate,
      },
      category
    );
  };

  const handleCopy = (data) => {
    navigator.clipboard.writeText(data);
  };

  return (
    <>
      <Stack
        p={1}
        flexGrow={2}
        sx={{
          overflowY: "scroll",
        }}
        ref={stackRef}
      >
        <Typography variant="h4">Boolean</Typography>
        <Divider />

        {chatHistories?.length === 0 && (
          <>
            <BotMessage>
              <Typography variant="button" sx={{ display: "block" }}>
                I have been trained on a bunch of roles to help you frame
                booleans. Choose a role from below.
              </Typography>
              <BooleanSelectRole
                chatId={chatId}
                category={category}
                role={role}
                setRole={setRole}
                formattedDate={formattedDate}
                updateChatHistory={updateChatHistory}
              />
            </BotMessage>
            <Box sx={{ borderBottom: "1px solid lightgrey" }} />
          </>
        )}

        {chatHistories?.length !== 0 && (
          <>
            {chatHistories.map((value, index) => (
              <>
                {value.sender === "BOOLEANUSER" && (
                  <UserMessage>{value.message}</UserMessage>
                )}

                {value.sender === "BOOLEANBOT" && (
                  <BotMessage>
                    {chatHistories?.length === index + 1 && typeWriterEffect ? (
                      <TypeWriterBoolean
                        text={value.message}
                      ></TypeWriterBoolean>
                    ) : (
                      value.message
                    )}
                  </BotMessage>
                )}

                {value.sender === "BOOLEANBOTINCLUDED" && (
                  <BotMessage>
                    <Stack
                      sx={{
                        width: "600px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Stack
                        sx={{
                          width: "90%",
                        }}
                      >
                        {chatHistories?.length === index + 1 &&
                        typeWriterEffect ? (
                          <TypeWriterBoolean
                            text={value.message}
                          ></TypeWriterBoolean>
                        ) : (
                          value.message
                        )}
                      </Stack>

                      {value.message !== "The Skills you enterd are not mandatory for this role" && <Stack sx={{ marginRight: "0" }}>
                        <Tooltip title="Copy Boolean">
                          <IconButton onClick={() => handleCopy(value.message)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>}
                    </Stack>
                  </BotMessage>
                )}

                {value.sender === "BOOLEANBOTGENERATED" && (
                  <BotMessage>
                    <Stack
                      sx={{
                        width: "600px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Stack
                        sx={{
                          width: "90%",
                        }}
                      >
                        {chatHistories?.length === index + 1 &&
                        typeWriterEffect ? (
                          <TypeWriterBoolean
                            text={value.message}
                          ></TypeWriterBoolean>
                        ) : (
                          value.message
                        )}
                      </Stack>

                      <Stack sx={{ marginRight: "0" }}>
                        <Tooltip title="Copy Boolean">
                          <IconButton onClick={() => handleCopy(value.message)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </Stack>
                  </BotMessage>
                )}

                {index + 1 === chatHistories?.length &&
                  value.sender === "BOOLEANBOTINCLUDED" &&
                  showCountinue && (
                    <>
                      <Box sx={{ borderTop: "1px solid lightgrey" }} />
                      <BotMessage>
                        <FormControl>
                          <Typography mb={2}>
                            We believe <span style={{fontWeight:"bold"}}>{exclude}</span> is not really mandatory for this
                            role . Still do you want to make it mandatory?
                          </Typography>
                          <Stack
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "20px",
                            }}
                          >
                            <Button
                              variant="outlined"
                              sx={{ width: "100px" }}
                              onClick={handleShowExclude}
                            >
                              Yes
                            </Button>
                            <Button
                              variant="outlined"
                              sx={{ width: "100px" }}
                              onClick={handleShow}
                            >
                              No
                            </Button>
                          </Stack>
                        </FormControl>
                      </BotMessage>
                    </>
                  )}

                {index + 1 === chatHistories?.length &&
                  value.sender === "BOOLEANBOTGENERATED" &&
                  showCountinue && (
                    <>
                      <Box sx={{ borderTop: "1px solid lightgrey" }} />
                      <BotMessage>
                        <FormControl>
                          <Typography mb={2} variant="button">
                            Continue To generate a New Boolean
                          </Typography>
                          <Button
                            variant="outlined"
                            sx={{ width: "100px" }}
                            onClick={handleShow}
                          >
                            Continue
                          </Button>
                        </FormControl>
                      </BotMessage>
                    </>
                  )}

                {index + 1 === chatHistories?.length &&
                  value.message === "Generate New Boolean" && (
                    <>
                      <Box sx={{ borderTop: "1px solid lightgrey" }} />
                      <BotMessage>
                        <Typography variant="button">
                          Select a Role from Given Below options to Generate New
                          Boolean.
                        </Typography>
                        <BooleanSelectRole
                          chatId={chatId}
                          category={category}
                          role={role}
                          setRole={setRole}
                          formattedDate={formattedDate}
                          updateChatHistory={updateChatHistory}
                        />
                      </BotMessage>
                    </>
                  )}

                <Box sx={{ borderBottom: "1px solid lightgrey" }} />
              </>
            ))}
          </>
        )}
      </Stack>
      <Stack mt={2} direction="row" spacing={1}>
        <TextField
          disabled={takeInput}
          fullWidth
          onKeyDown={handleKeyDown}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          size="small"
          placeholder="Write your response here..."
          variant="outlined"
        />
        <Button
          disabled={takeInput}
          onClick={handleClick}
          variant="contained"
          color="primary"
          size="small"
        >
          {loader ? (
            <CircularProgress size={20} sx={{ color: "white" }} />
          ) : (
            <SendIcon />
          )}
        </Button>
      </Stack>{" "}
    </>
  );
};

export default BooleanChat;
