
// If only experiance field is selected
const onlyExperiance = (experiance, googleSearchUrl, setLinks) => {
    if (experiance === ">3") {
        const newArr = [`${googleSearchUrl} "${"1..2 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"2..3 yrs | years * exp | experience"}"`]

        setLinks(prev => [...prev, { link: newArr[0], name: "1-2 Years Experience" }])
        setLinks(prev => [...prev, { link: newArr[1], name: "2-3 Years Experience" }])
    }
    if (experiance === ">5") {
        const newArr = [`${googleSearchUrl} "${"3..4 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"4..5 yrs | years * exp | experience"}"`]

        setLinks(prev => [...prev, { link: newArr[0], name: "3-4 Years Experience" }])
        setLinks(prev => [...prev, { link: newArr[1], name: "4-5 Years Experience" }])
    }
    if (experiance === ">8") {
        const newArr = [`${googleSearchUrl} "${"5..6 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"6..7 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"7..8 yrs | years * exp | experience"}"`]

        setLinks(prev => [...prev, { link: newArr[0], name: "5-6 Years Experience" }])
        setLinks(prev => [...prev, { link: newArr[1], name: "6-7 Years Experience" }])
        setLinks(prev => [...prev, { link: newArr[2], name: "7-8 Years Experience" }])
    }
    if (experiance === ">12") {
        const newArr = [`${googleSearchUrl} "${"8..9 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"9..10 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"10..11 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"11..12 yrs | years * exp | experience"}"`]
        setLinks(prev => [...prev, { link: newArr[0], name: "8-9 Years Experience" }])
        setLinks(prev => [...prev, { link: newArr[1], name: "9-10 Years Experience" }])
        setLinks(prev => [...prev, { link: newArr[2], name: "10-11 Years Experience" }])
        setLinks(prev => [...prev, { link: newArr[3], name: "11-12 Years Experience" }])
    }
    if (experiance === "<12") {
        const newArr = [`${googleSearchUrl} "${"12+ yrs | years * exp | experience"}"`]
        setLinks(prev => [...prev, { link: newArr[0], name: "12+ Years Experience" }])
    }
}


// When designation , experiance, and Company all are selected
const designationExperianceCompany = (experiance, googleSearchUrl, selectedDesignations, transformedArray, setLinks) => {
    if (experiance === ">3") {
        const newArr = [`${googleSearchUrl} "${"1..2 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"2..3 yrs | years * exp | experience"}"`]
        selectedDesignations.map((value) => {
            transformedArray.map((company) => {
                let link = `${newArr[0]} intitle:"${value}" intitle:"${company}"`
                setLinks(prev => [...prev, { link: link, name: `1-2 Years Experience with ${value} Designation and Company Cluster ${company}` }]);
            })
        })

        selectedDesignations.map((value) => {
            transformedArray.map((company) => {
                let link = `${newArr[1]} intitle:"${value}" intitle:"${company}"`
                setLinks(prev => [...prev, { link: link, name: `2-3 Years Experience with ${value} Designation and Company Cluster ${company}` }]);
            })
        })

    }
    if (experiance === ">5") {
        const newArr = [`${googleSearchUrl} "${"3..4 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"4..5 yrs | years * exp | experience"}"`]

        selectedDesignations.map((value) => {
            transformedArray.map((company) => {
                let link = `${newArr[0]} intitle:"${value}" intitle:"${company}"`
                setLinks(prev => [...prev, { link: link, name: `3-4 Years Experience with ${value} Designation and Company Cluster ${company}` }]);
            })
        })

        selectedDesignations.map((value) => {
            transformedArray.map((company) => {
                let link = `${newArr[1]} intitle:"${value}" intitle:"${company}"`
                setLinks(prev => [...prev, { link: link, name: `4-5 Years Experience with ${value} Designation and Company Cluster ${company}` }]);
            })
        })

    }
    if (experiance === ">8") {
        const newArr = [`${googleSearchUrl} "${"5..6 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"6..7 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"7..8 yrs | years * exp | experience"}"`]

        selectedDesignations.map((value) => {
            transformedArray.map((company) => {
                let link = `${newArr[0]} intitle:"${value}" intitle:"${company}"`
                setLinks(prev => [...prev, { link: link, name: `5-6 Years Experience with ${value} Designation and Company Cluster ${company}` }]);
            })
        })

        selectedDesignations.map((value) => {
            transformedArray.map((company) => {
                let link = `${newArr[1]} intitle:"${value}" intitle:"${company}"`
                setLinks(prev => [...prev, { link: link, name: `6-7 Years Experience with ${value} Designation and Company Cluster ${company}` }]);
            })
        })
        selectedDesignations.map((value) => {
            transformedArray.map((company) => {
                let link = `${newArr[2]} intitle:"${value}" intitle:"${company}"`
                setLinks(prev => [...prev, { link: link, name: `7-8 Years Experience with ${value} Designation and Company Cluster ${company}` }]);
            })
        })
    }
    if (experiance === ">12") {
        const newArr = [`${googleSearchUrl} "${"8..9 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"9..10 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"10..11 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"11..12 yrs | years * exp | experience"}"`]
        selectedDesignations.map((value) => {
            transformedArray.map((company) => {
                let link = `${newArr[0]} intitle:"${value}" intitle:"${company}"`
                setLinks(prev => [...prev, { link: link, name: `8-9 Years Experience with ${value} Designation and Company Cluster ${company}` }]);
            })
        })

        selectedDesignations.map((value) => {
            transformedArray.map((company) => {
                let link = `${newArr[1]} intitle:"${value}" intitle:"${company}"`
                setLinks(prev => [...prev, { link: link, name: `9-10 Years Experience with ${value} Designation and Company Cluster ${company}` }]);
            })
        })
        selectedDesignations.map((value) => {
            transformedArray.map((company) => {
                let link = `${newArr[2]} intitle:"${value}" intitle:"${company}"`
                setLinks(prev => [...prev, { link: link, name: `10-11 Years Experience with ${value} Designation and Company Cluster ${company}` }]);
            })
        })
        selectedDesignations.map((value) => {
            transformedArray.map((company) => {
                let link = `${newArr[3]} intitle:"${value}" intitle:"${company}"`
                setLinks(prev => [...prev, { link: link, name: `11-12 Years Experience with ${value} Designation and Company Cluster ${company}` }]);
            })
        })
    }
    if (experiance === "<12") {
        const newArr = [`${googleSearchUrl} "${"12+ yrs | years * exp | experience"}"`]
        selectedDesignations.map((value) => {
            transformedArray.map((company) => {
                let link = `${newArr[0]} intitle:"${value}" intitle:"${company}"`
                setLinks(prev => [...prev, { link: link, name: `12+ Years Experience with ${value} Designation and Company Cluster ${company}` }]);
            })
        })
    }
}


// only Experiance and company are selected
const experianceCompany = (experiance, googleSearchUrl, transformedArray, setLinks) => {
    if (experiance === ">3") {
        const newArr = [`${googleSearchUrl} "${"1..2 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"2..3 yrs | years * exp | experience"}"`]
        transformedArray.map((company) => {
            let link = `${newArr[0]} intitle:"${company}"`
            setLinks(prev => [...prev, { link: link, name: `1-2 Years Experience with Company Cluster ${company}` }]);
        })
        transformedArray.map((company) => {
            let link = `${newArr[1]}  intitle:"${company}"`
            setLinks(prev => [...prev, { link: link, name: `2-3 Years Experience with Company Cluster ${company}` }]);
        })

    }
    if (experiance === ">5") {
        const newArr = [`${googleSearchUrl} "${"3..4 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"4..5 yrs | years * exp | experience"}"`]

        transformedArray.map((company) => {
            let link = `${newArr[0]} intitle:"${company}"`
            setLinks(prev => [...prev, { link: link, name: `3-4 Years Experience with Company Cluster ${company}` }]);
        })

        transformedArray.map((company) => {
            let link = `${newArr[1]} intitle:"${company}"`
            setLinks(prev => [...prev, { link: link, name: `4-5 Years Experience with Company Cluster ${company}` }]);
        })
    }
    if (experiance === ">8") {
        const newArr = [`${googleSearchUrl} "${"5..6 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"6..7 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"7..8 yrs | years * exp | experience"}"`]

        transformedArray.map((company) => {
            let link = `${newArr[0]} intitle:"${company}"`
            setLinks(prev => [...prev, { link: link, name: `5-6 Years Experience with Company Cluster ${company}` }]);
        })

        transformedArray.map((company) => {
            let link = `${newArr[1]} intitle:"${company}"`
            setLinks(prev => [...prev, { link: link, name: `6-7 Years Experience with Company Cluster ${company}` }]);
        })
        transformedArray.map((company) => {
            let link = `${newArr[2]} intitle:"${company}"`
            setLinks(prev => [...prev, { link: link, name: `7-8 Years Experience with Company Cluster ${company}` }]);
        })
    }
    if (experiance === ">12") {
        const newArr = [`${googleSearchUrl} "${"8..9 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"9..10 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"10..11 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"11..12 yrs | years * exp | experience"}"`]
        transformedArray.map((company) => {
            let link = `${newArr[0]} intitle:"${company}"`
            setLinks(prev => [...prev, { link: link, name: `8-9 Years Experience with Company Cluster ${company}` }]);
        })

        transformedArray.map((company) => {
            let link = `${newArr[1]} intitle:"${company}"`
            setLinks(prev => [...prev, { link: link, name: `9-10 Years Experience with Company Cluster ${company}` }]);
        })
        transformedArray.map((company) => {
            let link = `${newArr[2]} intitle:"${company}"`
            setLinks(prev => [...prev, { link: link, name: `10-11 Years Experience with Company Cluster ${company}` }]);
        })
        transformedArray.map((company) => {
            let link = `${newArr[3]} intitle:"${company}"`
            setLinks(prev => [...prev, { link: link, name: `11-12 Years Experience with Company Cluster ${company}` }]);
        })
    }
    if (experiance === "<12") {
        const newArr = [`${googleSearchUrl} "${"12+ yrs | years * exp | experience"}"`]
        transformedArray.map((company) => {
            let link = `${newArr[0]} intitle:"${company}"`
            setLinks(prev => [...prev, { link: link, name: `12+ Years Experience with Company Cluster ${company}` }]);
        })
    }
}

// Only Experiance and designation are selected
const experianceDesignation = (experiance, googleSearchUrl, selectedDesignations, setLinks) => {
    if (experiance === ">3") {
        const newArr = [`${googleSearchUrl} "${"1..2 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"2..3 yrs | years * exp | experience"}"`]
        selectedDesignations.map((value) => {
            let link = `${newArr[0]} intitle:"${value}"`
            setLinks(prev => [...prev, { link: link, name: `1-2 Years Experience with ${value} Designation` }]);
        })

        selectedDesignations.map((value) => {
            let link = `${newArr[1]} intitle:"${value}"`
            setLinks(prev => [...prev, { link: link, name: `2-3 Years Experience with ${value} Designation` }]);
        })

    }
    if (experiance === ">5") {
        const newArr = [`${googleSearchUrl} "${"3..4 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"4..5 yrs | years * exp | experience"}"`]

        selectedDesignations.map((value) => {
            let link = `${newArr[0]} intitle:"${value}"`
            setLinks(prev => [...prev, { link: link, name: `3-4 Years Experience with ${value} Designation` }])
        })

        selectedDesignations.map((value) => {
            let link = `${newArr[1]} intitle:"${value}"`
            setLinks(prev => [...prev, { link: link, name: `4-5 Years Experience with ${value} Designation` }])
        })

    }
    if (experiance === ">8") {
        const newArr = [`${googleSearchUrl} "${"5..6 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"6..7 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"7..8 yrs | years * exp | experience"}"`]

        selectedDesignations.map((value) => {
            let link = `${newArr[0]} intitle:"${value}"`
            setLinks(prev => [...prev, { link: link, name: `5-6 Years Experience with ${value} Designation` }])
        })

        selectedDesignations.map((value) => {
            let link = `${newArr[1]} intitle:"${value}"`
            setLinks(prev => [...prev, { link: link, name: `6-7 Years Experience with ${value} Designation` }])
        })
        selectedDesignations.map((value) => {
            let link = `${newArr[2]} intitle:"${value}"`
            setLinks(prev => [...prev, { link: link, name: `7-8 Years Experience with ${value} Designation` }])
        })
    }
    if (experiance === ">12") {
        const newArr = [`${googleSearchUrl} "${"8..9 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"9..10 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"10..11 yrs | years * exp | experience"}"`, `${googleSearchUrl} "${"11..12 yrs | years * exp | experience"}"`]
        selectedDesignations.map((value) => {
            let link = `${newArr[0]} intitle:"${value}"`
            setLinks(prev => [...prev, { link: link, name: `8-9 Years Experience with ${value} Designation` }])
        })

        selectedDesignations.map((value) => {
            let link = `${newArr[1]} intitle:"${value}"`
            setLinks(prev => [...prev, { link: link, name: `9-10 Years Experience with ${value} Designation` }])
        })
        selectedDesignations.map((value) => {
            let link = `${newArr[2]} intitle:"${value}"`
            setLinks(prev => [...prev, { link: link, name: `10-11 Years Experience with ${value} Designation` }])
        })
        selectedDesignations.map((value) => {
            let link = `${newArr[3]} intitle:"${value}"`
            setLinks(prev => [...prev, { link: link, name: `11-12 Years Experience with ${value} Designation` }])
        })
    }
    if (experiance === "<12") {
        const newArr = [`${googleSearchUrl} "${"12+ yrs | years * exp | experience"}"`]
        selectedDesignations.map((value) => {
            let link = `${newArr[0]} intitle:"${value}"`
            setLinks(prev => [...prev, { link: link, name: `12+ Years Experience with ${value} Designation` }])
        })
    }
}



export { onlyExperiance, designationExperianceCompany, experianceCompany, experianceDesignation }