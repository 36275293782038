import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const SimpleTypeWriter = ({text}) => {
    const [displayedText, setDisplayedText] = useState('');

    useEffect(() => {
        if (typeof text !== 'string') {
            return;
        }

        let currentIndex = -1;
        const interval = setInterval(() => {
            if (currentIndex >= text?.length - 1) {
                clearInterval(interval);
                return;
            }

            currentIndex++;
            setDisplayedText(text.slice(0, currentIndex + 1));
        }, 25);

        return () => {
            clearInterval(interval);
        };
    }, [text]);

    return <span dangerouslySetInnerHTML={{ __html: displayedText }}></span>;
}

export default SimpleTypeWriter