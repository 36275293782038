const rolesData = [
  {
    name: "Software Developer/Engineer",
    value: "Software Developer/Engineer",
  },
  {
    name: "Product Manager",
    value: "Product Manager",
    options: [
      "Associate Product Manager",
      "Product Manager",
      "Senior Product Manager",
      "AVP Product Manager",
      "VP Product Manager",
    ],
  },
  {
    name: "FullStack Developer",
    value: "FullStack Developer",
    options: [
      "FullStack Developer",
      "FullStack Engineer",
      "Full Stack Developer",
      "Full Stack Engineer",
    ],
  },
  {
    name: "Data Scientist",
    value: "Data Scientist",
    options: ["Data Scientist", "ML Engineer", "Machine Learning Engineer"],
  },
  {
    name: "Data Engineer",
    value: "Data Engineer",
    options: [
      "Data Engineer",
      "Senior Data Engineer",
      "Bigdata Engineer",
      "Bigdata Developer",
    ],
  },
  {
    name: "SDET/Test Engineer",
    value: "SDET/Test Engineer",
    options: ["SDET", "Test Engineer", "Quality Engineer", "Automation Test"],
  },
  {
    name: "Business Analyst",
    value: "Business Analyst",
    options: [
      "Business Analyst",
      "Business Technology Analyst",
      "Senior Business Analyst",
    ],
  },
  {
    name: "Project Manager",
    value: "Project Manager",
    options: ["Project Manager", "Program Manager", "PMP"],
  },
  {
    name: "Marketing",
    value: "Marketing",
    options: [
      "Social Media Marketing",
      "Digital Marketing",
      "SEO Marketing",
      "Marketing Manager",
      "Performance Marketing",
      "Product Marketing",
    ],
  },
  {
    name: "Linux Admin",
    value: "Linux Admin",
    options: [
      "Linux Admin",
      "Linux administrator",
      "Linux system admin",
      "Linux system administrator",
    ],
  },
  {
    name: "Storage Admin",
    value: "Storage Admin",
    options: [
      "Storage Admin",
      "Storage administrator",
      "Storage system admin",
      "Storage system administrator",
    ],
  },
  {
    name: "Network Admin",
    value: "Network Admin",
    options: [
      "Network Admin",
      "Network administrator",
      "Network system admin",
      "Network system administrator",
    ],
  },
  {
    name: "Infrastructure Admin",
    value: "Infrastructure Admin",
    options: [
      "Infrastructure Admin",
      "Infrastructure administrator",
      "Infrastructure system admin",
      "Infrastructure system administrator",
    ],
  },
  {
    name: "Virtualization Admin",
    value: "Virtualization Admin",
    options: [
      "Virtualization Admin",
      "Virtualization administrator",
      "Virtualization system admin",
      "Virtualization system administrator",
      "Vmware Admin",
      "Vmware administrator",
      "Vmware system admin",
      "Vmware system administrator",
    ],
  },
  {
    name: "Infra/Cloud Architect",
    value: "Infra/Cloud Architect",
    options: [
      "Cloud Architect",
      "Cloud system architect",
      "Cloud infra architect",
      "AWS Architect",
      "Azure Architect",
      "Infra Architect",
      "Infrastructure Architect",
    ],
  },
  {
    name: "Custom Role",
    value: "Custom Role",
  },
];

const experianceData = [
  {
    years: "(1-3) Years",
    value: ">3",
  },
  {
    years: "(3-5) Years",
    value: ">5",
  },
  {
    years: "(5-8) Years",
    value: ">8",
  },
  {
    years: "(8-12) Years",
    value: ">12",
  },
  {
    years: "(12+) Years",
    value: "<12",
  },
];

const designationData = [
  {
    title: "Junior-Mid",
    value: "Junior-Mid",
    options: [
      "Software Engineer",
      "Software Developer",
      "SDE",
      "SDE 1",
      "SDE I",
      "Software Developer Engineer 1",
      "Software Development Engineer I",
    ],
  },
  {
    title: "Mid-Senior",
    value: "Mid-Senior",
    options: [
      "Senior Software Engineer",
      "Senior Software Developer",
      "SDE 2",
      "SDE 3",
      "SDE II",
      "SDE III",
      "Software Developer Engineer 2",
      "Software Development Engineer II",
      "Software Developer Engineer 3",
      "Software Development Engineer III",
      "MTS",
      "Member Technical Staff",
    ],
  },
  {
    title: "Lead",
    value: "Lead",
    options: ["Lead Engineer", "Lead Developer"],
  },
  {
    title: "Software Manager",
    value: "Software Manager",
    options: ["Engineering Manager", "Software Manager"],
  },
  {
    title: "Principal Engineer/Architect",
    value: "Principal Engineer/Architect",
    options: ["Principal Engineer", "Architect"],
  },
];

const roleBoolean = [
  {
    label: "Java Developer",
    value: "Java Developer",
  },
  {
    label: "Python Developer",
    value: "Python Developer",
  },
  {
    label: ".Net Developer",
    value: "DotNet Developer",
  },
  {
    label: "NodeJs Developer",
    value: "Node Developer",
  },
  {
    label: "FullStack Developer",
    value: "FullStack Developer",
  },
  {
    label: "UI Developer",
    value: "UI Developer",
  },
];

const assessmentRole = [
  {
    label: "Backend Developer",
    value: "backend",
  },
];

export {
  rolesData,
  experianceData,
  designationData,
  roleBoolean,
  assessmentRole,
};
