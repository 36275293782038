import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import { useSelector, useDispatch } from "react-redux";
import useChatData from "../api/useChatData";
import NewCompanyClusterForm from "./NewCompanyClusterForm";
import {
  createCompanyCluterSuccess,
  createCompanyCluterFailure,
} from "../features/chats/chatsSlice";

const CompanySectionLinkedin = ({
  activeCluster,
  newCluster,
  setNewCluster,
  newClusterTitle,
  setEditCluster,
  addNewURL,
  setAddNewURL,
  popupClose,
  setSnackbarError,
  setSnackbarMessage,
  setSnackbarType,
}) => {
  const dispatch = useDispatch();
  const { loadingCompanyCluster, successCompanyCluster, errorCompanyCluster } =
    useSelector((state) => state.chats);

  const [isEditing, setIsEditing] = useState(true);
  const [url, setUrl] = useState("");
  const [newCompany, setNewCompany] = useState("");
  const [isClusterEdited, setIsClusterEdited] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const { updateComanyClusters, getComanyClusters } = useChatData();
  const [existingCompanies, setExistingCompanies] = useState([]);

  useEffect(() => {
    dispatch(createCompanyCluterSuccess(""));
    dispatch(createCompanyCluterFailure(""));
  }, []);

  useEffect(() => {
    if (!loadingCompanyCluster && successCompanyCluster) {
      setEditCluster(true);
      setIsClusterEdited(false);
      setUrl("");
      setIsEditing(true);
      setCompaniesList([]);
      setExistingCompanies([]);
    }

    dispatch(createCompanyCluterSuccess(""));
    dispatch(createCompanyCluterFailure(""));
  }, [loadingCompanyCluster, successCompanyCluster, errorCompanyCluster]);

  useEffect(() => {
    if (activeCluster && Object.keys(activeCluster)?.length) {
      let linkedinCompanies = activeCluster.linkedinCompanies;
      linkedinCompanies = linkedinCompanies.map((item) => {
        return { ...item, isEditing: false, newCompany: "" };
      });
      setExistingCompanies(linkedinCompanies);
    }

    setIsClusterEdited(false);
    setUrl("");
    setIsEditing(true);
    setCompaniesList([]);
  }, [activeCluster]);

  useEffect(() => {
    if (popupClose) {
      setIsClusterEdited(false);
      setUrl("");
      setIsEditing(true);
      setCompaniesList([]);
      setExistingCompanies([]);
    }
  }, [popupClose]);

  const isValidLinkedInFormat = (input) => {
    const regex =
      /^https?:\/\/www\.linkedin\.com\/search\/results\/people\/\?(currentCompany|pastCompany)=/i;
    return regex.test(input);
  };

  const handleSave = async () => {
    const body = {
      type: "LINKEDIN",
      title: activeCluster.title,
      linkedinCompanies: addNewURL
        ? [
            ...existingCompanies,
            {
              url: url,
              companies: companiesList,
            },
          ]
        : existingCompanies,
    };
    updateComanyClusters(body, activeCluster._id);
  };

  const handleDelete = (value) => {
    if (value === "NEW") {
      setAddNewURL(false);
      setUrl("");
      setCompaniesList([]);
    } else {
      let linkedinCompanies = existingCompanies;
      linkedinCompanies = linkedinCompanies.filter(
        (item) => item._id !== value._id
      );
      setExistingCompanies(linkedinCompanies);
      setIsClusterEdited(true);
    }
  };

  const handleTagChange = (event, newTags) => {
    setIsClusterEdited(true);
    setCompaniesList(newTags);
  };

  const handleDeleteTag = (tagToDelete) => {
    setIsClusterEdited(true);
    setCompaniesList((prevTags) =>
      prevTags.filter((tag) => tag !== tagToDelete)
    );
  };

  const handleLinkedinCompanies = (existingCompany, value, type) => {
    setIsClusterEdited(true);
    let linkedinCompanies = existingCompanies;
    linkedinCompanies = linkedinCompanies.map((item) => {
      if (item._id === existingCompany._id) {
        if (type === "URL") {
          item.url = value;
        } else if (type === "EDIT_ICON") {
          item.isEditing = value;
        } else if (type === "COMPANY") {
          item.companies = value;
        }
      }

      return item;
    });

    setExistingCompanies(linkedinCompanies);
  };

  const handleNewUrl = (event) => {
    setIsClusterEdited(true);
    setUrl(event.target.value);
  };

  const saveNewUrl = () => {
    if (url === "") {
      setSnackbarError(true);
      setSnackbarMessage("Url can't be empty Enter Url");
      setSnackbarType("error");
      return;
    }
    if (!isValidLinkedInFormat(url)) {
      setSnackbarError(true);
      setSnackbarMessage("Added Url is wrong kindly watch the video");
      setSnackbarType("error");
      return;
    }
    setIsEditing(false);
  };

  if (newCluster === true) {
    return (
      <NewCompanyClusterForm
        newCluster={newCluster}
        setNewCluster={setNewCluster}
        newClusterTitle={newClusterTitle}
        setEditCluster={setEditCluster}
        setSnackbarError={setSnackbarError}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarType={setSnackbarType}
      />
    );
  }

  return (
    <Stack
      padding="0px 5px 5px 5px"
      gap={2}
      flexGrow={2}
      sx={{
        alignItems: "start",
        width: "560px",
        overflow: "hidden",
        overflowY: "scroll",
      }}
    >
      <Stack
        position="sticky"
        top="0"
        width="100%"
        direction="row"
        justifyContent="end"
        sx={{
          backgroundColor: "white",
          zIndex: "100",
          paddingRight: "10px",
          borderBottom: "1px solid lightgrey",
          paddingBottom: "10px",
        }}
      >
        {!addNewURL && activeCluster && Object.keys(activeCluster)?.length ? (
          <Button variant="outlined" onClick={() => setAddNewURL(true)}>
            Add Url
          </Button>
        ) : null}
        {isClusterEdited ? (
          <Button
            variant="outlined"
            color="success"
            onClick={handleSave}
            style={{ marginLeft: "10px" }}
          >
            Save Changes
          </Button>
        ) : null}
      </Stack>

      {addNewURL && (
        <>
          <Box
            sx={{
              border: "1px solid grey",
              padding: "5px",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "20px",
            }}
          >
            {activeCluster?.linkedinCompanies?.companies}
            <Box display="flex" alignItems="start">
              <Box width="10%">
                <Typography
                  variant="button"
                  display="inline-block"
                  color="black"
                  mr={1}
                >
                  Url {activeCluster?.linkedinCompanies?.length + 1}:
                </Typography>
              </Box>
              {isEditing ? (
                <TextField
                  variant="standard"
                  value={url}
                  onChange={handleNewUrl}
                  sx={{ width: "450px" }}
                />
              ) : (
                <Stack
                  sx={{
                    width: "440px",
                    display: "flex",
                    overflow: "hidden",
                    justifyContent: "start",
                    marginLeft: "10px",
                    height: "auto",
                  }}
                >
                  <Typography
                    color="black"
                    sx={{
                      maxWidth: "100%",
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                    }}
                  >
                    {url}
                  </Typography>
                </Stack>
              )}

              <Stack direction="row">
                {isEditing ? (
                  <IconButton size="small" color="success" onClick={saveNewUrl}>
                    <DoneIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    size="small"
                    color="info"
                    sx={{ marginLeft: "10px" }}
                    onClick={() => setIsEditing(true)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Stack>
            </Box>

            <Typography variant="button" color="black">
              Companies Related To URL{" "}
              {activeCluster?.linkedinCompanies?.length + 1}:
            </Typography>
            <Box
              sx={{
                width: "100%",
                padding: "10px 10px 10px 0px",
                flexWrap: "wrap",
                flexDirection: "row",
                gap: 1,
                padding: "5px",
                alignItems: "center",
              }}
            >
              <Autocomplete
                multiple
                freeSolo
                value={companiesList}
                onChange={handleTagChange}
                options={[]}
                renderTags={(value, getTagProps) =>
                  value?.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag}
                      onDelete={() => handleDeleteTag(tag)}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Company Names"
                    placeholder="Add Company Names"
                  />
                )}
              />
              {/*  */}
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleDelete("NEW")}
              >
                Delete Cluster
              </Button>
            </Box>
          </Box>
        </>
      )}

      {existingCompanies?.map((value, index) => {
        return (
          <>
            <Box
              sx={{
                border: "1px solid grey",
                padding: "5px",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "20px",
              }}
            >
              <Box display="flex" alignItems="start">
                <Box sx={{ width: "20%" }}>
                  <Typography
                    variant="button"
                    display="inline-block"
                    color="black"
                    mr={1}
                  >
                    Url {index + 1}:
                  </Typography>
                </Box>
                <Stack
                  sx={{
                    width: "440px",
                    display: "flex",
                    overflow: "hidden",
                    justifyContent: "start",
                    marginLeft: "10px",
                    height: "auto",
                  }}
                >
                  {value.isEditing ? (
                    <TextField
                      variant="standard"
                      value={value.url}
                      onChange={(event) => {
                        handleLinkedinCompanies(
                          value,
                          event.target.value,
                          "URL"
                        );
                      }}
                      sx={{ width: "450px" }}
                    />
                  ) : (
                    <Typography
                      color="black"
                      sx={{
                        maxWidth: "100%",
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                        textAlign: "justify",
                        overflowX: "auto",
                        maxHeight: "70px",
                      }}
                    >
                      {value.url}
                    </Typography>
                  )}
                </Stack>
                {value?.isEditing ? (
                  <IconButton
                    size="small"
                    color="success"
                    onClick={() => {
                      handleLinkedinCompanies(value, false, "EDIT_ICON");
                    }}
                  >
                    <DoneIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    size="small"
                    color="info"
                    onClick={() => {
                      handleLinkedinCompanies(value, true, "EDIT_ICON");
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Box>

              <Typography variant="button" color="black">
                Companies Related To URL {index + 1}:
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  padding: "10px 10px 10px 0px",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  gap: 1,
                  padding: "5px",
                  alignItems: "center",
                }}
              >
                {/* {value?.companies?.map((value, cindex) => (
                  <>
                    <Chip
                      key={cindex}
                      label={value}
                      variant="outlined"
                      style={{ margin: "0.5rem" }}
                      sx={{
                        borderRadius: "4px",
                        fontSize: "14px",
                        height: "58px",
                      }}
                    />
                  </>
                ))} */}

                {
                  <AutoCompleteComponent
                    value={value}
                    existingCompanies={existingCompanies}
                    setExistingCompanies={setExistingCompanies}
                    setIsClusterEdited={setIsClusterEdited}
                  />
                }
                {/* {value?.companies?.map((companyItem, index) => (
                  <>
                    <Chip
                      key={index}
                      label={companyItem}
                      // onClick={() => {handleLinkedinCompanies(companyItem, )}}
                      onDelete={
                        value?.companies?.includes(companyItem)
                          ? () => handleExistingDeleteCompany(value, companyItem)
                          : undefined
                      }
                      color={
                        value?.companies?.includes(companyItem)
                          ? "primary"
                          : "default"
                      }
                      variant="outlined"
                      style={{ margin: "0.5rem" }}
                      sx={{
                        borderRadius: "4px",
                        fontSize: "14px",
                        height: "58px",
                      }}
                    />

                    
                  </>
                ))}

                  {(
                      <TextField
                        sx={{
                          width: "150px",
                          marginLeft: "10px",
                          marginTop: "8px",
                        }}
                        label="+ Add Company"
                        variant="outlined"
                        value={value.newCompany}
                        onChange={(event) =>
                          {
                            handleLinkedinCompanies(value, event.target.value, "COMPANY")
                          }
                        }
                        onKeyDown={(event) => {handleExistingCompanyKeyDown(value, event)}}
                      />
                    )} */}
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleDelete(value)}
                >
                  Delete Cluster
                </Button>
              </Box>
            </Box>
          </>
        );
      })}
    </Stack>
  );
};

const AutoCompleteComponent = ({
  value,
  existingCompanies,
  setExistingCompanies,
  setIsClusterEdited,
}) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (value !== undefined && Object.keys(value)?.length) {
      setTags(value?.companies);
    }
  }, [value]);

  const handleTagChange = (event, newTags) => {
    setTags(newTags);
    setIsClusterEdited(true);

    let linkedinCompanies = existingCompanies;
    linkedinCompanies = linkedinCompanies.map((item) => {
      if (item._id === value._id) {
        item.companies = newTags;
      }

      return item;
    });

    setExistingCompanies(linkedinCompanies);
  };

  const handleDeleteTag = (tagToDelete) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
    setIsClusterEdited(true);
  };
  return (
    <>
      <Autocomplete
        multiple
        freeSolo
        value={tags}
        onChange={handleTagChange}
        options={[]}
        renderTags={(value, getTagProps) =>
          value?.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              onDelete={() => handleDeleteTag(tag)}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Company Names"
            placeholder="Add Company Names"
          />
        )}
      />
    </>
  );
};

export default CompanySectionLinkedin;
