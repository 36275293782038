import { useEffect, useState } from "react";
import SideBar from "../components/SideBar";
import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  Snackbar,
  Stack,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import useChatData from "../api/useChatData";
import TopBar from "../components/TopBar";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

function Boolean() {
  const [open, setOpen] = useState(false);
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const { getChats } = useChatData();

  useEffect(() => {
    getChats();
  }, []);


  return (
    <>
      <AppBar position="static">
        <Toolbar sx={{ backgroundColor: "white" }}>
          <TopBar />
        </Toolbar>
      </AppBar>

      <Stack overflow="hidden" flexGrow={1} direction="row">
        {/* The Alert Card */}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={3000}
          onClose={() => setOpen(false)}
        >
          <Alert severity="success" onClose={() => setOpen(false)}>
            <AlertTitle>Success</AlertTitle>
            You have been successfully loggedIn.
          </Alert>
        </Snackbar>

        {/* Left - SideBar */}
        <Box
          display="flex"
          flexDirection="column"
          mt={1}
          minWidth={280}
          maxWidth={280}
        >
          <SideBar />
        </Box>
        <Divider orientation="vertical" flexItem />
        {/* Right - Main Chat Section */}
        <Box flexGrow={1} display="flex" flexDirection="column" m={2}>
          <Outlet />
        </Box>
      </Stack>
    </>
  );
}

export default Boolean;
