import { Navigate } from "react-router-dom";

const LoginFailure = () => {
  return (
    <div>
      <Navigate to="/" replace={true} />
    </div>
  );
};

export default LoginFailure;
