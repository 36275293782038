import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Stack, Typography, Box, Grid, Alert, Snackbar } from "@mui/material";
import CompanySectionLinkedin from "./CompanySectionLinkedin";
import CompaniesChatCard from "./CompaniesChatCard";
import useChatData from "../api/useChatData";
import { useDispatch, useSelector } from "react-redux";
import VideocamIcon from '@mui/icons-material/Videocam';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { convertToXrayCompanyClusterSuccess, convertToXrayCompanyClusterFailure } from "../features/chats/chatsSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ComapniesClusterPopup({ open, setOpen, setUseClusterList, activeCluster, setActiveCluster }) {
  const { getComanyClusters, convertToXrayComanyCluster } = useChatData();
  const { companyCluster, loadingCompanyCluster, successCompanyCluster, errorCompanyCluster } = useSelector((state) => state.chats);
  const dispatch = useDispatch();

  const [newCluster, setNewCluster] = useState(false)
  const [newClusterTitle, setNewClusterTitle] = useState("Default Title")
  const [addNewURL, setAddNewURL] = useState(false)
  const [editCluster, setEditCluster] = useState(false)
  const [newClusterData, setNewClusterData] = useState({})
  const [snackbarError, setSnackbarError] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [snackbarType, setSnackbarType] = useState("success")

  useEffect(() => {
    dispatch(convertToXrayCompanyClusterSuccess(""))
    dispatch(convertToXrayCompanyClusterFailure(""))

    setActiveCluster({})
  }, [])

  useEffect(() => {
    if (!loadingCompanyCluster && successCompanyCluster) {
      setNewCluster(false)
      getComanyClusters("LINKEDIN");
      setSnackbarError(true)
      setSnackbarType("success")
      setSnackbarMessage("Copied to Xray successfully")
    }

    dispatch(convertToXrayCompanyClusterSuccess(""))
    dispatch(convertToXrayCompanyClusterFailure(""))
  }, [loadingCompanyCluster, successCompanyCluster, errorCompanyCluster])


  const handleCopy = () => {
    if (activeCluster && Object.keys(activeCluster)?.length) {
      if (activeCluster?.title === "Default Title") {
        setSnackbarError(true)
        setSnackbarMessage("Change Default Title and Copy")
        setSnackbarType("error")
      } else {
        convertToXrayComanyCluster(activeCluster?._id)
      }
    } else {
      setSnackbarError(true)
      setSnackbarMessage("Select a cluster first")
      setSnackbarType("error")
    }
  }

  const handleSet = () => {
    if (activeCluster && Object.keys(activeCluster)?.length) {
      setOpen(false);
      setUseClusterList(true)
      setEditCluster(false)
      setAddNewURL(false)
      setNewCluster(false)
      setNewClusterData({})
      setNewClusterTitle("Default Title")
    } else {
      setSnackbarError(true)
      setSnackbarMessage("Select a cluster first")
      setSnackbarType("error")
    }
  };

  const handleClose = () => {
    setUseClusterList(false)
    setEditCluster(false)
    setOpen(false);
    setAddNewURL(false)
    setNewCluster(false)
    setNewClusterData({})
    setNewClusterTitle("Default Title")
    setActiveCluster({})
  };

  useEffect(() => {
    getComanyClusters("LINKEDIN");
  }, []);

  useEffect(() => {
    setNewCluster(false)
    setAddNewURL(false)
  }, [activeCluster]);

  useEffect(() => {
    if (editCluster) {
      getComanyClusters("LINKEDIN");
      setEditCluster(false)
      setAddNewURL(false)
      setNewCluster(false)
      setNewClusterData({})
      setNewClusterTitle("Default Title")
      setActiveCluster({})
    }
  }, [editCluster]);

  useEffect(() => {
    if (activeCluster && !Object.keys(activeCluster)?.length) {
      setActiveCluster(companyCluster[0])
    }
  }, [companyCluster])

  useEffect(() => {
    if (newCluster && newClusterTitle) {
      setNewClusterData({
        title: newClusterTitle,
        _id: "newClusterId",
        isNewCluster: true
      })
    } else {
      setNewClusterData({})
      setNewClusterTitle("Default Title")
    }
  }, [newCluster, newClusterTitle])

  return (
    <div>

      <Snackbar open={snackbarError}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={4000} onClose={() => { setSnackbarError(false) }} >
        <Alert severity={snackbarType} sx={{ width: '100%' }} onClose={() => { setSnackbarError(false) }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth="400px"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          mb={2}
          sx={{
            backgroundColor: "white",
            color: "#0778bd",
            width: "100%",
            textAlign: "left",
            borderBottom: "1px solid black",
          }}
        >
          <Grid container md={12}>
            <Grid item md={4}>
              Select Company Cluster
            </Grid>
            <Grid item md={3} />
            {/* <Grid item md={5}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <span style={{ fontSize: "14px" }}>
                  How to use this?
                </span>
                <ArrowForwardIcon fontSize="small" style={{ margin: "0px 5px 0px 5px" }} />
                <a target="_blank" href={"https://vimeo.com/835716542/d27cb1d9af"} style={{ textDecoration: "none" }}>
                  <Button variant="outlined" >
                    <VideocamIcon fontSize="small" style={{ paddingRight: "5%" }} />
                    Watch Video
                  </Button>
                </a>
              </Box>
            </Grid> */}
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{
              textAlign: "center",
              padding: "10px",
              borderBottom: "1px solid lightgrey",
            }}
          >
            <Stack display="flex" flexDirection="row" gap={2} height="55vh">
              <Stack sx={{ borderRight: "1px solid lightgrey" }}>
                <Box sx={{ margin: "0px 10px 10px 10px" }}>
                  <Button fullWidth variant="outlined" onClick={() => setNewCluster(true)}>
                    New Companies
                  </Button>
                </Box>
                <Stack
                  sx={{
                    overflowY: "scroll",
                  }}
                  px={1}
                  spacing={1}
                  flexGrow={2}
                  gap={1}
                  p={1}
                >
                  {newCluster ? (
                    <CompaniesChatCard
                      id={newClusterData?._id}
                      value={newClusterData}
                      newCluster={newCluster}
                      activeCluster={activeCluster}
                      setActiveCluster={setActiveCluster}
                      setEditCluster={setEditCluster}
                      setNewClusterTitle={setNewClusterTitle}
                    />
                  ) : null}

                  {companyCluster?.map((value) => {
                    return (
                      <CompaniesChatCard
                        key={value._id}
                        value={value}
                        newCluster={newCluster}
                        activeCluster={activeCluster}
                        setActiveCluster={setActiveCluster}
                        setEditCluster={setEditCluster}
                      />
                    )
                  })}
                </Stack>
              </Stack>

              <Stack display="flex">
                <CompanySectionLinkedin
                  activeCluster={activeCluster}
                  setActiveCluster={setActiveCluster}
                  newCluster={newCluster}
                  setNewCluster={setNewCluster}
                  addNewURL={addNewURL}
                  setAddNewURL={setAddNewURL}
                  newClusterTitle={newClusterTitle}
                  setEditCluster={setEditCluster}
                  popupClose={!open}
                  setSnackbarError={setSnackbarError}
                  setSnackbarMessage={setSnackbarMessage}
                  setSnackbarType={setSnackbarType}
                ></CompanySectionLinkedin>
              </Stack>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ margin: "0px 15px 15px 10px" }}>
          <Button variant="outlined" onClick={handleCopy} color="warning">
            Copy to XRAY
          </Button>
          <Button variant="outlined" onClick={handleSet}>
            Use this List
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
