import {
  Box,
  Card,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const About = () => {
  return (
    <Box p={2}>
      <Typography variant="h5">About Us :</Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText>
            TABuddy is a product of{" "}
            <Link
              target="_blank"
              href="https://www.linkedin.com/company/recruitingmonk"
            >
              RecruitingMonk
            </Link>
          </ListItemText>
        </ListItem>

        <ListItem disablePadding>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText>
            Recruitingmonk has been working towards helping tech recruiters
            upskill via tons of learning events, live sourceathons, workshops &
            more.
          </ListItemText>
        </ListItem>
        <ListItem disablePadding>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText>
            TABuddy is focused towards helping Recruiters get some helping hand
            in their day-day sourcing on LinkedIn, Xray.
          </ListItemText>
        </ListItem>
        <ListItem disablePadding>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText>
            Skill Assessment (Coming Soon) : Tech Recruiters can even assess
            their skills around Boolean, tech know-how’s etc.
          </ListItemText>
        </ListItem>
      </List>
      <Typography p={2} variant="body1"></Typography>
      <Typography variant="h5">About Founder :</Typography>
      <Divider />
      <Box display="flex" alignItems="center" justifyContent="center">
        <Card
          sx={{
            maxWidth: "900px",
            margin: "30px 0px",
            border: "1px solid",
            borderColor: "lightgray",
          }}
        >
          <Stack direction="row" alignItems="center">
            <img
              src="/23.png"
              alt="ashfaq ahmed"
              style={{
                maxHeight: "250px",
              }}
            />
            <Stack direction="column" px={2}>
              <Stack direction="row" justifyContent="space-between">
                <Box>
                  <Typography variant="h6">Ashfaq Ahmed</Typography>
                  <Typography variant="subtitle2">Founder</Typography>
                </Box>
                <IconButton
                  size="large"
                  color="primary"
                  LinkComponent="a"
                  target="_blank"
                  href="https://www.linkedin.com/in/ashfaqahmedhr/"
                >
                  <LinkedInIcon />
                </IconButton>
              </Stack>
              <Typography variant="subtitle1" mt={2}>
                TABuddy is the brain child of our founder Ashfaq Ahmed. He comes
                with 10+ years of experience in the tech recruiting space. For
                the last 8+ years he has been involved in training/upskilling
                tech recruiters. He has helped more than 10,000+ tech
                recruiters.
              </Typography>
            </Stack>
          </Stack>
        </Card>
      </Box>
    </Box>
  );
};

export default About;
